import React, { FC } from "react";
import { Modal } from "react-rainbow-components";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { review } from "../../redux/reducers/Properties/PropertiesSlice";
import { useDispatch } from "react-redux";
import { BookingRequestDetails } from "../../redux/reducers/Properties/PropertiesSliceTypes";
import { ArrowRightIcon } from "@heroicons/react/24/solid";
import FiveStartIconForRate from "components/FiveStartIconForRate/FiveStartIconForRate";
import ButtonCircle from "shared/Button/ButtonCircle";
import { toast } from "react-toastify";
import { AppDispatch } from "redux/store";

interface ReviewModalProps {
  isOpen: boolean;
  onClose: () => void;
  bookingRequestDetails: BookingRequestDetails | null;
  fetchAllManageBooking: () => void;
}

const ReviewModal: FC<ReviewModalProps> = ({
  isOpen,
  onClose,
  bookingRequestDetails,
  fetchAllManageBooking,
}) => {
  const propertyId = bookingRequestDetails?.id ?? 0;
  const dispatch = useDispatch<AppDispatch>();

  const validationSchema = Yup.object().shape({
    accuracy: Yup.number().required("Rating is required"),
    communication: Yup.number().required("Rating is required"),
    cleanliness: Yup.number().required("Rating is required"),
    location: Yup.number().required("Rating is required"),
    check_in: Yup.number().required("Rating is required"),
    values: Yup.number().required("Rating is required"),
    comment: Yup.string()
      .trim()
      .matches(/^(?!\s*$).+/, "Comment cannot be empty or just spaces")
      .required("Comment is required"),
  });

  const handleSubmit = async (values: any, { resetForm }: any) => {
    try {
      const payload = {
        review: {
          accuracy: values.accuracy,
          communication: values.communication,
          cleanliness: values.cleanliness,
          location: values.location,
          check_in: values.check_in,
          values: values.values,
          comment: values.comment.trim(),
        },
      };

      const response = await dispatch(
        review({ id: propertyId, review: payload })
      ).unwrap();

      if (response?.message === "Review saved successfully") {
        toast.success("Review submitted successfully!");
        fetchAllManageBooking();
        resetForm();
        onClose();
      } else if (response?.error === "You have already reviewed this booking") {
        toast.info("You have already reviewed this booking.");
      } else {
        toast.error(response.message || "Failed to submit the review.");
      }
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data.message);
      } else {
        toast.error(
          error?.message || "Something went wrong. Please try again."
        );
      }
    }
  };

  return (
    <Modal id="review-modal" isOpen={isOpen} onRequestClose={onClose}>
      <div className="p-5 space-y-5">
        <h2 className="text-xl font-bold text-center">Share Your Review</h2>
        <Formik
          initialValues={{
            accuracy: 5,
            communication: 5,
            cleanliness: 5,
            location: 5,
            check_in: 5,
            values: 5,
            comment: "",
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue, values }) => (
            <Form className="space-y-4">
              {(
                [
                  "accuracy",
                  "communication",
                  "cleanliness",
                  "location",
                  "check_in",
                  "values",
                ] as const
              ).map((category) => (
                <div key={category} className="flex items-center space-x-2">
                  <span className="text-gray-700 font-medium">
                    {category.replace("_", " ")}
                  </span>
                  <FiveStartIconForRate
                    className="space-x-1"
                    defaultPoint={Number(
                      values[category as keyof typeof values]
                    )}
                    onChange={(val) => setFieldValue(category, val)}
                  />
                  <ErrorMessage
                    name={category}
                    component="div"
                    className="text-red-500"
                  />
                </div>
              ))}

              <div className="relative">
                <Field
                  as="textarea"
                  name="comment"
                  className="w-full h-16 px-4 py-3 border rounded-3xl !bg-white dark:text-black"
                  placeholder="Share your thoughts ..."
                />
                <ErrorMessage
                  name="comment"
                  component="div"
                  className="text-red-500"
                />
                <ButtonCircle
                  className="absolute right-2 top-1/2 transform -translate-y-1/2"
                  size="w-12 h-12"
                  type="submit"
                  disabled={!values.comment}
                >
                  <ArrowRightIcon className="w-5 h-5" />
                </ButtonCircle>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default ReviewModal;
