import React, { FC } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";
import Badge from "shared/Badge/Badge";
import { currencySymbol } from "shared/CurrencySymbol";
import { Link } from "react-router-dom";
import { PropertyToEdit } from "redux/reducers/Properties/PropertiesSliceTypes";

export interface SimilarCardProps {
  className?: string;
  similarProperties: PropertyToEdit;
}

const SimilarCard: FC<SimilarCardProps> = ({
  className = "",
  similarProperties,
}) => {
  const href = `/listing-stay-detail/${similarProperties?.slug}`;
  const renderSliderGallery = () => {
    return (
      <div className="relative w-full">
        <GallerySlider
          uniqueID={`SimilarCard_${similarProperties?.id}`}
          ratioClass="aspect-w-4 aspect-h-3"
          galleryImgs={
            similarProperties?.place_images?.map((image: any) =>
              String(image)
            ) || []
          }
          href={href}
        />
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className="p-4 space-y-4">
        <div className="space-y-2">
          <div className="flex items-center justify-between">
            <Badge
              name={similarProperties?.property_type?.name || "Property"}
            />
            <Badge
              name={similarProperties?.property_category?.name || "Category"}
            />
          </div>
          <h2 className="text-lg font-medium capitalize line-clamp-1">
            {similarProperties?.title}
          </h2>
          <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-sm">
            <span>{similarProperties?.address}</span>
          </div>
        </div>
        <div className="w-14 border-b border-neutral-100 dark:border-neutral-800"></div>
        <div className="flex justify-between items-center">
          <span className="text-base font-semibold">
            {`${currencySymbol()} ${Number(
              similarProperties?.price_per_month
            )?.toFixed(2)}`}
            <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
              /month
            </span>
          </span>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-SimilarCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow ${className}`}
      data-nc-id="SimilarCard"
    >
      {renderSliderGallery()}
      <Link to={href}>{renderContent()}</Link>
    </div>
  );
};

export default SimilarCard;
