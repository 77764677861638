import React from "react";
import Input from "../../shared/Input/Input";
import CustomDatePickerV2 from "shared/DateField/CustomDatePickerV2";
import { format } from "date-fns";
import moment from "moment";

interface FormFieldProps {
  label: string;
  name: string;
  type?:
    | "text"
    | "email"
    | "date"
    | "checkbox"
    | "radio"
    | "select"
    | "textarea"
    | "password";
  value: any; // Adjusted to handle checkbox, radio, and date types
  onChange: (event: any) => void;
  onBlur: (
    event: React.FocusEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => void;
  error?:any;
  options?: Array<{ value: string; label: string }>; // For select and radio inputs
  rowIntextArea?: number;
  disabled?: boolean;
  placeholder?: string;
  isCrossRequired?: boolean;
  isDOB?: boolean;
  minDate?: Date;
  maxDate?: Date;
}

const FormField: React.FC<FormFieldProps> = ({
  label = "",
  name,
  type = "text",
  value,
  placeholder = "",
  onChange,
  onBlur,
  error,
  options = [],
  rowIntextArea = 3,
  disabled = false,
  minDate,
  maxDate,
}) => {
  const handleChange = (e: any) => {
    if (e && typeof e === "object" && "target" in e) {
      onChange(e);
    } else {
      onChange({ name, value: e });
    }
  };

  return (
    <div className="mb-4">
      <label className="block mb-1" htmlFor={name}>
        {label}:
      </label>

      {type === "select" && (
        <select
          name={name}
          value={value}
          onChange={handleChange}
          onBlur={onBlur}
          disabled={disabled}
          className="block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 h-11 px-4 py-3 rounded-2xl"
        >
          <option value="" disabled>
            Select
          </option>
          {options?.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      )}

      {type === "textarea" && (
        <textarea
          name={name}
          value={value}
          onChange={handleChange}
          onBlur={onBlur}
          disabled={disabled}
          rows={rowIntextArea}
          placeholder={placeholder}
          className="mt-1 p-4 block w-full rounded-2xl border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        />
      )}

      {type === "date" && (
        <CustomDatePickerV2
          placeholder={`Select ${label}`}
          value={value || null}
          onChange={onChange}
          minDate={minDate}
          maxDate={maxDate}
        />
      )}

      {type === "checkbox" && (
        <input
          name={name}
          type="checkbox"
          checked={value}
          onChange={handleChange}
          onBlur={onBlur}
          disabled={disabled}
          className="border p-2 rounded-2xl w-full" // You can adjust the styles as needed
        />
      )}

      {type === "radio" && options && (
        <div className="mt-2">
          {options.map((option) => (
            <label key={option.value} className="inline-flex items-center">
              <input
                type="radio"
                name={name}
                value={option.value}
                checked={value === option.value}
                onChange={handleChange}
                onBlur={onBlur}
                disabled={disabled}
                className="form-radio text-indigo-600"
              />
              <span className="ml-2">{option.label}</span>
            </label>
          ))}
        </div>
      )}

      {(type === "text" || type === "email" || type === "password") && (
        <Input
          name={name}
          type={type}
          value={value}
          onChange={handleChange}
          onBlur={onBlur}
          disabled={disabled}
          placeholder={placeholder}
          // className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        />
      )}

      {error && <p className="mt-2 text-sm text-red-600">{error}</p>}
    </div>
  );
};

export default FormField;
