import React, { FC } from "react";

interface Props {
  dayOfMonth: number;
  date?: Date;
  booked_dates?: [string, string][]; // Mark booked_dates as optional
}

const DatePickerCustomDay: FC<Props> = ({ dayOfMonth, date, booked_dates }) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0); 
  const isBookedDate = (date: Date) => {
    if (!booked_dates) return false;
    return booked_dates.some(([start, end]) => {
      const startDate = new Date(start).getTime();
      const endDate = new Date(end).getTime();
      const currentDate = date.getTime();
      return currentDate >= startDate && currentDate <= endDate;
    });
  };

  const booked = date ? isBookedDate(date) : false;
  const isPastDate = date && date < today;

  return (
    <div className="relative flex justify-center items-center w-10 h-10">
      <span className="flex items-center justify-center w-full h-full">
        {dayOfMonth}
      </span>
      {(booked || isPastDate) && (
        <span className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-xs text-red-500">
          ❌
        </span>
      )}
    </div>
  );
};

export default DatePickerCustomDay;
