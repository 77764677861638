import { Tab } from "@headlessui/react";
import React, { useEffect, useState } from "react";
import CommonLayout from "./CommonLayout";
import { getAllfavoritesList } from "../../redux/reducers/Properties/PropertiesSlice";
import { useDispatch } from "react-redux";
import { AppDispatch } from "redux/store";
import Favorities from "components/StayCard/Favorities";
import Pagination from "shared/Pagination/Pagination";

const AccountSavelists = () => {
  const dispatch = useDispatch<AppDispatch>();
  let [categories] = useState(["Stays", "Experiences", "Cars"]);
  const [favorites, setFavorites] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  const [totalPages, SetTotalPages] = useState(0);

  const fetchFavorites = async (page: number) => {
    setLoading(true);
    try {
      const response = await dispatch(getAllfavoritesList(page)).unwrap();
      setFavorites(response.data);
      const totalPages =
        response?.meta?.total_pages || response?.data?.meta?.total_pages || 1;
      SetTotalPages(totalPages);
    } catch (error) {
      console.error("Error fetching favorites:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFavorites(page);
  }, [page]);

  const handlePageChange = (page: number) => {
    setPage(page);
    fetchFavorites(page);
  };

  const renderSection1 = () => {
    return (
      <div className="space-y-6 sm:space-y-8">
        <div>
          <h2 className="text-3xl font-semibold">Favorite lists</h2>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        <div>
          <Tab.Group>
            {/* <Tab.List className="flex space-x-1 overflow-x-auto">
              {categories.map((item) => (
                <Tab key={item} as={Fragment}>
                  {({ selected }) => (
                    <button
                      className={`flex-shrink-0 block !leading-none font-medium px-5 py-2.5 text-sm sm:text-base sm:px-6 sm:py-3 capitalize rounded-full focus:outline-none ${
                        selected
                          ? "bg-secondary-900 text-secondary-50 "
                          : "text-neutral-500 dark:text-neutral-400 dark:hover:text-neutral-100 hover:text-neutral-900 hover:bg-neutral-100 dark:hover:bg-neutral-800"
                      } `}
                    >
                      {item}
                    </button>
                  )}
                </Tab>
              ))}
            </Tab.List> */}
            <Tab.Panels>
              <Tab.Panel className="mt-8">
                <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3">
                  {loading ? (
                    <p className="text-center col-span-full text-2xl font-semibold">
                      Loading favorites...
                    </p>
                  ) : favorites.length === 0 ? (
                    <p className="text-center col-span-full text-2xl font-semibold">
                      No favorites list
                    </p>
                  ) : (
                    favorites.map((stay) => (
                      <Favorities
                        key={stay.id}
                        data={stay}
                        favorites={favorites}
                        setFavorites={setFavorites}
                      />
                    ))
                  )}
                </div>

                {totalPages > 1 && (
                  <div className="flex mt-16 justify-center items-center space-x-4">
                    <Pagination
                      currentPage={page}
                      totalPages={totalPages}
                      onPageChange={handlePageChange}
                    />
                  </div>
                )}

                {/* <div className="flex mt-11 justify-center items-center">
                  <ButtonSecondary>Show me more</ButtonSecondary>
                </div> */}
              </Tab.Panel>
              {/* <Tab.Panel className="mt-8">
                <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                  {DEMO_EXPERIENCES_LISTINGS.filter((_, i) => i < 8).map(
                    (stay) => (
                      <ExperiencesCard key={stay.id} data={stay} />
                    )
                  )}
                </div>
                <div className="flex mt-11 justify-center items-center">
                  <ButtonSecondary>Show me more</ButtonSecondary>
                </div>
              </Tab.Panel> */}
              {/* <Tab.Panel className="mt-8">
                <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                  {DEMO_CAR_LISTINGS.filter((_, i) => i < 8).map((stay) => (
                    <CarCard key={stay.id} data={stay} />
                  ))}
                </div>
                <div className="flex mt-11 justify-center items-center">
                  <ButtonSecondary>Show me more</ButtonSecondary>
                </div>
              </Tab.Panel> */}
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
    );
  };

  return (
    <div>
      <CommonLayout>{renderSection1()}</CommonLayout>
    </div>
  );
};

export default AccountSavelists;
