import React, { FC } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";
import { DEMO_STAY_LISTINGS } from "data/listings";
import { StayDataType } from "data/types";
import { Link } from "react-router-dom";
import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
import Badge from "shared/Badge/Badge";
import { currencySymbol } from "shared/CurrencySymbol";

export interface FavoritiesProps {
  className?: string;
  data?: StayDataType;
  size?: "default" | "small";
  setAllData?: React.Dispatch<React.SetStateAction<StayDataType[]>>; // New prop
  stayListings?: StayDataType[];
  favorites?:any,
  setFavorites?:  React.Dispatch<React.SetStateAction<any[]>>
}

const Favorities: FC<FavoritiesProps> = ({
  size = "default",
  className = "",
  data,
  favorites,
  setFavorites
}) => {
  const id = data?.id || 0;
  const title = data?.title || "Untitled";
  const price = data?.price_per_month || 0;
  const slug = data?.slug || "";
  const place_images = data?.place_images || [];
  const address = data?.address || "";
  const city = data?.city || "";
  const state = data?.state || "";
  const like = data?.like || false;
  const Href = `/listing-stay-detail/${slug}`;
  const ShortAddress = `${city}, ${state}`;
  const propertyCategory: any = (data as any)?.property_category?.name;
  const propertyType: any = (data as any)?.property_type?.name;

  const renderSliderGallery = () => (
    <div className="relative w-full">
      <GallerySlider
        uniqueID={`StayCard_${id}`}
        ratioClass="aspect-w-4 aspect-h-3"
        galleryImgs={place_images}
        href={Href}
      />
      <BtnLikeIcon
        isLiked={like}
        className="absolute right-3 top-3 z-[1]"
        propertyId={typeof id === "number" ? id : undefined}
        favorites={favorites}
        setFavorites={setFavorites}
      />
    </div>
  );
  const renderContent = () => (
    <div className={size === "default" ? "p-4 space-y-4" : "p-3 space-y-2"}>
      <div className="space-y-2">
        <div className="flex items-center justify-between">
          <Badge name={propertyType} />
          <Badge name={propertyCategory} />
        </div>
        <div className="flex items-center space-x-2">
          <h2
            className={`font-medium capitalize ${
              size === "default" ? "text-lg" : "text-base"
            }`}
          >
            <span className="line-clamp-1">{title}</span>
          </h2>
        </div>
        <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
          {size === "default" && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
              />
            </svg>
          )}
          <span>{address || ShortAddress}</span>
        </div>
      </div>
      <div className="w-14 border-b border-neutral-100 dark:border-neutral-800"></div>
      <div className="flex justify-between items-center">
        <span className="text-base font-semibold">
          {`${currencySymbol()} ${Number(price).toFixed(2)}`}
          {size === "default" && (
            <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
              /month
            </span>
          )}
        </span>
      </div>
    </div>
  );

  return (
    <div
      className={`nc-StayCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow ${className}`}
      data-nc-id="StayCard"
    >
      {renderSliderGallery()}
      <Link to={Href}>{renderContent()}</Link>
    </div>
  );
};

export default Favorities;
