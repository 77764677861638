import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { RootState, AppDispatch } from "redux/store";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import { bookingQueries } from "../../../redux/reducers/UserSlice/UserSlice";
import { useParams, useNavigate } from "react-router-dom";
import { booking } from "../../../redux/reducers/UserSlice/UserSliceType";
import successHandler from "utils/helpers/SuccessHandler";
import CustomDatePickerV2 from "shared/DateField/CustomDatePickerV2";
import { format, eachDayOfInterval } from "date-fns";
import CommonModal from "shared/Modal/CommonModal";
import moment from "moment";
import * as Yup from "yup";

interface ContactOwnerModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSend: (data: booking) => void;
  propertyData?: any;
  currentRoomId?: any;
}

const ContactOwnerModal: React.FC<ContactOwnerModalProps> = ({
  isOpen,
  onClose,
  onSend,
  propertyData = {},
  currentRoomId,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { currentUser } = useSelector((state: RootState) => state.user);
  const { slug } = useParams();
  const id = slug;
  const propertyId = id;
  const isSharedProperty = propertyData.is_shared_property;
  const rooms = propertyData?.rooms?.map((room: any, idx: number) => ({
    data: `${room?.name}`,
    value: room?.id,
  }));
  const [Description, setDescription] = useState("");
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);

  const currentRoom = isSharedProperty
    ? propertyData?.rooms?.find((item: any) => item.id === currentRoomId)
    : null;

  const calculateMonthsDifference = (
    check_in_date: string,
    check_out_date: string
  ): number => {
    const start = moment(check_in_date, "YYYY-MM-DD");
    const end = moment(check_out_date, "YYYY-MM-DD");
    const uniqueMonths = new Set();
    let current = start.clone();
    while (current.isBefore(end, "day") || current.isSame(end, "day")) {
      uniqueMonths.add(`${current.year()}-${current.month() + 1}`);
      current.add(1, "day");
    }

    return uniqueMonths.size;
  };

  const formik = useFormik({
    initialValues: {
      check_in_date: "",
      check_out_date: "",
      guest_count: 1,
      query_for: "",
      query: "",
    },
    validationSchema: Yup.object().shape({
      check_in_date: Yup.date().required("Check-in date is required"),
      check_out_date: Yup.date().required("Check-out date is required"),
      query: Yup.string().required("Query is required"),
    }),
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async (values) => {
      if (!currentUser?.profile_complete) {
        setIsContactModalOpen(true);
        setDescription(
          "Please complete your profile before making a booking Query."
        );
        return;
      }

      const queryFor =
        isSharedProperty && values.query_for
          ? { room_number: [values.query_for] }
          : null;

      onSend(values);

      try {
        await dispatch(
          bookingQueries({
            ...values,
            check_in_date: moment(values.check_in_date).format("YYYY-MM-DD"),
            check_out_date: moment(values.check_out_date).format("YYYY-MM-DD"),
            guest_count: 1,
            query: values.query,
            query_for: queryFor,
            property_id: propertyId,
            no_of_months: calculateMonthsDifference(
              moment(values.check_in_date).format("YYYY-MM-DD"),
              moment(values.check_out_date).format("YYYY-MM-DD")
            ),
          })
        ).unwrap();
        successHandler(
          "Your message was sent! You will be notified by email when a reply is received."
        );
        onClose();
      } catch (error) {
        console.error("Failed to dispatch booking query:", error);
      }

      onClose();
    },
  });

  useEffect(() => {
    if (currentRoom && formik.values.query_for !== currentRoom.id) {
      formik.setFieldValue("query_for", currentRoom.id);
    }
  }, [currentRoom, formik.values.query_for]);

  const getDisabledDates = (booked_dates: [string, string][]) => {
    const disabledDates: Date[] = [];

    booked_dates.forEach(([startDate, endDate]) => {
      if (startDate && endDate) {
        const dateRange = eachDayOfInterval({
          start: moment(startDate).toDate(),
          end: moment(endDate).toDate(),
        });
        disabledDates.push(...dateRange);
      }
    });

    return disabledDates;
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-opacity-50 dark:bg-black-800 dark:bg-opacity-75 z-40 backdrop-blur-md">
      <div className="enquiry-modal bg-white dark:bg-gray-900 rounded-lg shadow-lg p-6 w-full max-w-md relative mt-16 z-50 mr-7">
        <button
          className="absolute top-2 right-2 text-gray-400 hover:text-gray-600 dark:text-gray-300 dark:hover:text-gray-100 focus:outline-none"
          onClick={onClose}
        >
          ×
        </button>
        <h2 className="text-xl font-semibold text-gray-800 dark:text-white mb-2 text-center">
          Contact the owner
        </h2>
        <p className="text-gray-500 dark:text-gray-400 text-center mb-4">
          Please complete the form below to contact the owner.
        </p>

        <form onSubmit={formik.handleSubmit}>
          <label className="block">
            <span className="text-gray-700 dark:text-gray-300">Check In</span>

            <CustomDatePickerV2
              placeholder="Select Start Date"
              value={
                formik.values.check_in_date
                  ? moment(formik.values.check_in_date)
                  : undefined
              }
              onChange={(value) => {
                formik.setFieldValue("check_in_date", value);
                formik.setFieldValue("check_out_date", "");
                formik.setFieldValue("guest_count", "");
              }}
              minDate={moment(
                moment(
                  !isSharedProperty
                    ? propertyData.availability_start
                    : currentRoom?.booking_start
                ).isAfter(moment())
                  ? !isSharedProperty
                    ? propertyData.availability_start
                    : currentRoom?.booking_start
                  : new Date()
              ).toDate()}
              maxDate={
                !isSharedProperty
                  ? propertyData?.availability_end
                    ? moment(propertyData?.availability_end).toDate()
                    : undefined
                  : currentRoom?.booking_end
                  ? moment(currentRoom?.booking_end).toDate()
                  : undefined
              }
              excludeDate={(date) => {
                const disabledDates = getDisabledDates(
                  !isSharedProperty
                    ? propertyData?.booked_dates
                    : currentRoom?.booked_dates
                );
                return disabledDates.some(
                  (disabledDate) =>
                    date.getFullYear() === disabledDate.getFullYear() &&
                    date.getMonth() === disabledDate.getMonth() &&
                    date.getDate() === disabledDate.getDate()
                );
              }}
            />
            {formik.touched.check_in_date && formik.errors.check_in_date && (
              <div style={{ color: "red" }}>{formik.errors.check_in_date}</div>
            )}
          </label>

          <label className="block">
            <span className="text-gray-700 dark:text-gray-300">Check Out</span>
            <CustomDatePickerV2
              placeholder="Select End Date"
              value={
                formik.values.check_out_date
                  ? moment(formik.values.check_out_date)
                  : null
              }
              onChange={(value) => {
                formik.setFieldValue("check_out_date", value);
              }}
              minDate={
                formik.values.check_in_date
                  ? moment(formik.values.check_in_date).add(1, "day").toDate()
                  : moment(
                      moment(
                        !isSharedProperty
                          ? propertyData?.availability_start
                          : currentRoom?.booking_start
                      ).isAfter(moment())
                        ? !isSharedProperty
                          ? propertyData?.availability_start
                          : currentRoom?.booking_start
                        : new Date()
                    ).toDate()
              }
              maxDate={
                !isSharedProperty
                  ? propertyData?.availability_end
                    ? moment(propertyData?.availability_end).toDate()
                    : undefined
                  : currentRoom?.booking_end
                  ? moment(currentRoom?.booking_end).toDate()
                  : undefined
              }
              excludeDate={(date) => {
                const disabledDates = getDisabledDates(
                  !isSharedProperty
                    ? propertyData?.booked_dates
                    : currentRoom?.booked_dates
                );
                return disabledDates.some(
                  (disabledDate) =>
                    date.getFullYear() === disabledDate.getFullYear() &&
                    date.getMonth() === disabledDate.getMonth() &&
                    date.getDate() === disabledDate.getDate()
                );
              }}
            />
            {formik.errors.check_out_date && formik.touched.check_out_date && (
              <div className="text-red-500 text-sm">
                {formik.errors.check_out_date}
              </div>
            )}
          </label>

          <label className="block">
            <span className="text-gray-700 dark:text-gray-300">Guests</span>
            <select
              name="guest_count"
              value={formik.values.guest_count || 1}
              onChange={formik.handleChange}
              className=" mt-1 block w-full text-[#52525B] font-medium rounded-[20px] border-gray-400 dark:border-gray-700 shadow-sm focus:border-indigo-300 dark:focus:border-indigo-500 focus:ring focus:ring-indigo-200 dark:focus:ring-indigo-600 focus:ring-opacity-50 dark:text-white"
            >
              {Array.from({ length: 10 }, (_, n) => (
                <option key={n} value={n + 1}>
                  {n + 1} Guest
                </option>
              ))}
            </select>
            {formik.touched.guest_count && formik.errors.guest_count && (
              <div className="text-red-500 text-sm">
                {formik.errors.guest_count}
              </div>
            )}
          </label>

          {isSharedProperty && (
            <label className="block">
              <span className="text-gray-700 dark:text-gray-300">
                Room-Name
              </span>
              {currentRoom ? (
                <input
                  type="text"
                  value={`${
                    rooms.find((room: any) => room.value === currentRoom.id)
                      ?.data
                  }`}
                  readOnly
                  className="mt-1 block w-full font-medium text-[#52525B] rounded-[20px] border-gray-400 dark:border-gray-700 shadow-sm bg-white-100 dark:bg-gray dark:text-white"
                />
              ) : (
                <select
                  name="query_for"
                  value={formik.values.query_for}
                  onChange={formik.handleChange}
                  className="mt-1 block w-full font-medium text-[#52525B] rounded-[20px] border-gray-400 dark:border-gray-700 shadow-sm"
                  disabled
                >
                  <option value="" className="text-[#52525B]">
                    No rooms available
                  </option>
                </select>
              )}

              {formik.touched.query_for && formik.errors.query_for && (
                <div className="text-red-500 text-sm">
                  {formik.errors.query_for}
                </div>
              )}
            </label>
          )}

          <label className="block">
            <span className="text-gray-700 dark:text-gray-300">Query</span>
            <textarea
              name="query"
              value={formik.values.query}
              onChange={formik.handleChange}
              placeholder="Your message"
              className="mt-1 block w-full font-medium rounded-md placeholder-[#52525B] text-[#5252 border-gray-400 dark:border-gray-700 shadow-sm focus:border-indigo-300 dark:focus:border-indigo-500 focus:ring focus:ring-indigo-200 dark:focus:ring-indigo-600 focus:ring-opacity-50  dark:text-white dark:placeholder-white"
            />
            {formik.touched.query && formik.errors.query && (
              <div className="text-red-500 text-sm">{formik.errors.query}</div>
            )}
          </label>

          <button
            type="submit"
            className="w-full py-2 bg-orange-500 text-white font-semibold rounded-md hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 dark:focus:ring-offset-gray-900 dark:bg-orange-400 dark:hover:bg-orange-500 mt-4"
          >
            Send Message
          </button>
        </form>
      </div>
      <CommonModal
        isOpen={isContactModalOpen}
        onClose={() => {
          setIsContactModalOpen(false);
          onClose();
        }}
        description={Description}
      />
    </div>
  );
};

export default ContactOwnerModal;
