import React, { FC, useState } from "react";
import StayCardH from "components/StayCardH/StayCardH";
import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Checkbox from "shared/Checkbox/Checkbox";
import TabFilters from "./TabFilters";
import Heading2 from "components/Heading/Heading2";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import { Link } from "react-router-dom";
import Pagination from "shared/Pagination/Pagination";

export interface SectionGridHasMapProps {}

const SectionGridHasMap: FC<SectionGridHasMapProps> = () => {
  const [currentHoverID, setCurrentHoverID] = useState<string | number>(-1);
  const [showFullMapFixed, setShowFullMapFixed] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState<any>(null);
  const [page, setPage] = useState(1);
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY || "",
    libraries: ["places"],
  });
  const storedSearchData = useSelector(
    (state: RootState) => state.property.searchedData
  );

  const { latitude, longitude, searchedResult } = storedSearchData || {};

  const properties = searchedResult?.properties || [];

  const modifiedLocation = {
    lat: latitude,
    lng: longitude,
  };

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return (
      <div className="flex items-center justify-center py-10">
        <div className="flex items-center justify-center space-x-2">
          <div className="w-10 h-10 border-4 border-t-4 border-gray-300 border-t-primary-6000 rounded-full animate-spin"></div>
        </div>
      </div>
    );
  }

  const mapContainerStyle = {
    width: "100%",
    height: "100%",
  };

  return (
    <div>
      <div className="relative flex min-h-screen">
        {/* CARDSSSS */}
        <div className="min-h-screen w-full xl:w-[780px] 2xl:w-[880px] flex-shrink-0 xl:px-8 ">
          <Heading2 />
          <div className="mb-8 lg:mb-11">
            <TabFilters page={page} />
          </div>
          {properties?.length > 0 ? (
            <>
              <div className="grid grid-cols-1 gap-8">
                {properties.map((item: any) => (
                  <div
                    key={item.id}
                    onMouseEnter={() => setCurrentHoverID((_) => item.id)}
                    onMouseLeave={() => setCurrentHoverID((_) => -1)}
                  >
                    <StayCardH data={item} />
                  </div>
                ))}
              </div>
              {searchedResult?.total_pages > 1 && (
                <div className="flex mt-16 justify-center items-center">
                  {/* <Pagination /> */}
                </div>
              )}
            </>
          ) : (
            <div className="flex justify-center items-center text-5xl">
              No property found
            </div>
          )}
        </div>

        {!showFullMapFixed && (
          <div
            className="flex xl:hidden items-center justify-center fixed bottom-8 left-1/2 transform -translate-x-1/2 px-6 py-2 bg-neutral-900 text-white shadow-2xl rounded-full z-30  space-x-3 text-sm cursor-pointer"
            onClick={() => setShowFullMapFixed(true)}
          >
            <i className="text-lg las la-map"></i>
            <span>Show map</span>
          </div>
        )}

        {/* MAPPPPP */}
        <div
          className={`xl:flex-grow xl:static xl:block ${
            showFullMapFixed ? "fixed inset-0 z-50" : "hidden"
          }`}
        >
          {showFullMapFixed && (
            <ButtonClose
              onClick={() => setShowFullMapFixed(false)}
              className="bg-white absolute z-50 left-3 top-3 shadow-lg rounded-xl w-10 h-10"
            />
          )}

          <div className="fixed xl:sticky top-0 xl:top-[88px] left-0 w-full h-full xl:h-[calc(100vh-88px)] rounded-md overflow-hidden">
            <div className="absolute bottom-5 left-3 lg:bottom-auto lg:top-2.5 lg:left-1/2 transform lg:-translate-x-1/2 py-2 px-4 bg-white dark:bg-neutral-800 shadow-xl z-10 rounded-2xl min-w-max">
              <Checkbox
                className="text-xs xl:text-sm"
                name="xx"
                label="Search as I move the map"
              />
            </div>
            <GoogleMap
              mapContainerStyle={mapContainerStyle}
              zoom={11}
              center={modifiedLocation}
              options={{
                maxZoom: 13.5,
                minZoom: 10,
                zoomControl: true ,
                mapTypeControl: false,
                streetViewControl: false,
                fullscreenControl: false,
                disableDefaultUI: true,
                keyboardShortcuts: false,
                styles: [
                  {
                    featureType: "all",
                    elementType: "labels",
                    stylers: [{ visibility: "off" }],
                  },
                  {
                    featureType: "road",
                    elementType: "labels",
                    stylers: [{ visibility: "simplified" }, { lightness: 40 }],
                  },
                  {
                    featureType: "road.arterial",
                    elementType: "labels",
                    stylers: [{ visibility: "on" }],
                  },
                  {
                    featureType: "road.highway",
                    elementType: "labels",
                    stylers: [{ visibility: "on" }, { weight: 1.5 }],
                  },
                  // Keep major roads visible, but simplify their appearance
                  {
                    featureType: "road.highway",
                    elementType: "geometry",
                    stylers: [{ visibility: "simplified" }, { lightness: 20 }],
                  },
                  {
                    featureType: "road.arterial",
                    elementType: "geometry",
                    stylers: [{ visibility: "simplified" }, { lightness: 40 }],
                  },
                  // Hide local streets to reduce clutter
                  {
                    featureType: "road.local",
                    elementType: "geometry",
                    stylers: [{ visibility: "off" }],
                  },
                  // Show essential POIs like shops, markets, and restaurants
                  {
                    featureType: "poi.business",
                    elementType: "labels",
                    stylers: [{ visibility: "simplified" }],
                  },
                  {
                    featureType: "poi.government",
                    elementType: "labels",
                    stylers: [{ visibility: "on" }],
                  },
                  {
                    featureType: "poi.place_of_worship",
                    elementType: "labels",
                    stylers: [{ visibility: "off" }],
                  },
                  {
                    featureType: "poi.medical",
                    elementType: "labels",
                    stylers: [{ visibility: "simplified" }],
                  },
                  {
                    featureType: "poi.school",
                    elementType: "labels",
                    stylers: [{ visibility: "simplified" }],
                  },
                  // Hide unnecessary landscape details but keep city layout clear
                  {
                    featureType: "landscape",
                    elementType: "geometry",
                    stylers: [{ lightness: 60 }],
                  },
                  // Hide transit routes (optional)
                  {
                    featureType: "transit",
                    elementType: "geometry",
                    stylers: [{ visibility: "off" }],
                  },
                ],
              }}
            >
              {searchedResult?.properties?.map((property: any) => (
                <React.Fragment key={property.id}>
                  <div>
                    <Marker
                      position={{
                        lat: Number(property.latitude),
                        lng: Number(property.longitude),
                      }}
                      onClick={() => {
                        setSelectedProperty(property);
                      }}
                      options={{ clickable: true }}
                    >
                      {selectedProperty &&
                        selectedProperty?.id === property?.id && (
                          <InfoWindow
                            position={{
                              lat: Number(selectedProperty.latitude),
                              lng: Number(selectedProperty.longitude),
                            }}
                            onCloseClick={() => setSelectedProperty(null)}
                          >
                            <Link
                              to={`/listing-stay-detail/${selectedProperty.slug}`}
                            >
                              <div className="w-72 bg-white rounded-lg  overflow-hidden relative font-sans">
                                <div className="relative">
                                  {/* <span className="absolute top-2 left-2 bg-red-500 text-white text-xs px-2 py-1 rounded-md">
                                    {selectedProperty?.property_state}
                                  </span> */}
                                  <img
                                    src={selectedProperty?.place_images[0]}
                                    alt="Property"
                                    className="w-full h-40 object-cover"
                                  />
                                </div>

                                <div className="p-3">
                                  <h3 className="text-lg font-bold flex items-center">
                                    <span className="truncate w-[60%] overflow-hidden whitespace-nowrap">
                                      {selectedProperty?.title}
                                    </span>
                                    <span className="text-orange-500 font-bold w-[40%] text-right">
                                      USD {selectedProperty.price_per_month}
                                    </span>
                                  </h3>
                                  <p className="text-sm text-gray-600">
                                    {selectedProperty.is_shared_property ? 'Shared Property' : 'Entire Property'} /{" "}
                                    {selectedProperty?.property_category?.name}
                                  </p>

                                  <div className="flex justify-between items-center mt-2 text-gray-700 text-sm">
                                    <span className="flex items-center gap-1">
                                      🛏 {selectedProperty?.total_bedrooms}
                                    </span>
                                    {/* <span className="flex items-center gap-1">
                                      👤 {selectedProperty?.guest_number}
                                    </span> */}
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </InfoWindow>
                        )}
                    </Marker>
                  </div>
                </React.Fragment>
              ))}
            </GoogleMap>
          </div>
        </div>
      </div>
      {searchedResult?.total_pages > 1 && (
        <div className="flex mt-16 justify-center items-center">
          <Pagination
            currentPage={page}
            totalPages={searchedResult?.total_pages}
            onPageChange={setPage}
          />
        </div>
      )}
    </div>
  );
};

export default SectionGridHasMap;