import React from "react";
import { PropertyToEdit } from "redux/reducers/Properties/PropertiesSliceTypes";

interface CancellationProps {
  propertyData: PropertyToEdit | null;
}

const Cancellation: React.FC<CancellationProps> = ({ propertyData }) => {
  return (
    <div
      className={`listingSection__wrap ${
        propertyData?.is_shared_property ? "lg:w-3/4" : "w-full"
      }`}
    >
      <h2 className="text-2xl font-semibold">Things to know</h2>
      <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />
      <div>
        <h4 className="text-lg font-semibold">Cancellation policy</h4>
        <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
          {propertyData?.cancellation_policy?.description}
        </span>
      </div>
    </div>
  );
};

export default Cancellation;
