import React, { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import NcImage from "shared/NcImage/NcImage";
import "react-loading-skeleton/dist/skeleton.css";
import Skeleton from "react-loading-skeleton";
import "./MessageList.scss";

interface MessageBody {
  name?: string;
  url?: string | null;
}

interface Sender {
  id?: number;
  name?: string;
  image?: string | null;
}

interface Message {
  id: number;
  body: MessageBody;
  event_message: string | null;
  event_for: string;
  sender_id: number;
  receiver_id: number;
  sender: Sender;
  receiver: Sender;
  "read?": boolean;
  attachments: any[];
  created_at: string;
}

interface MessageGroup {
  date: string;
  messages: Message[];
}

interface ChatData {
  id: number;
  sender_id: number;
  receiver_id: number;
  sender: Sender;
  receiver: Sender;
  last_message: string;
  last_message_time: string;
  unread_count: number;
  property: any;
  booking: any;
  booking_query: any;
}

interface ApiResponse {
  data: string;
  chat: ChatData;
  message: MessageGroup[];
  success: boolean;
  meta: {
    total_pages: number;
    current_page: number;
    next_page: number | null;
    prev_page: number | null;
    total_count: number;
  };
}

interface MessageListProps {
  modifiedBookingMap: ApiResponse[] | ApiResponse;
  showMore: boolean;

  loadMoreMessages: (pge: number) => Promise<void>;
}

const MessageList: React.FC<MessageListProps> = ({
  modifiedBookingMap,
  loadMoreMessages,
  showMore,
}) => {
  const bookingData = Array.isArray(modifiedBookingMap)
    ? modifiedBookingMap
    : [modifiedBookingMap];
  const { currentUser } = useSelector((state: RootState) => state.user);

  const myId = currentUser?.id;
  const isHost = currentUser?.is_host;
  const isStudent = currentUser?.is_student;

  const shouldShowMessage = (item: Message) => {
    const isCurrentUser = myId === item.sender_id;
    const isSystemMessage =
      (isHost && item.event_for === "host") ||
      (isStudent && item.event_for === "student");

    return {
      show: item.event_for === "both" || isCurrentUser || isSystemMessage,
      isSystemMessage,
    };
  };

  const [loading, setLoading] = useState(false);
  const nextPage = Array.isArray(modifiedBookingMap)
    ? modifiedBookingMap[0]?.meta?.next_page
    : modifiedBookingMap?.meta?.next_page;

  const handleLoadMore = async () => {
    if (!nextPage) return;

    setLoading(true);
    try {
      await loadMoreMessages(nextPage);
    } finally {
      setLoading(false);
    }
  };

  if (showMore) {
    return (
      <div className="space-y-4">
        <div className="flex items-center justify-center">
          <Skeleton width={100} height={20} />
        </div>
        {[...Array(3)].map((_, index) => (
          <div key={index} className="flex flex-col space-y-4">
            <div className="flex items-start space-x-3">
              <div className="flex-shrink-0">
                <Skeleton circle width={32} height={32} />
              </div>
              <div className="flex-1 w-full">
                <Skeleton width="95%" height={80} />
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }

  if (!bookingData || bookingData.length === 0) {
    return <div className="text-center p-4">No messages to display</div>;
  }

  return (
    <div className="flex flex-col space-y-6 max-h-[600px] overflow-y-auto scrollbar scrollbar-thumb-blue-600 scrollbar-track-gray-100 custom-design pb-2">
      {bookingData.map((response, index) => (
        <div key={`response-${index}`} className="space-y-4">
          {response.message.map((group, groupIndex) => (
            <div key={`group-${groupIndex}`} className="space-y-4">
              <div className="flex items-center justify-center">
                <div className="bg-gray-100 dark:bg-gray-800 px-4 py-2 rounded-full">
                  <span className="text-sm font-medium text-gray-600 dark:text-gray-300">
                    {group.date}
                  </span>
                </div>
              </div>
              <div className="space-y-4">
                {group.messages.map((msg, msgIndex) => {
                  const { show, isSystemMessage } = shouldShowMessage(msg);
                  if (!show) return null;

                  return (
                    <div
                      key={`msg-${msgIndex}`}
                      className="group flex flex-col ml-1 inline-block bg-white dark:bg-gray-700 rounded-lg w-[95%] px-4 py-4 shadow hover:bg-gray-100 dark:hover:bg-gray-800 transition"
                    >
                      {isSystemMessage ? (
                        <div className="flex justify-start">
                          <div className="rounded-lg px-4 py-2">
                            <div className="mb-1 flex gap-5">
                              <span className="font-bold text-orange-500">
                                System Message:
                              </span>
                              <div className="mt-1 text-xs text-gray-500 dark:text-gray-300">
                                {new Date(msg.created_at).toLocaleString(
                                  "en-US",
                                  {
                                    month: "long",
                                    day: "numeric",
                                    year: "numeric",
                                  }
                                )}
                                ,{" "}
                                {new Date(msg.created_at).toLocaleString(
                                  "en-US",
                                  {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                    hour12: true,
                                  }
                                )}
                              </div>
                            </div>
                            <p className="mt-1 text-xs text-gray-500 dark:text-gray-300">
                              {msg.body?.name}
                            </p>
                          </div>
                        </div>
                      ) : (
                        <div className="flex items-start space-x-3">
                          <div className="flex-shrink-0">
                            {msg.sender.image ? (
                              <NcImage
                                src={msg.sender.image}
                                alt={msg.sender.name || "User"}
                                className="h-12 w-12 rounded-full object-cover"
                              />
                            ) : (
                              <div className="h-12 w-12 rounded-full bg-blue-500 flex items-center justify-center">
                                <span className="text-sm font-medium text-white">
                                  {msg.sender.name
                                    ? msg.sender.name.charAt(0).toUpperCase()
                                    : "?"}
                                </span>
                              </div>
                            )}
                          </div>
                          <div className="flex-1 w-full">
                            <div className="">
                              <div className="mb-1 flex gap-5">
                                <span className="font-bold text-gray-500 dark:text-gray-400">
                                  {msg.sender.name || "User"}
                                </span>
                                <div className="mt-1 text-xs text-gray-500 dark:text-gray-300">
                                  {new Date(msg.created_at).toLocaleString(
                                    "en-US",
                                    {
                                      month: "long",
                                      day: "numeric",
                                      year: "numeric",
                                    }
                                  )}
                                  ,{" "}
                                  {new Date(msg.created_at).toLocaleString(
                                    "en-US",
                                    {
                                      hour: "2-digit",
                                      minute: "2-digit",
                                      hour12: true,
                                    }
                                  )}
                                </div>
                              </div>
                              {msg.body?.url ? (
                                <button
                                  onClick={() =>
                                    msg.body?.url &&
                                    window.open(msg.body.url, "_blank")
                                  }
                                  className="text-sm text-blue-600 dark:text-blue-400 font-medium hover:underline flex items-center gap-1 group-hover:text-orange-500"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={2}
                                    stroke="currentColor"
                                    className="w-12 h-12"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M7 3h6l5 5v11a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2z"
                                    />
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M8 10h8M8 14h5"
                                    />
                                  </svg>
                                  {msg.body?.name}
                                </button>
                              ) : (
                                <p className="text-sm text-gray-800 dark:text-gray-200">
                                  {msg.body?.name}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          ))}
        </div>
      ))}
      {nextPage && (
        <div className="text-center p-4">
          <ButtonPrimary
            onClick={handleLoadMore}
            disabled={loading}
            loading={loading}
          >
            Load More Messages
          </ButtonPrimary>
        </div>
      )}
    </div>
  );
};

export default MessageList;
