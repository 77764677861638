import React, { FC } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";
import { DEMO_STAY_LISTINGS } from "data/listings";
import { StayDataType } from "data/types";
import StartRating from "components/StartRating/StartRating";
import { Link } from "react-router-dom";
import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
import SaleOffBadge from "components/SaleOffBadge/SaleOffBadge";
import Badge from "shared/Badge/Badge";
import { RootState } from "redux/store";
import { useSelector } from "react-redux";

export interface StayCardHProps {
  className?: string;
  data?: StayDataType;
}

const DEMO_DATA = DEMO_STAY_LISTINGS[0];

const StayCardH: FC<StayCardHProps> = ({
  className = "",
  data = DEMO_DATA,
}) => {
  const {
    title,
    href = data.href || `/listing-stay-detail/${data?.slug}`,
    isAds,
    price_per_month: price,
    average,
    id,
    city,
    state,
    place_images,
    total_bathrooms,
    total_bedrooms,
    total_rooms,
    count,
    next_available_date,
    is_favorite,
    is_feature,
  } = data;

  const PropertyStatus = data.is_shared_property;

  const modifiedPropertyStatus = (
    PropertyStatus: string | undefined | boolean
  ) => {
    const status =
      typeof PropertyStatus === "boolean"
        ? PropertyStatus.toString()
        : PropertyStatus;
    switch (status) {
      case "true":
        return "Shared Property";
      case "false":
        return "Entire Property";
      default:
        return "";
    }
  };

  const Propertyfeatures = (is_feature: string | undefined | boolean) => {
    const status =
      typeof is_feature === "boolean" ? is_feature.toString() : is_feature;
    switch (status) {
      case "true":
        return "Featured";
      case "false":
        return "";
      default:
        return "";
    }
  };

  console.log("Propertyfeatures", Propertyfeatures(is_feature));

  const { currentUser } = useSelector((state: RootState) => state.user);
  const shortAddress = `${city}, ${state}`;

  const renderSliderGallery = () => {
    return (
      <div className="relative flex-shrink-0 w-full md:w-72 ">
        <GallerySlider
          ratioClass="aspect-w-6 aspect-h-5"
          galleryImgs={place_images}
          uniqueID={`StayCardH_${id}`}
          href={href}
        />

        {Propertyfeatures(is_feature) ? (
          <div className="absolute top-6 -left-5 bg-orange-500 text-white px-4 py-1 text-sm font-bold rotate-[-32deg] w-[182px] text-center">
            {Propertyfeatures(is_feature)}
          </div>
        ) : (
          ""
        )}

        {currentUser?.is_student && (
          <BtnLikeIcon
            isLiked={is_favorite}
            className="absolute right-3 top-3"
            propertyId={typeof data?.id === "number" ? data.id : undefined}
            path="propertySearch"
          />
        )}
        {/* {saleOff && <SaleOffBadge className="absolute left-3 top-3" />}  */}
      </div>
    );
  };

  const renderTienIch = () => {
    return (
      <div className="hidden sm:grid grid-cols-3 gap-2">
        <div className="space-y-3">
          <div className="flex items-center space-x-3">
            <i className="las la-bath text-lg"></i>
            <span className="text-sm text-neutral-500 dark:text-neutral-400">
              {total_bathrooms} baths
            </span>
          </div>
        </div>
        <div className="flex items-center space-x-3">
          <i className="las la-door-open text-lg"></i>
          <span className="text-sm text-neutral-500 dark:text-neutral-400">
            {total_bedrooms} bedrooms
          </span>
        </div>
        <div className="flex items-center space-x-3">
          <i className="las la-door-open  text-lg"></i>
          <span className="text-sm text-neutral-500 dark:text-neutral-400">
            {total_rooms} room
          </span>
        </div>
      </div>
    );
  };
  const renderContent = () => {
    return (
      <div className="flex-grow p-3 sm:p-5 flex flex-col">
        <div className="space-y-2">
          <div className="flex items-center justify-between">
            <Badge name={next_available_date || "Property"} />
            <Badge
              name={modifiedPropertyStatus(PropertyStatus) || "Property"}
            />
          </div>
          <div className="text-sm text-neutral-500 dark:text-neutral-400">
            <span>{shortAddress}</span>
          </div>
          <div className="flex items-center space-x-2">
            {isAds && <Badge name="ADS" color="green" />}
            <h2 className="text-lg font-medium capitalize">
              <span className="line-clamp-1">{title}</span>
            </h2>
          </div>
        </div>
        <div className="hidden sm:block w-14 border-b border-neutral-100 dark:border-neutral-800 my-4"></div>
        {renderTienIch()}
        <div className="w-14 border-b border-neutral-100 dark:border-neutral-800 my-4"></div>
        <div className="flex justify-between items-end">
          <StartRating reviewCount={average} reviewTotal={count} />
          <span className="text-base font-semibold text-secondary-500">
            ${price || "N/A"}
            {` `}
            <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
              /month
            </span>
          </span>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-StayCardH group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden hover:shadow-xl transition-shadow will-change-transform ${className}`}
      data-nc-id="StayCardH"
    >
      <Link to={href} className="absolute inset-0"></Link>
      <div className="grid grid-cols-1 md:flex md:flex-row ">
        {renderSliderGallery()}
        {renderContent()}
      </div>
    </div>
  );
};

export default StayCardH;
