import CommentListing from "components/CommentListing/CommentListing";
import React, { useEffect, useState } from "react";
import { PropertyToEdit } from "../../../../redux/reducers/Properties/PropertiesSliceTypes";
import { getReviews } from "../../../../redux/reducers/Properties/PropertiesSlice";
import { AppDispatch } from "redux/store";
import { useDispatch } from "react-redux";

interface ReviewProps {
  propertyData: PropertyToEdit | null;
}

const Review: React.FC<ReviewProps> = ({ propertyData }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [showReview, setShowReview] = useState(false);
  const [reviews, setReviews] = useState<any[]>([]);

  const getReviewData = async () => {
    if (!propertyData) {
      return;
    }
    const propertyId = propertyData.id;
    try {
      setShowReview(true);
      const result = await dispatch(getReviews(propertyId)).unwrap();
      setReviews(result?.reviews || []);
    } catch (error: any) {
    } finally {
      setShowReview(false);
    }
  };

  useEffect(() => {
    getReviewData();
  }, []);

  return (
    <div
      className={`listingSection__wrap ${
        propertyData?.is_shared_property ? "lg:w-3/4" : "w-full"
      }`}
    >
      <h2 className="text-2xl font-semibold">{reviews?.length} Reviews</h2>
      <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
      <div>
        {reviews.length > 0 ? (
          reviews.map((review) => (
            <CommentListing
              key={review.id}
              data={review}
              propertyData={propertyData}
            />
          ))
        ) : (
          <div className="text-center text-gray-500 dark:text-gray-400 py-4">
            No Reviews Available
          </div>
        )}
      </div>
    </div>
  );
};

export default Review;
