import React, { useEffect, useState } from "react";
import { getInvoice } from "../../../redux/reducers/Properties/PropertiesSlice";
import { useDispatch } from "react-redux";
import { AppDispatch } from "redux/store";
import { currencySymbol } from "shared/CurrencySymbol";
import { InvoiceData } from "../../../redux/reducers/Properties/PropertiesSliceTypes";
import CustomDatePickerV2 from "shared/DateField/CustomDatePickerV2";
import Skeleton from "react-loading-skeleton";
import moment from "moment";
import Pagination from "shared/Pagination/Pagination";

interface TotalItems {
  invoice_amt: string;
  confirmed_amt: string;
}

const Invoice: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [invoice, setInvoice] = useState<InvoiceData[]>([]);
  const [openInvoiceId, setOpenInvoiceId] = useState<number | null>(null);
  const [filters, setFilters] = useState({
    fromDate: "",
    toDate: "",
    invoiceType: "Reservation fee",
    status: "Any",
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [page, setPage] = useState(1);
  const [totalPages, SetTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState<TotalItems | null>(null);

  const fetchInvoices = async (page: number = 1) => {
    setLoading(true);
    setError(null);
    setInvoice([]);

    try {
      const response = await dispatch(
        getInvoice({
          start_date: filters.fromDate,
          end_date: filters.toDate,
          type: filters.invoiceType,
          status: filters.status === "Any" ? undefined : filters.status,
          page,
        })
      ).unwrap();
      if (response?.success) {
        setInvoice(response.data);
        setTotalItems(response);
        const totalPages =
          response?.meta?.total_pages || response?.data?.meta?.total_pages || 1;
        SetTotalPages(totalPages);
      } else {
        setInvoice([]);
        setError(response?.error || "No invoices found.");
      }
    } catch (err: any) {
      const errorMessage =
        err.response?.data?.error || err.message || "Failed to fetch invoices.";

      setInvoice([]);
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchInvoices(page);
  }, [dispatch, filters]);

  const handlePageChange = (page: number) => {
    setPage(page);
    fetchInvoices(page);
  };

  useEffect(() => {
    setPage(1);
    fetchInvoices(1);
  }, [filters]);

  const toggleInvoice = (invoiceId: number) => {
    setOpenInvoiceId(openInvoiceId === invoiceId ? null : invoiceId);
  };

  return (
    <div className="p-6 bg-gray-100 dark:bg-gray-900 min-h-screen container">
      <h2 className="text-2xl font-bold text-orange-500 dark:text-orange-500 mb-6">
        Invoices
      </h2>
      <div className="grid grid-cols-4 gap-4 bg-white dark:bg-gray-800 p-4 rounded-lg shadow-md">
        <CustomDatePickerV2
          placeholder="From Date"
          value={filters.fromDate ? new Date(filters.fromDate) : null}
          onChange={(date: Date | null) =>
            setFilters({
              ...filters,
              fromDate: date ? date.toLocaleDateString("sv-SE") : "",
              toDate:
                date && filters.toDate && new Date(filters.toDate) < date
                  ? ""
                  : filters.toDate,
            })
          }
        />

        <CustomDatePickerV2
          placeholder="To Date"
          value={filters.toDate ? new Date(filters.toDate) : null}
          minDate={
            filters.fromDate
              ? moment(filters.fromDate).add(1, "day").toDate()
              : new Date()
          }
          onChange={(date: Date | null) =>
            setFilters({
              ...filters,
              toDate: date ? date.toLocaleDateString("sv-SE") : "",
            })
          }
        />

        <select
          className="border border-gray-300 dark:border-gray-600 p-2 rounded-2xl bg-white dark:bg-gray-700 dark:text-white"
          value="Reservation fee"
          disabled
        >
          <option>Reservation fee</option>
        </select>
        <select
          className="border border-gray-300 dark:border-gray-600 p-2 rounded-2xl bg-white dark:bg-gray-700 dark:text-white"
          value={filters.status}
          onChange={(e) => {
            setFilters({
              ...filters,
              status: e.target.value,
            });
          }}
        >
          <option value="any">Any</option>
          <option value="confirmed">Confirmed</option>
          <option value="issued">Issued</option>
        </select>
      </div>

      <div className="mt-5 mb-5 text-gray-700 dark:text-gray-300 flex gap-4">
        <p>
          <strong>Total Invoices Confirmed:</strong>{" "}
          <span className="ml-2">
            {" "}
            {`${currencySymbol()} ${Number(totalItems?.confirmed_amt)?.toFixed(
              2
            )}`}
          </span>
        </p>
        <p>
          <strong>Total Invoices Issued:</strong>{" "}
          <span className="ml-2 dark:text-white">
            {" "}
            {`${currencySymbol()} ${Number(totalItems?.invoice_amt)?.toFixed(
              2
            )}`}
          </span>
        </p>
      </div>
      <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow-md">
        <div className="grid grid-cols-6 bg-gray-200 dark:bg-gray-700 pl-2 pr-2 pb-5 pt-5 font-bold border-b border-gray-300 dark:border-gray-600">
          <div className="text-center">Invoice No.</div>
          <div className="text-center">Date</div>
          <div className="text-center">Invoice Type</div>
          <div className="text-center">Billing Type</div>
          <div className="text-center">Status</div>
          <div className="text-center">Invoice Amount</div>
        </div>

        {loading && (
          <p className="text-gray-600 dark:text-gray-300 text-lg font-bold text-center flex justify-center items-center h-24">
            Loading invoices...
          </p>
        )}
        {loading ? (
          <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow-md">
            <div className="grid grid-cols-6 bg-gray-200 dark:bg-gray-700 pl-2 pr-2 pb-5 pt-5 font-bold border-b border-gray-300 dark:border-gray-600">
              {Array(6)
                .fill(null)
                .map((_, index) => (
                  <Skeleton key={index} height={30} />
                ))}
            </div>
            {Array(5)
              .fill(null)
              .map((_, index) => (
                <div
                  key={index}
                  className="grid grid-cols-6 border-t border-gray-300 dark:border-gray-600 pl-2 pr-2 pb-5 pt-5  text-center"
                >
                  {Array(6)
                    .fill(null)
                    .map((_, subIndex) => (
                      <Skeleton key={subIndex} height={30} />
                    ))}
                </div>
              ))}
          </div>
        ) : invoice.length > 0 ? (
          invoice.map((invoices) => (
            <React.Fragment key={invoices.id}>
              <div
                className="grid grid-cols-6 border-t border-gray-300 dark:border-gray-600 pl-2 pr-2 pb-5 pt-5 text-center cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-700"
                onClick={() => toggleInvoice(invoices?.id)}
              >
                <div>{invoices.id}</div>
                <div>{invoices.created_at}</div>
                <div>{invoices.invoice_type}</div>
                <div>{invoices.billing_type}</div>
                <div>{invoices.status}</div>
                <div>
                  {`${currencySymbol()} ${Number(invoices?.total)?.toFixed(2)}`}
                </div>
              </div>
              {openInvoiceId === invoices.id && (
                <div className="bg-gray-50 dark:bg-gray-800 p-4 rounded-lg shadow-md border border-gray-300 dark:border-gray-600 mt-2 col-span-6 mb-5">
                  <div className="p-4 bg-white dark:bg-gray-900 rounded-lg shadow w-1/2">
                    <h3 className="text-lg font-semibold mb-5">
                      Invoice <span>INV{invoices?.id}</span>
                    </h3>

                    <p>
                      <strong>Period:</strong> {invoices?.invoice_period}
                    </p>
                    <p>
                      <strong>No. of Months:</strong> {invoices?.no_of_months}
                    </p>
                    <p>
                      <strong>Guests:</strong> {invoices?.number_of_guests}
                    </p>
                    <p>
                      <strong>Price per Month:</strong>{" "}
                      {`${currencySymbol()} ${Number(
                        invoices?.first_month_price
                      )?.toFixed(2)}`}
                    </p>

                    <div className="mt-4">
                      <div className="flex font-semibold gap-10 mt-2 border-b border-gray-300 dark:border-gray-600">
                        <span>Subtotal</span>
                        <span>Details</span>
                      </div>
                      <div className="flex gap-10 mt-2">
                        <span>
                          {`${currencySymbol()} ${Number(
                            invoices?.first_month_price
                          )?.toFixed(2)}`}
                        </span>
                        <span>First Month's Rent</span>
                      </div>
                      {invoices?.service_tax !== null && (
                        <div className="flex gap-10 mt-2">
                          <span>
                            {`${currencySymbol()} ${Number(
                              invoices?.service_tax
                            )?.toFixed(2)}`}
                          </span>
                          <span>Service Fee:</span>
                        </div>
                      )}
                      {/* {invoices?.calculate_cleaning_fee !== null && (
                        <div className="flex gap-10 mt-2">
                          <span>
                            {`${currencySymbol()} ${Number(
                              invoices?.calculate_cleaning_fee
                            )?.toFixed(2)}`}
                          </span>
                          <span>Calculate cleaning fee</span>
                        </div>
                      )}
                      {invoices?.calculate_city_fee !== null && (
                        <div className="flex gap-10 mt-2">
                          <span>
                            {`${currencySymbol()} ${Number(
                              invoices?.calculate_city_fee
                            )?.toFixed(2)}`}
                          </span>
                          <span>Calculate city fee</span>
                        </div>
                      )} */}
                      {Number(invoices?.subtotal) > 0 && (
                        <div className="flex gap-10 mt-2 font-semibold">
                          <span>
                            {`${currencySymbol()} ${(
                              Number(invoices?.subtotal) -
                              Number(invoices?.discount_amount)
                            )?.toFixed(2)}`}
                          </span>
                          <span>Invoice Total</span>
                        </div>
                      )}
                      {invoices?.extra_guest_amount !== null && (
                        <div className="flex gap-10 mt-2">
                          <span>
                            {`${currencySymbol()} ${Number(
                              invoices?.extra_guest_amount
                            )?.toFixed(2)}`}
                          </span>
                          <span>Extra guest Amount</span>
                        </div>
                      )}
                      <div className="flex gap-10 font-bold mt-4 text-lg">
                        <span>Total:</span>
                        <span className="text-blue-600">
                          {`${currencySymbol()} ${Number(
                            invoices?.total
                          )?.toFixed(2)}`}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </React.Fragment>
          ))
        ) : (
          <div className="text-red-500 text-lg font-bold text-center flex justify-center items-center h-24">
            No invoices found.
          </div>
        )}
        {totalPages > 1 && (
          <div className="flex mt-16 justify-center items-center space-x-4">
            <Pagination
              currentPage={page}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Invoice;
