import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import { eachDayOfInterval } from "date-fns";
import moment from "moment";
import DatePickerCustomDay from "components/DatePickerCustomDay";
import DatePickerCustomHeaderTwoMonth from "components/DatePickerCustomHeaderTwoMonth";
import { SectionDateRangeProps } from "redux/reducers/Properties/PropertiesSliceTypes";

const SectionDateRange: React.FC<SectionDateRangeProps> = ({
  availabilityStart = Date.now(),
  availabilityEnd = null,
  booked_dates = [],
}) => {
  const today = new Date();
  const startAvailability = new Date(availabilityStart);
  const minSelectableDate =
    startAvailability < today ? today : startAvailability;

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [disabledDates, setDisabledDates] = useState<Date[]>([]);

  useEffect(() => {
    setStartDate(minSelectableDate);
    setEndDate(availabilityEnd ? new Date(availabilityEnd) : null);
  }, [availabilityStart, availabilityEnd]);

  const getDisabledDates = (booked_dates: [string, string][]) => {
    const disabledDates: Date[] = [];
    booked_dates.forEach(([startDate, endDate]) => {
      if (startDate && endDate) {
        const dateRange = eachDayOfInterval({
          start: moment(startDate).toDate(),
          end: moment(endDate).toDate(),
        });
        disabledDates.push(...dateRange);
      }
    });
    return disabledDates;
  };

  useEffect(() => {
    setDisabledDates(getDisabledDates(booked_dates));
  }, [booked_dates]);

  const isBookedDate = (date: Date) => {
    return disabledDates.some(
      (disabledDate) =>
        date.getFullYear() === disabledDate.getFullYear() &&
        date.getMonth() === disabledDate.getMonth() &&
        date.getDate() === disabledDate.getDate()
    );
  };

  const onChangeDate = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  return (
    <div className="listingSection__wrap overflow-hidden">
      <div>
        <h2 className="text-2xl font-semibold">Availability</h2>
        <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
          Prices may increase on weekends or holidays
        </span>
      </div>
      <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

      <div>
        <DatePicker
          selected={startDate}
          onChange={onChangeDate}
          startDate={startDate}
          endDate={endDate}
          selectsRange
          minDate={minSelectableDate}
          maxDate={availabilityEnd ? new Date(availabilityEnd) : null}
          excludeDates={disabledDates}
          monthsShown={2}
          showPopperArrow={false}
          inline
          filterDate={(date) => !isBookedDate(date)}
          renderCustomHeader={(p) => <DatePickerCustomHeaderTwoMonth {...p} />}
          renderDayContents={(day, date) => (
            <DatePickerCustomDay
              dayOfMonth={day}
              date={date}
              booked_dates={booked_dates}
            />
          )}
        />
      </div>
    </div>
  );
};

export default SectionDateRange;
