import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  getBookings,
  getRequestBookings,
} from "../../../redux/reducers/Properties/PropertiesSlice";
import { AppDispatch, RootState } from "redux/store";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import StayCardV3 from "components/StayCard/StayCardV3";
import HeaderFilterV2 from "../../PageHome/HeaderFilterV2";
import SectionGridFeaturePlacesSkeleton from "shared/Skelton/SectionGirdSkelton";
import Pagination from "shared/Pagination/Pagination";
import ButtonPrimary from "shared/Button/ButtonPrimary";

interface BookingData {
  id: string;
  [key: string]: string | number | boolean;
}

const ManageBookings: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const [page, setPage] = useState(1);
  const [bookingData, setBookingData] = useState<BookingData[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [query, setQuery] = useState<string>("");
  const [isSearchLoading, setIsSearchLoading] = useState(false);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
  };

  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");
  const { metaData } = useSelector((state: RootState) => state.property);
  const { currentUser } = useSelector((state: RootState) => state.user);

  const fetchBookings = useCallback(
    async (pageNumber: number, searchQuery = "") => {
      if (!currentUser) return; // Ensure currentUser is defined
      setBookingData([]); // Clear data before fetching new results
      setIsLoading(true);
      try {
        const response = await dispatch(
          currentUser.is_host
            ? getRequestBookings({ page: pageNumber, query: searchQuery })
            : getBookings({ page: pageNumber, query: searchQuery })
        ).unwrap();
        setBookingData(response?.bookings || []);
      } catch (error) {
        console.error("Error fetching bookings:", error);
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch, currentUser?.is_host]
  );

  useEffect(() => {
    setPage(1);
    setBookingData([]);
    fetchBookings(1);
  }, [fetchBookings, id, currentUser]);

  const handlePageChange = (pageNumber: number) => {
    setPage(pageNumber);
    fetchBookings(pageNumber);
  };

  const handleSearch = async () => {
    setIsSearchLoading(true);
    try {
      fetchBookings(1, query);
    } catch (error) {
      console.error("Error while searching:", error);
    } finally {
      setIsSearchLoading(false);
    }
  };

  useEffect(() => {
    if (query === "") {
      fetchBookings(1);
    }
  }, [query]);

  if (isLoading) {
    return (
      <div className="p-10">
        <SectionGridFeaturePlacesSkeleton />
      </div>
    );
  }

  return (
    <div className="nc-PageHome relative overflow-hidden">
      <div className="container relative space-y-24 mb-24 lg:space-y-28 lg:mb-28">
        <div className="relative py-16">
          <BackgroundSection />
          <div className="nc-SectionGridFeaturePlaces relative">
            <HeaderFilterV2
              heading={
                currentUser?.is_host
                  ? "Manage Your Bookings"
                  : "Manage Your Reservations"
              }
              subHeading={
                currentUser?.is_host
                  ? "Easily view, update, or manage your booking details below."
                  : "View and manage your reservations below."
              }
            />
            <div className="mb-8">
              <div className="flex items-center">
                <input
                  type="text"
                  value={query}
                  onChange={handleSearchChange}
                  placeholder="Search By Property Name..."
                  className="p-3 w-full md:w-1/3 border rounded-lg"
                />
                <ButtonPrimary
                  onClick={handleSearch}
                  className={`ml-4 p-3 rounded-lg text-white ${
                    isSearchLoading || query === ""
                      ? "bg-orange-700 cursor-not-allowed bg-opacity-70"
                      : "bg-orange-600 hover:bg-orange-700"
                  }`}
                  disabled={isSearchLoading || query === ""}
                >
                  {isSearchLoading ? "Searching..." : "Search"}
                </ButtonPrimary>
              </div>
            </div>
            {bookingData.length > 0 ? (
              <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-2">
                {bookingData.map((data) => (
                  <StayCardV3
                    key={data.id}
                    booking={data}
                    fetchBookings={fetchBookings}
                  />
                ))}
              </div>
            ) : (
              <div className="text-center py-10">
                <h3 className="text-2xl font-semibold">No bookings found</h3>
                <p className="text-gray-500 mt-2">
                  There are currently no bookings to display.
                </p>
              </div>
            )}
          </div>
          {metaData?.total_pages > 1 && (
            <div className="flex mt-16 justify-center items-center space-x-4">
              <Pagination
                currentPage={page}
                totalPages={metaData?.total_pages || 1}
                onPageChange={handlePageChange}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ManageBookings;
