import { MapPinIcon } from "@heroicons/react/24/solid";
import { FC, useCallback, useEffect, useState } from "react";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Input from "shared/Input/Input";
import FormItem from "./FormItem";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AppDispatch, RootState } from "redux/store";
import { useFormik } from "formik";
import {
  createProperties,
  getallupdateProperties,
  setId,
  updateProperties,
} from "../../redux/reducers/Properties/PropertiesSlice";
import successHandler from "utils/helpers/SuccessHandler";
import { locationValidationSchema } from "utils/formSchema";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useSelector } from "react-redux";
import __countryListing from "../../data/jsons/__countryListing.json";
import CommonLayoutV2 from "./CommonLayoutV2";
import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow,
  Circle,
} from "@react-google-maps/api";
import { toast } from "react-toastify";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import "../../components/HeroSearchForm/LocationInput.scss";

export interface Location {}

interface FormValues {
  title: string;
  country: string;
  street: string;
  city: string;
  state: string;
  zipcode: string | number;
  latitude: number | null;
  longitude: number | null;
}

const Location: FC<Location> = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [infoOpen, setInfoOpen] = useState(false);
  const { globalId } = useSelector((state: RootState) => state.property);
  const { slug } = useParams();
  const id = slug;
  const RouteID = globalId || id;
  const [loading, setLoading] = useState<boolean>(false);
  const [address, setAddress] = useState<{
    street: string;
    city: string;
    state: string;
    zipcode: string;
    country: string;
  }>({ street: "", city: "", state: "", zipcode: "", country: "" });
  const [propertyToEdit, setPropertyToEdit] = useState<any>(null);
  const [userLocation, setUserLocation] = useState<{
    lat: number;
    lng: number;
  }>({ lat: 38.9072, lng: -77.0369 });
  const [isEditing, setIsEditing] = useState(false);
  const [updateLocation, setUpdateLocation] = useState(false);
  const [googleAddress, setGoogleAddress] = useState("");
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY || "",
    libraries: ["places"],
  });

  useEffect(() => {
    const fetchProperty = async () => {
      const propertyId = id;
      if (propertyId) {
        const action = await dispatch(getallupdateProperties({ propertyId }));
        if (action?.payload?.success) {
          const { latitude, longitude, address } = action?.payload?.property;
          setUserLocation({ lat: Number(latitude), lng: Number(longitude) });
          setGoogleAddress(address);
          setPropertyToEdit(action?.payload?.property);
        }
      }
    };
    fetchProperty();
  }, [dispatch, globalId, id]);

  const initialValues = {
    title: propertyToEdit?.title || "",
    country: address?.country || propertyToEdit?.country || "",
    street: address?.street || propertyToEdit?.address || "",
    city: address?.city || propertyToEdit?.city || "",
    state: address?.state || propertyToEdit?.state || "",
    zipcode: address?.zipcode || propertyToEdit?.zipcode || "",
    latitude: userLocation?.lat || propertyToEdit?.latitude || null,
    longitude: userLocation?.lng || propertyToEdit?.longitude || null,
  };

  const formik = useFormik<FormValues>({
    initialValues: initialValues,
    validationSchema: locationValidationSchema,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      const payload = {
        address: `${values.street}`,
        city: values.city,
        state: values.state,
        country: values.country,
        zipcode: values.zipcode,
        latitude: values.latitude,
        longitude: values.longitude,
        ...(id ? { id: id } : {}),
      };

      setSubmitting(true);
      if (id) {
        if (isEditing) {
          dispatch(updateProperties({ propertyId: id, property: payload }))
            .unwrap()
            .then((response) => {
              if (response?.success) {
                successHandler(response?.message || "Updated successfully");
                navigate(`/add-listing-6/${slug}`);
                resetForm();
              } else {
                console.log("Update failed: ", response);
              }
            })
            .catch((error) => {
              console.log("Error: ", error);
            })
            .finally(() => {
              setSubmitting(false);
            });
        } else {
          navigate(`/add-listing-6/${slug}`);
        }
      } else {
        dispatch(createProperties({ property: payload }))
          .unwrap()
          .then((response) => {
            if (response?.success) {
              successHandler(response?.message || "Created successfully");
              dispatch(setId(response.property.id));
              navigate(`/add-listing-6?id=${response.property.id}`);
              resetForm();
            } else {
              console.log("Creation failed: ", response);
            }
          })
          .catch((error) => {
            console.log("Error: ", error);
          })
          .finally(() => {
            setSubmitting(false);
          });
      }
    },
  });

  const initialFormValues = JSON.stringify(initialValues);

  useEffect(() => {
    const currentFormValues = JSON.stringify(formik.values);
    if (updateLocation) {
      setIsEditing(true);
    } else {
      setIsEditing(currentFormValues !== initialFormValues);
    }
  }, [formik.values, initialFormValues, updateLocation]);

  const fetchAddress = useCallback(
    async (latitude: number, longitude: number) => {
      const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_GOOGLE_MAP_KEY}`;
      try {
        const response = await fetch(geocodeUrl);
        const data = await response.json();
        if (data.status === "OK" && data.results.length > 0) {
          const addressComponents = data.results[0].address_components;
          const formattedAddress = data.results[0].formatted_address;

          let city = "";
          let state = "";
          let zipCode = "";
          let country = "";

          addressComponents.forEach((component: any) => {
            if (component.types.includes("locality")) {
              city = component.long_name;
            }
            if (component.types.includes("administrative_area_level_1")) {
              state = component.long_name;
            }
            if (component.types.includes("postal_code")) {
              zipCode = component.long_name;
            }
            if (component.types.includes("country")) {
              country = component.long_name;
            }
          });

          setAddress({
            street: formattedAddress || `${city}, ${state}, ${country}`,
            city: city || "N/A",
            state: state || "N/A",
            zipcode: zipCode || "N/A",
            country: country || "N/A",
          });
          setGoogleAddress(formattedAddress || `${city}, ${state}, ${country}`);
        } else {
          toast.info("No Address found", {
            position: "top-right",
            autoClose: 5000,
          });
        }
      } catch (error) {
        console.error("Error fetching geocode data:", error);
      }
    },
    [userLocation.lat, userLocation.lng]
  );

  const getUserLocation = () => {
    if (navigator.geolocation) {
      setLoading(true);
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          setUpdateLocation(true);
          setUserLocation({ lat: latitude, lng: longitude });
          await fetchAddress(latitude, longitude);
          setLoading(false);
        },

        (error) => {
          console.error("Error get user location: ", error);
          setLoading(false);
        }
      );
    } else {
      setLoading(false);
      console.log("Geolocation is not supported by this browser");
    }
  };

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return (
      <div className="flex items-center justify-center py-10">
        <div className="flex items-center justify-center space-x-2">
          <div className="w-10 h-10 border-4 border-t-4 border-gray-300 border-t-primary-6000 rounded-full animate-spin"></div>
        </div>
      </div>
    );
  }

  const mapContainerStyle = {
    width: "100%",
    height: "100%",
  };

  const handlePlaceSelect = async (place: any) => {
    try {
      if (!place || !place.value || !place.value.place_id) return;
      const placeId = place.value.place_id;
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?place_id=${placeId}&key=${process.env.REACT_APP_GOOGLE_MAP_KEY}`
      );
      if (!response.ok) {
        throw new Error(`API error: ${response.status} ${response.statusText}`);
      }
      const geocodeData = await response.json();
      if (geocodeData.status !== "OK") {
        throw new Error(`Google API Error: ${geocodeData.status}`);
      }
      if (geocodeData.results?.length > 0) {
        const { lat, lng } = geocodeData.results[0].geometry.location;
        setUserLocation({ lat: lat, lng: lng });
        const addressComponents = geocodeData.results[0].address_components;
        const formattedAddress = geocodeData.results[0].formatted_address;

        let city = "";
        let state = "";
        let zipCode = "";
        let country = "";

        addressComponents.forEach((component: any) => {
          if (component.types.includes("locality")) {
            city = component.long_name;
          }
          if (component.types.includes("administrative_area_level_1")) {
            state = component.long_name;
          }
          if (
            component.types.includes("postal_code") ||
            component.types.includes("postal_code_prefix")
          ) {
            zipCode = component.long_name;
          }
          if (component.types.includes("country")) {
            country = component.long_name;
          }
        });

        setAddress({
          street: formattedAddress || `${city}, ${state}, ${country}`,
          city: city || "N/A",
          state: state || "N/A",
          zipcode: zipCode || "N/A",
          country: country || "N/A",
        });
      }
    } catch (error) {
      console.error("Geocoding error:", error);
    }
  };

  return (
    <CommonLayoutV2 currentHref="/add-listing-5" PropertyID={RouteID}>
      <>
        <h2 className="text-2xl font-semibold">Listing Location</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        {/* FORM */}
        <form onSubmit={formik.handleSubmit}>
          <div className="space-y-8">
            <ButtonSecondary type="button" onClick={getUserLocation}>
              {loading ? (
                "fetching ..."
              ) : (
                <>
                  <MapPinIcon className="w-5 h-5 text-neutral-500 dark:text-neutral-400" />
                  <span className="ml-3">Use current location</span>
                </>
              )}
            </ButtonSecondary>
            <FormItem label="Address">
              <div className="border border-neutral-200 rounded-2xl">
                <GooglePlacesAutocomplete
                  apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY || ""}
                  selectProps={{
                    inputValue: googleAddress,
                    onInputChange: (newValue, { action }) => {
                      if (action !== "input-blur" && action !== "menu-close") {
                        setGoogleAddress(newValue);
                      }
                    },
                    placeholder: "Search for places...",
                    onChange: (place: any) => {
                      if (place && place.value && place.value.place_id) {
                        handlePlaceSelect(place);
                        setUpdateLocation(true);
                      }
                    },
                    className: "text-sm",
                    isClearable: true,
                    styles: {
                      control: (provided) => ({
                        ...provided,
                        border: "none",
                        boxShadow: "none",
                        background: "transparent",
                      }),
                      input: (provided) => ({
                        ...provided,
                        color: "inherit",
                        ".dark &": { color: "white" },
                      }),
                      placeholder: (provided) => ({
                        ...provided,
                        color: "black",
                        ".dark &": {
                          color: "white",
                        },
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isFocused
                          ? "#E5E7EB"
                          : "transparent",
                        color: "inherit",
                        ".dark &": {
                          backgroundColor: state.isFocused
                            ? "rgba(255, 255, 255, 0.2)"
                            : "transparent",
                        },
                      }),
                      menu: (provided) => ({
                        ...provided,
                        backgroundColor: "var(--bg-color, #ffffff)",
                        marginTop: "0",
                        marginLeft: "6px",
                        ".dark &": {
                          backgroundColor:
                            "rgba(var(--c-neutral-800), var(--tw-bg-opacity, 1))",
                          color: "#ffffff",
                        },
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: "inherit",
                        ".dark &": { color: "white" },
                      }),
                    },
                  }}
                />
              </div>
              {formik.touched.street && formik.errors.street ? (
                <div className="text-red-600">{formik.errors.street}</div>
              ) : null}
            </FormItem>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8 md:gap-5">
              <FormItem label="City">
                <Input
                  name="city"
                  value={formik.values.city}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.city && formik.errors.city ? (
                  <div className="text-red-600">{formik.errors.city}</div>
                ) : null}
              </FormItem>

              <FormItem label="State">
                <Input
                  name="state"
                  value={formik.values.state}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.state && formik.errors.state ? (
                  <div className="text-red-600">{formik.errors.state}</div>
                ) : null}
              </FormItem>

              <FormItem label="Zipcode">
                <Input
                  name="zipcode"
                  value={formik.values.zipcode}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.zipcode && formik.errors.zipcode ? (
                  <div className="text-red-600">{formik.errors.zipcode}</div>
                ) : null}
              </FormItem>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-8 md:gap-5">
              <FormItem label="Latitude">
                <Input
                  name="latitude"
                  value={formik.values.latitude || ""}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.latitude && formik.errors.latitude ? (
                  <div className="text-red-600">{formik.errors.latitude}</div>
                ) : null}
              </FormItem>

              <FormItem label="Longitude">
                <Input
                  name="longitude"
                  value={formik.values.longitude || ""}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.longitude && formik.errors.longitude ? (
                  <div className="text-red-600">{formik.errors.longitude}</div>
                ) : null}
              </FormItem>
            </div>

            <div className="aspect-w-5 aspect-h-5 sm:aspect-h-3 ring-1 ring-black/10 rounded-xl z-0">
              <GoogleMap
                mapContainerStyle={mapContainerStyle}
                zoom={13}
                center={userLocation}
                onClick={async (event) => {
                  const newPos = {
                    lat: event.latLng?.lat() ?? 0,
                    lng: event.latLng?.lng() ?? 0,
                  };
                  setUserLocation(newPos);
                  await fetchAddress(newPos.lat, newPos.lng);
                  setInfoOpen(true);
                  setUpdateLocation(true);
                }}
                options={{
                  maxZoom: 15,
                  minZoom: 12,
                  zoomControl: true,
                  mapTypeControl: false,
                  streetViewControl: false,
                  fullscreenControl: false,
                  disableDefaultUI: true,
                  keyboardShortcuts: false,
                  styles: [
                    {
                      featureType: "all",
                      elementType: "labels",
                      stylers: [{ visibility: "off" }],
                    },
                    {
                      featureType: "road",
                      elementType: "labels",
                      stylers: [
                        { visibility: "simplified" },
                        { lightness: 40 },
                      ],
                    },
                    {
                      featureType: "road.arterial",
                      elementType: "labels",
                      stylers: [{ visibility: "on" }],
                    },
                    {
                      featureType: "road.highway",
                      elementType: "labels",
                      stylers: [{ visibility: "on" }, { weight: 1.5 }],
                    },
                    {
                      featureType: "road.highway",
                      elementType: "geometry",
                      stylers: [
                        { visibility: "simplified" },
                        { lightness: 20 },
                      ],
                    },
                    {
                      featureType: "road.arterial",
                      elementType: "geometry",
                      stylers: [
                        { visibility: "simplified" },
                        { lightness: 40 },
                      ],
                    },
                    {
                      featureType: "road.local",
                      elementType: "geometry",
                      stylers: [{ visibility: "off" }],
                    },
                    {
                      featureType: "poi.business",
                      elementType: "labels",
                      stylers: [{ visibility: "simplified" }],
                    },
                    {
                      featureType: "poi.government",
                      elementType: "labels",
                      stylers: [{ visibility: "on" }],
                    },
                    {
                      featureType: "poi.place_of_worship",
                      elementType: "labels",
                      stylers: [{ visibility: "off" }],
                    },
                    {
                      featureType: "poi.medical",
                      elementType: "labels",
                      stylers: [{ visibility: "simplified" }],
                    },
                    {
                      featureType: "poi.school",
                      elementType: "labels",
                      stylers: [{ visibility: "simplified" }],
                    },
                    {
                      featureType: "landscape",
                      elementType: "geometry",
                      stylers: [{ lightness: 60 }],
                    },
                    {
                      featureType: "transit",
                      elementType: "geometry",
                      stylers: [{ visibility: "off" }],
                    },
                  ],
                }}
              >
                <Marker
                  position={userLocation}
                  onClick={() => setInfoOpen(true)}
                >
                  {infoOpen && (
                    <InfoWindow
                      position={userLocation}
                      onCloseClick={() => setInfoOpen(false)}
                    >
                      <div className="w-72 bg-white rounded-lg  overflow-hidden relative font-sans">
                        <div className="p-3">
                          <h3 className="text-lg font-bold flex items-center">
                            <p className="text-sm text-gray-600">
                              {googleAddress}
                            </p>
                          </h3>
                        </div>
                      </div>
                    </InfoWindow>
                  )}
                </Marker>
                <Circle
                  center={userLocation}
                  radius={500}
                  options={{
                    fillColor: "#FF0000",
                    fillOpacity: 0.2,
                    strokeColor: "#FF0000",
                    strokeOpacity: 0.5,
                    strokeWeight: 2,
                  }}
                />
              </GoogleMap>
            </div>

            <div className="flex justify-end space-x-5">
              <ButtonSecondary href={`/add-listing-4/${slug}`}>
                Go back
              </ButtonSecondary>
              <ButtonPrimary type="submit" disabled={formik.isSubmitting}>
                {formik.isSubmitting ? "Loading..." : "Continue"}
              </ButtonPrimary>
            </div>
          </div>
        </form>
      </>
    </CommonLayoutV2>
  );
};

export default Location;
