import { FC } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";
import { DEMO_STAY_LISTINGS } from "data/listings";
import { StayDataType } from "data/types";
import { Link } from "react-router-dom";
import { currencySymbol } from "shared/CurrencySymbol";
import moment from "moment";
import Badge from "shared/Badge/Badge";

export interface StayCardV2Props {
  className?: string;
  data?: StayDataType;
  size?: "default" | "small";
}

const DATA = DEMO_STAY_LISTINGS[0];

const StayCardV2: FC<StayCardV2Props> = ({
  size = "default",
  className = "",
  data = DATA,
}) => {
  const {
    place_images,
    address,
    title,
    href,
    price,
    id,
    state,
    check_in_date,
    check_out_date,
    guest_count,
    PropertyStatus,
    RoomName,
    RequestBy,
  } = data;

  const modifiedPropertyStatus = (
    PropertyStatus: string | undefined | boolean
  ) => {
    const status =
      typeof PropertyStatus === "boolean"
        ? PropertyStatus.toString()
        : PropertyStatus;
    switch (status) {
      case "true":
        return "Shared Property";
      case "false":
        return "Entire Property";
      default:
        return "";
    }
  };

  const modifiedStatus = (status: string | undefined) => {
    switch (state) {
      case "send_message":
        return "Send message";
      case "booking_invitation":
        return "Booking invitation";
      case "rejected":
        return "Rejected";
      case "request_change":
        return "Request change";

      default:
        return "";
    }
  };

  const renderSliderGallery = () => {
    return (
      <div className="relative w-full">
        <GallerySlider
          uniqueID={`StayCard_${id}`}
          ratioClass="aspect-w-4 aspect-h-3 "
          galleryImgs={place_images}
          href={href}
        />
        {/* <BtnLikeIcon isLiked={like} className="absolute right-3 top-3 z-[1]" /> */}
        {/* {saleOff && <SaleOffBadge className="absolute left-3 top-3" />} */}
        <div className="absolute left-3 top-3 z-[1] bg-black/50 pl-5 pr-5 rounded-md text-white dark:text-white">
          {modifiedStatus(state)}
        </div>
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className={size === "default" ? "p-4 space-y-4" : "p-3 space-y-2"}>
        <div className="space-y-2">
          <div className="flex items-center justify-between">
            <Badge
              name={modifiedPropertyStatus(PropertyStatus) || "Property"}
            />
            {(PropertyStatus === true || PropertyStatus === "true") && (
              <Badge name={RoomName || "N/A"} />
            )}
          </div>
          <div className="flex items-center space-x-2">
            {/* {isAds && <Badge name="ADS" color="green" />} */}
            <h2
              className={` font-medium capitalize ${
                size === "default" ? "text-lg" : "text-base"
              }`}
            >
              <span className="line-clamp-1">{title}</span>
            </h2>
          </div>
          <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
            {size === "default" && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
            )}
            <span className="">{address}</span>
          </div>
        </div>
        <div className="w-14 border-b border-neutral-100 dark:border-neutral-800"></div>
        <div className="flex justify-between items-center">
          <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
            Request by
          </span>
          {size === "default" && (
            <span className="text-base font-semibold">{RequestBy}</span>
          )}
        </div>
        <div className="flex justify-between items-center">
          <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
            Period :
          </span>
          {size === "default" && (
            <span className="text-base font-semibold">
              {moment(check_in_date).format("MMM DD, YYYY")} to{" "}
              {moment(check_out_date).format("MMM DD, YYYY")}
            </span>
          )}
        </div>
        <div className="flex justify-between items-center">
          <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
            Guest :
          </span>
          {size === "default" && (
            <span className="text-base font-semibold">{guest_count}</span>
          )}
        </div>
        <div className="flex justify-between items-center">
          <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
            Amount :
          </span>
          <span className="text-base font-semibold">
            {`${currencySymbol()} ${Number(price)?.toFixed(2) || 0}`}
          </span>
          {/* {!!reviewStart && (
            <StartRating reviewCount={reviewCount} point={reviewStart} />
          )} */}
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-StayCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow ${className}`}
      data-nc-id="StayCard"
    >
      {renderSliderGallery()}
      <Link to={href}>{renderContent()}</Link>
    </div>
  );
};

export default StayCardV2;
