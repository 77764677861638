import React, { FC, ReactNode, useState } from "react";
import { StayDataType } from "data/types";
import HeaderFilterV2 from "./HeaderFilterV2";
import StayCardV2 from "components/StayCard/StayCardV2";
import ButtonPrimary from "shared/Button/ButtonPrimary";

export interface SectionGridFeaturePlacesV2Props {
  stayListings?: StayDataType[];
  gridClass?: string;
  heading?: ReactNode;
  subHeading?: ReactNode;
  headingIsCenter?: boolean;
  tabs?: string[];
  onSearch?: (page: number, query: string) => void;
}

const SectionGridFeaturePlacesV2: FC<SectionGridFeaturePlacesV2Props> = ({
  stayListings = [],
  gridClass = "",
  heading = "Booking Query",
  subHeading = "",
  onSearch,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [isSearchLoading, setIsSearchLoading] = useState(false);

  const renderCard = (stay: StayDataType) => {
    return <StayCardV2 key={stay.id} data={stay} />;
  };

  return (
    <div className="nc-SectionGridFeaturePlaces relative">
      <HeaderFilterV2 subHeading={subHeading} heading={heading} />
      <div className="flex mb-5">
        <input
          type="text"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder="Search By Property Name..."
          className="p-3 w-full md:w-1/3 border rounded-lg"
        />
        <ButtonPrimary
          onClick={() => {
            if (!onSearch) return;
            setIsSearchLoading(true);
            onSearch(1, searchQuery);
            setTimeout(() => setIsSearchLoading(false), 1000);
          }}
          className={`ml-2 px-4 py-2 rounded-lg text-white ${
            isSearchLoading || searchQuery.trim() === ""
              ? "bg-orange-700 cursor-not-allowed bg-opacity-70"
              : "bg-orange-600 hover:bg-orange-700"
          }`}
          disabled={isSearchLoading || searchQuery.trim() === ""}
        >
          {isSearchLoading ? "Searching..." : "Search"}
        </ButtonPrimary>
      </div>
      {stayListings.length > 0 ? (
        <div
          className={`grid gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 ${gridClass}`}
        >
          {stayListings?.map((stay) => renderCard(stay))}
        </div>
      ) : (
        <div className="text-center py-10">
          <h2 className="text-2xl font-bold text-neutral-900 dark:text-neutral-100">
            No Booking Query Found
          </h2>
        </div>
      )}
    </div>
  );
};

export default SectionGridFeaturePlacesV2;
