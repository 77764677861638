import { FC, useState } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";
import { StayDataType } from "data/types";
import { Link } from "react-router-dom";
import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
import moment from "moment";
import { currencySymbol } from "shared/CurrencySymbol";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useSelector } from "react-redux";
import { AppDispatch, RootState } from "redux/store";
import successHandler from "utils/helpers/SuccessHandler";
import { useDispatch } from "react-redux";
import Invoice from "../../../src/containers/Booking/Invoice";
import InvoiceUser from "../../../src/containers/Booking/InvoiceUser";
import {
  setReserverBooking,
  updateBookingsDetails,
} from "../../redux/reducers/Payments/PaymentReducer";
import { deleteInvoice } from "../../redux/reducers/Properties/PropertiesSlice";
import Badge from "shared/Badge/Badge";

export interface StayCardProps {
  className?: string;
  data?: StayDataType;
  size?: "default" | "small";
  booking?: any;
  fetchBookings?: any;
}

const StayCardV3: FC<StayCardProps> = ({
  size = "default",
  className = "",
  booking,
  fetchBookings,
}) => {
  const { property } = booking;
  const { currentUser } = useSelector((state: RootState) => state.user);
  const {
    place_images: placeImagesTemp = property?.place_images,
    title = property?.title,
    href = `/booking_request?id=${booking?.id}`,
    price = booking?.total_amount,
    id = property?.id,
    check_in_date = booking?.check_in_date,
    check_out_date = booking?.check_out_date,
    status = booking?.status,
    guest = booking?.number_of_guests,
    request_by = booking?.request_by,
    invoice = booking?.invoice_no,
    PropertyStatus = booking?.is_shared_property,
    Room = booking?.booked_room,
  } = property;

  const place_images = placeImagesTemp;
  const sortAddress = `${property?.city}, ${property?.state}`;
  const dispatch = useDispatch<AppDispatch>();
  const [showInvoice, setShowInvoice] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const openInvoice = () => setShowInvoice(true);
  const closeInvoice = () => setShowInvoice(false);
  const [showUserInvoice, setShowUserInvoice] = useState(false);

  const modifiedPropertyStatus = (
    PropertyStatus: string | undefined | boolean
  ) => {
    const status =
      typeof PropertyStatus === "boolean"
        ? PropertyStatus.toString()
        : PropertyStatus;
    switch (status) {
      case "true":
        return "Shared Property";
      case "false":
        return "Entire Property";
      default:
        return "";
    }
  };

  const modifiedStatus = (status: string | undefined) => {
    switch (status) {
      case "invoice_sent":
        return "Invoice sent";
      case "booking_request":
        return "Booking request";
      case "canceled":
        return "Canceled";
      default:
        return "";
    }
  };

  const issueInvoiceHandler = () => {
    dispatch(setReserverBooking(booking));
    setShowUserInvoice((prev) => !prev);
  };

  const handleDeleteInvoice = async () => {
    if (id == null) {
      return;
    }
    setIsDeleting(true);
    const postData = {
      bookingId: booking?.id,
      id: booking?.invoice_no,
      _destroy: true,
      status: "canceled",
    };

    try {
      const action = await dispatch(deleteInvoice(postData));
      if (deleteInvoice.fulfilled.match(action)) {
        successHandler("Invoice deleted successfully:", action.payload);
        await fetchBookings();
      } else {
        console.error("Failed to delete invoice:", action.payload);
      }
    } catch (error) {
      console.error("Error deleting invoice:", error);
    } finally {
      setIsDeleting(false);
    }
  };

  const handleCancelBooking = async () => {
    if (id == null) {
      return;
    }

    const actionData = {
      id: booking?.id,
      status: "canceled",
    };
    try {
      const action = await dispatch(updateBookingsDetails(actionData));
      if (updateBookingsDetails.fulfilled.match(action)) {
        successHandler("Booking canceled successfully:", action.payload);
        await fetchBookings();
      } else {
        console.error("Failed to cancel booking:", action.payload);
      }
    } catch (error) {
      console.error("Error canceling booking:", error);
    }
  };

  const renderSliderGallery = () => {
    return (
      <div className="relative w-full">
        <GallerySlider
          uniqueID={`StayCard_${id}`}
          ratioClass="aspect-w-4 aspect-h-3"
          galleryImgs={place_images || []} // Ensure it's not undefined
          href={href}
        />

        {/* Status badge */}
        <div className="absolute left-3 top-3 z-[1] bg-black/50 px-5 rounded-md text-white dark:text-white">
          {modifiedStatus(status)}
        </div>
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className={size === "default" ? "p-4 space-y-4" : "p-3 space-y-2"}>
        <div className="space-y-2">
          <div className="flex items-center justify-between">
            <Badge
              name={modifiedPropertyStatus(PropertyStatus) || "Property"}
            />
            {(PropertyStatus === true || PropertyStatus === "true") && (
              <Badge name={Room || "N/A"} />
            )}
          </div>
          <div className="flex items-center space-x-2">
            <h2
              className={`font-medium capitalize ${
                size === "default" ? "text-lg" : "text-base"
              }`}
            >
              <span className="line-clamp-1">{title}</span>
            </h2>
          </div>
          <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
            {size === "default" && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
            )}
            <span className="">{sortAddress || "No address found"}</span>
          </div>
        </div>
        <div className="w-14 border-b border-neutral-100 dark:border-neutral-800"></div>
        <div className="flex justify-between items-center">
          <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
            Request by
          </span>
          {size === "default" && (
            <span className="text-base font-semibold">{request_by}</span>
          )}
        </div>
        <div className="flex justify-between items-center">
          <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
            Period :
          </span>
          {size === "default" && (
            <span className="text-base font-semibold">
              {moment(check_in_date).format("MMM DD, YYYY")} to{" "}
              {moment(check_out_date).format("MMM DD, YYYY")}
            </span>
          )}
        </div>
        <div className="flex justify-between items-center">
          <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
            Invoice No :
          </span>
          {size === "default" && (
            <span className="text-base font-semibold">{invoice || "N/A"}</span>
          )}
        </div>
        <div className="flex justify-between items-center">
          <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
            Guest
          </span>
          {size === "default" && (
            <span className="text-base font-semibold">{guest}</span>
          )}
        </div>
        <div className="flex justify-between items-center">
          <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
            Amount:
          </span>
          <span className="text-base font-semibold">
            {`${currencySymbol()} ${Number(price)?.toFixed(2) || 0}`}
          </span>
          {/* {!!reviewStart && <StartRating reviewCount={reviewCount} point={reviewStart} />} */}
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-StayCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow ${className}`}
      data-nc-id="StayCard"
    >
      {renderSliderGallery()}
      <Link to={href}> {renderContent()}</Link>
      <div className=" pl-3 pb-3 pr-3 rounded-lg">
        <div className="flex flex-col sm:flex-row gap-4">
          {booking?.status === "confirmed" ? (
            <>
              <ButtonPrimary
                className="bg-green-500 text-white px-6 py-3 rounded-lg cursor-not-allowed"
                disabled
              >
                Booking Confirmed
              </ButtonPrimary>

              {currentUser?.is_student && booking?.property?.slug && (
                <ButtonPrimary
                  href={`/listing-stay-detail/${booking.property.slug}`}
                  className="bg-orange-500 text-white px-6 py-3 rounded-lg"
                >
                  Visit Property
                </ButtonPrimary>
              )}
            </>
          ) : currentUser?.is_host ? (
            <>
              {booking?.status !== "canceled" &&
                (booking?.status === "invoice_sent" ? (
                  <>
                    <ButtonPrimary
                      className="bg-gray-500 text-white px-6 py-3 rounded-lg cursor-not-allowed"
                      disabled
                    >
                      Invoice Issued
                    </ButtonPrimary>

                    {booking?.invoice_no != null && (
                      <ButtonPrimary
                        onClick={handleDeleteInvoice}
                        disabled={isDeleting}
                        className={`bg-red-500 text-white px-6 py-3 rounded-lg ${
                          isDeleting
                            ? "opacity-50 cursor-not-allowed"
                            : "hover:bg-red-600"
                        }`}
                      >
                        {isDeleting ? "Deleting..." : "Delete Invoice"}
                      </ButtonPrimary>
                    )}
                  </>
                ) : (
                  <ButtonPrimary
                    className="bg-orange-500 text-white px-6 py-3 rounded-lg"
                    onClick={() => setShowInvoice((prev) => !prev)}
                  >
                    {showInvoice ? "Close Invoice" : "Issue Invoice"}
                  </ButtonPrimary>
                ))}

              <ButtonPrimary
                className="bg-orange-500 text-white px-6 py-3 rounded-lg"
                onClick={handleCancelBooking}
                disabled={booking?.status === "canceled"}
              >
                Reject Booking Request
              </ButtonPrimary>
            </>
          ) : (
            <>
              {booking?.status === "canceled" ? (
                <button
                  className="bg-gray-300 dark:bg-gray-600 px-6 py-3 rounded-lg text-gray-700 dark:text-gray-300 cursor-not-allowed"
                  disabled
                >
                  Request Pending
                </button>
              ) : booking?.status === "booking_request" ? (
                <button
                  className="bg-gray-300 dark:bg-gray-600 px-6 py-3 rounded-lg text-gray-700 dark:text-gray-300 cursor-not-allowed"
                  disabled
                >
                  Request Pending
                </button>
              ) : booking?.status === "invoice_sent" ? (
                <button
                  className="bg-green-500 text-white px-6 py-3 rounded-lg"
                  onClick={issueInvoiceHandler}
                >
                  {showUserInvoice
                    ? "Hide Invoice"
                    : "Received Invoice - Check & Pay"}
                </button>
              ) : null}

              <ButtonPrimary
                className="bg-orange-500 text-white px-6 py-3 rounded-lg"
                onClick={handleCancelBooking}
                disabled={booking?.status === "canceled"}
              >
                Cancel Booking Request
              </ButtonPrimary>

              {booking?.status !== "canceled" && (
                <ButtonPrimary
                  href={`/listing-stay-detail/${booking?.property?.slug}`}
                  className="bg-orange-500 text-white px-6 py-3 rounded-lg"
                >
                  Visit Property
                </ButtonPrimary>
              )}
            </>
          )}
        </div>

        {showUserInvoice && <InvoiceUser bookingRequestDetails={booking} />}
        {showInvoice && (
          <Invoice
            bookingRequestDetails={booking}
            closeInvoice={closeInvoice}
            callback={fetchBookings}
          />
        )}
      </div>
    </div>
  );
};

export default StayCardV3;
