import React, { FC } from "react";
import twFocusClass from "utils/twFocusClass";

export interface PaginationProps {
  className?: string;
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

const Pagination: FC<PaginationProps> = ({
  className = "",
  currentPage,
  totalPages,
  onPageChange,
}) => {
  const renderItem = (page: number) => {
    const isActive = page === currentPage;

    return isActive ? (
      <span
        key={page}
        className={`inline-flex w-11 h-11 items-center justify-center rounded-full bg-primary-6000 text-white ${twFocusClass()}`}
      >
        {page}
      </span>
    ) : (
      <button
        key={page}
        className={`inline-flex w-11 h-11 items-center justify-center rounded-full bg-white hover:bg-neutral-100 border border-neutral-200 text-neutral-6000 dark:text-neutral-400 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:border-neutral-700 ${twFocusClass()}`}
        onClick={() => onPageChange(page)}
      >
        {page}
      </button>
    );
  };

  return (
    <nav
      className={`nc-Pagination flex space-x-1 text-base font-medium ${className}`}
    >
      {currentPage > 1 && (
        <button
          className="inline-flex w-11 h-11 items-center justify-center rounded-full bg-white hover:bg-neutral-100 border border-neutral-200 text-neutral-6000 dark:text-neutral-400 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:border-neutral-700"
          onClick={() => onPageChange(currentPage - 1)}
        >
          ←
        </button>
      )}
      {[...Array(totalPages)].map((_, index) => renderItem(index + 1))}
      {currentPage < totalPages && (
        <button
          className="inline-flex w-11 h-11 items-center justify-center rounded-full bg-white hover:bg-neutral-100 border border-neutral-200 text-neutral-6000 dark:text-neutral-400 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:border-neutral-700"
          onClick={() => onPageChange(currentPage + 1)}
        >
          →
        </button>
      )}
    </nav>
  );
};

export default Pagination;
