import React from "react";
import { ReactNode } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";

export interface Heading2Props {
  heading?: ReactNode;
  subHeading?: ReactNode;
  className?: string;
  searchedData?: any;
}

const Heading2: React.FC<Heading2Props> = ({ className = "", subHeading }) => {
  const storedSearchData = useSelector(
    (state: RootState) => state.property.searchedData
  );
  const { startDate, endDate, totalGuests, searchedResult, locationData } =
    storedSearchData || {};
  const propertiesCount = searchedResult?.total_count;
  const city = locationData?.city;
  const heading = city ? `Stays in ${city}` : "";
  const formatDate = (date: Date | string | undefined) => {
    if (!date) return "";
    const options: Intl.DateTimeFormatOptions = {
      month: "short",
      day: "numeric",
    };
    return new Date(date).toLocaleDateString(undefined, options);
  };

  const formattedStartDate = formatDate(startDate);
  const formattedEndDate = formatDate(endDate);

  return (
    <div className={`mb-12 lg:mb-16 ${className}`}>
      <h2 className="text-4xl font-semibold">{heading}</h2>
      {subHeading
        ? subHeading
        : propertiesCount > 0 && (
            <span className="block text-neutral-500 dark:text-neutral-400 mt-3">
              {propertiesCount} stays
              <span className="mx-2">·</span>
              {formattedStartDate && formattedEndDate
                ? `${formattedStartDate} - ${formattedEndDate}`
                : ""}
              <span className="mx-2">·</span>
              {totalGuests
                ? `${totalGuests} ${totalGuests == 1 ? "Guest" : "Guests"}`
                : "Any"}
            </span>
          )}
    </div>
  );
};

export default Heading2;
