import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "redux/store";
import { DEMO_STAY_LISTINGS } from "data/listings";
import { StayDataType } from "data/types";
import {
  bookingQuery,
  getBookingsQuery,
} from "../../../redux/reducers/Properties/PropertiesSlice";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionGridFeaturePlacesV2 from "containers/PageHome/SectionGridFeaturePlacesV2";
import SectionGridFeaturePlacesSkeleton from "shared/Skelton/SectionGirdSkelton";
import Pagination from "shared/Pagination/Pagination";

const BookingQuery: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { currentUser } = useSelector((state: RootState) => state.user);
  const [allData, setAllData] = useState<any>([]);
  const [pagemetaData, setMetaData] = useState<any>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");

  const DEMO_DATA: StayDataType[] = DEMO_STAY_LISTINGS.filter((_, i) => i < 8);

  const getRandomIndex = () => Math.floor(Math.random() * 8);

  const mergedData = (dataToBeMerged: any[]) => {
    const newData = dataToBeMerged?.map((apiItem) => {
      const randomIndex = getRandomIndex();
      const fallback = DEMO_DATA[randomIndex];
      return {
        ...fallback,
        id: apiItem?.id,
        title: apiItem?.property?.title || fallback?.title,
        place_images: apiItem?.property?.place_images || fallback?.galleryImgs,
        address: `${apiItem?.property?.city}, ${apiItem?.property?.state}`,
        availability_start: apiItem.availability_start || fallback?.date,
        availability_end: apiItem.availability_end || fallback?.date,
        price: apiItem?.property?.price_per_month,
        href: `/booking_queries_details?id=${apiItem?.id}`,
        state: apiItem?.state,
        check_in_date: apiItem?.check_in_date,
        check_out_date: apiItem?.check_out_date,
        guest_count: apiItem?.guest_count,
        PropertyStatus : apiItem?.is_shared_property,
        RoomName : apiItem?.booked_room,
        RequestBy : apiItem?.request_by,
      };
    });
    setAllData(newData);
  };

  const fetchData = async (page: number, query: string) => {
    setIsLoading(true);
    try {
      const res = currentUser?.is_host
        ? await dispatch(getBookingsQuery({ page, query })).unwrap()
        : await dispatch(bookingQuery({ page, query })).unwrap();

      if (!res?.booking_queries || res?.booking_queries.length === 0) {
        setAllData([]);
        setMetaData(1);
      } else {
        mergedData(res.booking_queries);
        setMetaData(res.meta);
      }
    } catch (error: any) {
      setAllData([]);
      setMetaData(null);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (currentUser) {
      fetchData(currentPage, searchQuery);
    }
  }, [currentPage]);

  if (isLoading) {
    return (
      <div className="p-10">
        <SectionGridFeaturePlacesSkeleton />
      </div>
    );
  }

  return (
    <div className="nc-PageHome relative overflow-hidden">
      <div className="container relative space-y-24 mb-24 lg:space-y-28 lg:mb-28">
        <div className="relative py-16">
          <BackgroundSection />
          <SectionGridFeaturePlacesV2
            stayListings={allData}
            onSearch={(page, query) => {
              setSearchQuery(query);
              fetchData(page, query);
            }}
          />
        </div>

        {/* Pagination */}
        {pagemetaData?.total_pages > 1 && (
          <div className="flex mt-16 justify-center items-center">
            <Pagination
              currentPage={currentPage}
              totalPages={pagemetaData?.total_pages}
              onPageChange={setCurrentPage}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default BookingQuery;
