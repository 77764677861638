import React, { FC, useState } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";
import { DEMO_STAY_LISTINGS } from "data/listings";
import { StayDataType } from "data/types";
import StartRating from "components/StartRating/StartRating";
import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
import Badge from "shared/Badge/Badge";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { updatePropertiesListingStatus } from "../../redux/reducers/Properties/PropertiesSlice";
import { useDispatch } from "react-redux";
import { AppDispatch } from "redux/store";
import successHandler from "utils/helpers/SuccessHandler";
import { currencySymbol } from "shared/CurrencySymbol";

export interface StayCardV5Props {
  className?: string;
  data?: StayDataType;
  size?: "default" | "small";
  setAllData: React.Dispatch<React.SetStateAction<StayDataType[]>>;
  stayListings: StayDataType[];
}

const DATA = DEMO_STAY_LISTINGS[0];

const StayCardV5: FC<StayCardV5Props> = ({
  size = "default",
  className = "",
  data = DATA,
  setAllData,
  stayListings,
}) => {
  const {
    place_images,
    listingCategory,
    address,
    title,
    bedrooms,
    href,
    like,
    isAds,
    price,
    reviewStart,
    reviewCount,
    id,
    property_state,
    active,
    property_category,
    property_type,
    city,
    country,
    slug,
    reviewTotal,
  } = data;

  const dispatch = useDispatch<AppDispatch>();
  const [activeLoading, setActiveLoading] = useState(false);

  const handleStatusUpdate = async (status: boolean, id: any) => {
    setActiveLoading(true);
    const payload = {
      property: {
        active: !status,
        id: id,
      },
    };
    const action = await dispatch(updatePropertiesListingStatus(payload));
    if (action.payload.success) {
      const modifiedArr = stayListings?.map((item: any) =>
        item.id === id ? { ...item, active: !status } : item
      );
      setAllData(modifiedArr);
    }
    successHandler(
      active
        ? "The property has been paused!"
        : "The property has been activated!"
    );
    setActiveLoading(false);
  };

  const modifiedStatus = (status: string | undefined) => {
    switch (status) {
      case "waiting_for_approval":
        return "Waiting for approval";
      case "approved":
        return "Approved";
      default:
        return "";
    }
  };

  const renderSliderGallery = () => {
    return (
      <div className="relative w-full">
        <GallerySlider
          uniqueID={`StayCard_${id}`}
          ratioClass="aspect-w-4 aspect-h-3 "
          galleryImgs={place_images}
          href={href}
        />
        {/* <BtnLikeIcon isLiked={like} className="absolute right-3 top-3 z-[1]" /> */}
        {/* {saleOff && <SaleOffBadge className="absolute left-3 top-3" />} */}
        <div className="absolute left-3 top-3 z-[1] bg-black/50 pl-5 pr-5 rounded-md text-white dark:text-white">
          {modifiedStatus(property_state)}
        </div>
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className={size === "default" ? "p-4 space-y-4" : "p-3 space-y-2"}>
        <div className="space-y-2">
          <span className="text-sm text-neutral-500 dark:text-neutral-400">
            {listingCategory?.name} · {bedrooms} beds
          </span>
          <div className="flex items-center space-x-2">
            {isAds && <Badge name="ADS" color="green" />}
            <h2
              className={` font-medium capitalize ${
                size === "default" ? "text-lg" : "text-base"
              }`}
            >
              <span className="line-clamp-1">{title}</span>
            </h2>
          </div>
          <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
            {size === "default" && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
            )}
            <span className="">{address}</span>
          </div>
        </div>
        <div className="w-14 border-b border-neutral-100 dark:border-neutral-800"></div>
        <div className="flex justify-between items-center">
          <span className="text-base font-semibold">
            {`${currencySymbol()} ${Number(price)?.toFixed(2) || 0}`}
            {size === "default" && (
              <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
                /month
              </span>
            )}
          </span>
          {!!reviewStart && (
            <StartRating reviewCount={reviewCount} reviewTotal ={reviewTotal}/>
          )}
        </div>
        <div>
          <span className="text-gray-500 text-sm">
            Listed in{" "}
            <span className="text-orange-500 font-bold">{property_type}</span>{" "}
            and{" "}
            <span className="text-orange-500 font-bold">
              {property_category}
            </span>
          </span>
          <br />
          <span className="text-gray-500 text-sm">
            {city ? (
              <>
                City: <span className="text-orange-500 font-bold">{city}</span>,
                {/* Area: <span className="text-orange-500 font-bold">Area Name</span> */}
              </>
            ) : (
              <>
                Country:{" "}
                <span className="text-orange-500 font-bold">{country}</span>,
              </>
            )}
          </span>
        </div>

        <div className="flex items-center space-x-5 mt-8">
          <div title="Edit Listing">
            <ButtonSecondary href={`/add-listing-1/${slug}`}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                />
              </svg>
            </ButtonSecondary>
          </div>

          <div title="View Listing Details">
            <ButtonPrimary href={`/listing-stay-detail/${slug}`}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                />
              </svg>
            </ButtonPrimary>
          </div>

          <div title={active ? "Pause Listing" : "Resume Listing"}>
            <ButtonPrimary
              href=""
              loading={activeLoading}
              onClick={() =>
                active !== undefined && handleStatusUpdate(active, id)
              }
            >
              {active ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M10 4H6v16h4V4zm8 0h-4v16h4V4z"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M5 3l14 9-14 9V3z"
                  />
                </svg>
              )}
            </ButtonPrimary>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div
      className={`nc-StayCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow ${className}`}
      data-nc-id="StayCard"
    >
      {renderSliderGallery()}
      {renderContent()}
    </div>
  );
};

export default StayCardV5;
