import { StayDataType } from "data/types";
import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  postfavorites,
  removeFavorite,
  setProperties,
  setSearchData,
} from "../../redux/reducers/Properties/PropertiesSlice";
import { AppDispatch, RootState } from "redux/store";

export interface BtnLikeIconProps {
  className?: string;
  colorClass?: string;
  isLiked?: boolean;
  propertyId?: number;
  isFavorite?: boolean;
  stayListings?: any;
  favorites?: any;
  path?: string;
  data?: StayDataType;
  setFavorites?: React.Dispatch<React.SetStateAction<any[]>>;
  propertyData?: any;
  setPropertyData?: any;
}

const BtnLikeIcon: FC<BtnLikeIconProps> = ({
  className = "",
  colorClass = "text-white bg-black bg-opacity-30 hover:bg-opacity-50",
  propertyId,
  isLiked,
  stayListings,
  setPropertyData,
  path,
  favorites,
  propertyData,
  setFavorites,
}) => {
  const storedSearchData = useSelector(
    (state: RootState) => state.property.searchedData
  );
  const dispatch = useDispatch<AppDispatch>();
  const likedState = favorites
    ?.map((item: any) => item.id)
    ?.includes(propertyId);

  const handleLikeClick = async () => {
    if (!propertyId) {
      return;
    }
    if (!likedState && !isLiked) {
      const action = await dispatch(postfavorites({ property_id: propertyId }));
      if (action.payload?.success) {
        if (!isLiked) {
          if (path === "propertyDetail") {
            setPropertyData({ ...propertyData, is_favorite: true });
          } else if (path === "propertySearch") {
            const modifiedSearchedArr =
              storedSearchData?.searchedResult?.properties?.map((item: any) =>
                item.id === propertyId ? { ...item, is_favorite: true } : item
              );
            dispatch(
              setSearchData({
                ...storedSearchData,
                searchedResult: {
                  ...storedSearchData.searchedResult,
                  properties: modifiedSearchedArr,
                },
              })
            );
          } else {
            const remaining = stayListings?.map((item: any) =>
              item.id === propertyId ? { ...item, is_favorite: true } : item
            );
            dispatch(setProperties(remaining));
          }
        }
      }
    } else {
      const action = await dispatch(
        removeFavorite({ property_id: propertyId })
      );
      if (action.payload?.success) {
        if (isLiked) {
          if (path === "propertyDetail") {
            setPropertyData({ ...propertyData, is_favorite: false });
          } else if (path === "propertySearch") {
            const modifiedSearchedArr =
              storedSearchData?.searchedResult?.properties?.map((item: any) =>
                item.id === propertyId ? { ...item, is_favorite: false } : item
              );
            dispatch(
              setSearchData({
                ...storedSearchData,
                searchedResult: {
                  ...storedSearchData.searchedResult,
                  properties: modifiedSearchedArr,
                },
              })
            );
          } else {
            const remaining = stayListings?.map((item: any) =>
              item.id === propertyId ? { ...item, is_favorite: false } : item
            );
            dispatch(setProperties(remaining));
          }
        } else {
          const remainingFavourites = favorites?.filter(
            (item: any) => item.id !== propertyId
          );
          if (setFavorites) setFavorites(remainingFavourites);
        }
      }
    }
  };

  return (
    <div
      className={`nc-BtnLikeIcon w-8 h-8 flex items-center justify-center rounded-full cursor-pointer ${
        likedState ? "text-red-500" : ""
      } ${colorClass} ${className}`}
      data-nc-id="BtnLikeIcon"
      title="Save"
      onClick={handleLikeClick}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-5 w-5"
        fill={likedState || isLiked ? "red" : "none"}
        viewBox="0 0 24 24"
        stroke={likedState || isLiked ? "red" : "currentColor"}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
        />
      </svg>
    </div>
  );
};

export default BtnLikeIcon;
