import React, { FC } from "react";
import { DEMO_STAY_LISTINGS } from "data/listings";
import { Link } from "react-router-dom";
import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
import { currencySymbol } from "shared/CurrencySymbol";
import GallerySliderV4 from "components/GallerySlider/GallerySliderV4";
import Badge from "shared/Badge/Badge";

export interface StayCardV6Props {
  className?: string;
  listingdata?: any;
  size?: "default" | "small";
}

const DATA = DEMO_STAY_LISTINGS[0];

const StayCardV6: FC<StayCardV6Props> = ({
  size = "default",
  className = "",
  listingdata = DATA,
}) => {
  const {
    property: {
      place_images,
      address,
      title,
      price_per_month,
      property_type,
      rooms,
      city,
      state,
    },
    like,
  } = listingdata;

  const href = `/listing-stay-detail/${listingdata?.property?.slug}`;

  const renderSliderGallery = () => {
    return (
      <div className="relative w-full">
        <GallerySliderV4
          uniqueID={`${listingdata?.property?.slug}`}
          ratioClass="aspect-w-4 aspect-h-3 "
          galleryImgs={place_images.map(
            (image: { id: number; url: string }) => ({
              id: image.id,
              url: image.url,
            })
          )}
          href={href}
        />
        {/* <BtnLikeIcon isLiked={like} className="absolute right-3 top-3 z-[1]" /> */}
        <div className="absolute left-3 top-3 z-[1]">
          <Badge name={property_type?.name || "Property"} />
        </div>
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className={size === "default" ? "p-4 space-y-4" : "p-3 space-y-2"}>
        <div className="space-y-2">
          <div className="flex items-center space-x-2">
            <h2
              className={` font-medium capitalize ${
                size === "default" ? "text-lg" : "text-base"
              }`}
            >
              <span className="line-clamp-1">{title}</span>
            </h2>
          </div>
          <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
            {size === "default" && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
            )}
            <span className="">
              {city}, {state}
            </span>
          </div>
        </div>
        <div className="w-14 border-b border-neutral-100 dark:border-neutral-800"></div>
        <div className="flex justify-between items-center">
          <span className="text-base font-semibold">
            {`${currencySymbol()} ${
              price_per_month
                ? Number(price_per_month)?.toFixed(2)
                : rooms?.length > 0
                ? rooms[0]?.price_per_month
                : "No price found"
            }`}
            {size === "default" && (
              <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
                /month
              </span>
            )}
          </span>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-StayCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow ${className}`}
      data-nc-id="StayCard"
    >
      {renderSliderGallery()}
      <Link to={href}>{renderContent()}</Link>
    </div>
  );
};

export default StayCardV6;
