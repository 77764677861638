import React from "react";
import { PropertyToEdit } from "redux/reducers/Properties/PropertiesSliceTypes";

interface PolicyProps {
  propertyData: PropertyToEdit | null;
}

const Policy: React.FC<PolicyProps> = ({ propertyData }) => {
  return (
    <div
      className={`listingSection__wrap ${
        propertyData?.is_shared_property ? "lg:w-3/4" : "w-full"
      }`}
    >
      <h2 className="text-2xl font-semibold">Property Policies & Rules</h2>
      <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />
      <div>
        <h4 className="text-lg font-semibold">Special Note</h4>
        <div className="prose sm:prose">
          <ul className="mt-3 text-neutral-500 dark:text-neutral-400 space-y-2">
            {propertyData?.additional_rules.map((rule) => (
              <li key={rule.id}>{rule.name}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Policy;
