import { PropertyToEdit } from "redux/reducers/Properties/PropertiesSliceTypes";
import Avatar from "shared/Avatar/Avatar";

interface HostInformationProps {
  propertyData: PropertyToEdit | null;
}

const HostInformation: React.FC<HostInformationProps> = ({ propertyData }) => {
  const createdAt = `${propertyData?.user?.created_at}`;
  const date = new Date(createdAt);

  const formattedDate = `Joined in ${date.toLocaleString("en-US", {
    month: "long",
  })} ${date.getFullYear()}`;
  return (
    <div
      className={`listingSection__wrap ${
        propertyData?.is_shared_property ? "lg:w-3/4" : "w-full"
      }`}
    >
      <h2 className="text-2xl font-semibold">Host Information</h2>
      <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
      <div className="flex items-center space-x-4">
        {propertyData && (
          <Avatar
            hasChecked
            sizeClass="h-10 w-10"
            radius="rounded-full"
            imgUrl={propertyData.user?.image}
          />
        )}
        <div>
          <a className="block text-xl font-medium" href="##">
            <span className="text-neutral-900 dark:text-neutral-200 font-medium">
              {propertyData &&
                `${propertyData.user?.first_name} ${propertyData.user?.last_name}`}
            </span>
          </a>
          <div className="mt-1.5 flex items-center text-sm text-neutral-500 dark:text-neutral-400"></div>
        </div>
      </div>
      <span className="block text-neutral-6000 dark:text-neutral-300">
        {propertyData?.user.about_me}
      </span>
      <div className="block text-neutral-500 dark:text-neutral-400 space-y-2.5">
        <div className="flex items-center space-x-3">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1.5}
              d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
            />
          </svg>
          <span>{formattedDate}</span>
        </div>
      </div>
      <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
      <div></div>
    </div>
  );
};

export default HostInformation;
