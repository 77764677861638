import { StarIcon } from "@heroicons/react/24/solid";
import React, { FC } from "react";
import { PropertyToEdit } from "redux/reducers/Properties/PropertiesSliceTypes";

export interface StartRatingProps {
  className?: string;
  point?: number;
  reviewCount?: number;
  propertyData?: PropertyToEdit | null;
  reviewTotal?:number;
}

const StartRating: FC<StartRatingProps> = ({
  className = "",
  propertyData,
  reviewCount,
  reviewTotal,
}) => {
  const review = propertyData?.average?.average_review || reviewCount || 0;
  const formattedreview = review ? review.toFixed(1) : null;
  return (
    <div
      className={`nc-StartRating flex items-center space-x-1 text-sm  ${className}`}
      data-nc-id="StartRating"
    >
      <div className="pb-[2px]">
        <StarIcon className="w-[18px] h-[18px] text-orange-500" />
      </div>
      <span className="font-medium ">{formattedreview}</span>
      <span className="text-neutral-500 dark:text-neutral-400">
        ({propertyData?.average?.count || (reviewTotal ?? 0)})
      </span>
    </div>
  );
};

export default StartRating;
