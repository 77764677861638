import { PropertyToEdit } from "redux/reducers/Properties/PropertiesSliceTypes";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import NcImage from "shared/NcImage/NcImage";
import { Amenities_demos } from "../constant";

interface AmenitiesFeaturesProps {
  propertyData: PropertyToEdit | null;
  openModalAmenities: () => void;
  isOpenModalAmenities: boolean;
  closeModalAmenities: () => void;
}

const AmenitiesFeatures: React.FC<AmenitiesFeaturesProps> = ({
  propertyData,
  openModalAmenities,
  isOpenModalAmenities,
  closeModalAmenities,
}) => {
  if (!propertyData) return null;

  const data = [
    ...(propertyData.features || []),
    ...(propertyData.amenities?.map((amenity) => ({
      ...amenity,
      feature_type: "amenity",
    })) || []),
  ];

  const showViewMore = data.length > 6;
  const displayItems = showViewMore ? data.slice(0, 6) : data;

  return (
    <div
      className={`listingSection__wrap ${
        propertyData.is_shared_property ? "lg:w-3/4" : "w-full"
      }`}
    >
      <div>
        <h2 className="text-2xl font-semibold">Features & Amenities</h2>
        <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
          About the property's amenities and services
        </span>
      </div>

      <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

      <div className="grid grid-cols-1 xl:grid-cols-3 gap-6 text-sm text-neutral-700 dark:text-neutral-300">
        {displayItems.map((item) => (
          <div key={item.id} className="flex items-center space-x-3">
            {item.image ? (
              <NcImage
                src={item?.image}
                alt={item?.name}
                className="w-8 h-8 object-cover rounded-md"
              />
            ) : (
              <i
                className={`text-3xl las ${
                  Amenities_demos.find((demo) => demo.name === item?.icon_name)
                    ? `${item?.icon_name}`
                    : "la-check-circle"
                }`}
              ></i>
            )}
            <span>{item.name}</span>
          </div>
        ))}
      </div>

      {showViewMore && (
        <>
          <div className="w-14 border-b border-neutral-200"></div>
          <div>
            <ButtonSecondary onClick={openModalAmenities}>
              View more amenities
            </ButtonSecondary>
          </div>
        </>
      )}
      <Transition appear show={isOpenModalAmenities} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeModalAmenities}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>

            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block py-8 h-screen w-full max-w-4xl">
                <div className="inline-flex pb-2 flex-col w-full text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-white">
                      Amenities
                    </h3>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalAmenities} />
                    </span>
                  </div>

                  <div className="px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                    <div className="px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                      {data.map((item) => (
                        <div
                          key={item?.id}
                          className="flex items-center space-x-3 p-3"
                        >
                          {item?.image ? (
                            <NcImage
                              src={item?.image}
                              alt={item?.name}
                              className="w-8 h-8 object-cover rounded-md"
                            />
                          ) : (
                            <i
                              className={`text-3xl las ${
                                Amenities_demos.find(
                                  (demo) => demo.name === item?.icon_name
                                )
                                  ? `${item?.icon_name}`
                                  : "la-check-circle"
                              }`}
                            ></i>
                          )}
                          <span>{item?.name}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default AmenitiesFeatures;
