import { FC } from "react";
import { BookingRequestDetails } from "../../redux/reducers/Properties/PropertiesSliceTypes";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import {
  CreateOrderActions,
  CreateOrderData,
  OnApproveActions,
  OnApproveData,
} from "@paypal/paypal-js";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import moment from "moment";
import { currencySymbol } from "shared/CurrencySymbol";

interface InvoiceProps {
  bookingRequestDetails: BookingRequestDetails | null;
}

const Invoice: FC<InvoiceProps> = ({ bookingRequestDetails }) => {
  const navigate = useNavigate();
  const reserveBooking = useSelector(
    (state: RootState) => state.payment.reserveBooking
  );
  const totalAmount = Number(reserveBooking?.booking?.total);

  const onCreateOrder = (
    data: CreateOrderData,
    actions: CreateOrderActions
  ) => {
    return actions.order.create({
      intent: "CAPTURE",
      purchase_units: [
        {
          amount: {
            value: totalAmount?.toString(),
            currency_code: "USD",
          },
        },
      ],
      application_context: {
        return_url: `${window.location.origin}/success`,
        cancel_url: `${window.location.origin}/booking`,
      },
    });
  };

  const onApproveOrder = async (
    data: OnApproveData,
    actions: OnApproveActions,
    navigate: NavigateFunction
  ) => {
    if (actions.order) {
      const details = await actions.order.capture();
      if (details.status === "COMPLETED") {
        navigate("/success?payment_method=paypal&redirect_status=succeeded");
      }
    }
  };

  return (
    bookingRequestDetails && (
      <div className="flex p-6 bg-gray-100 dark:bg-gray-900 justify-center mt-5">
        <div className="w-2/3 p-6 border rounded shadow bg-white dark:bg-gray-800">
          <h2 className="text-xl font-medium mb-4 text-black dark:text-white">
            Create Invoice
          </h2>
          <div className="mb-6">
            <div className="flex flex-col space-y-2">
              <div className="flex justify-between">
                <span className="text-md text-gray-500 dark:text-gray-400">
                  Period :
                </span>
                <span className="text-md text-gray-500 dark:text-gray-400">
                  {moment(bookingRequestDetails?.check_in_date).format(
                    "MMM DD, YYYY"
                  )}{" "}
                  to{" "}
                  {moment(bookingRequestDetails?.check_out_date).format(
                    "MMM DD, YYYY"
                  )}
                </span>
              </div>
              <div className="flex justify-between">
                <span className="text-md text-gray-500 dark:text-gray-400">
                  No. of Months :
                </span>
                <span className="text-md text-gray-500 dark:text-gray-400">
                  {bookingRequestDetails?.no_of_months || "N/A"}
                </span>
              </div>
              <div className="flex justify-between">
                <span className="text-md text-gray-500 dark:text-gray-400">
                  No. of guests :
                </span>
                <span className="text-md text-gray-500 dark:text-gray-400">
                  {bookingRequestDetails?.number_of_guests}
                </span>
              </div>
            </div>
          </div>
          <div>
            <table className="w-full mb-4">
              <thead>
                <tr className="border-b">
                  <th className="text-left font-medium text-black dark:text-gray-300">
                    Detail
                  </th>
                  <th className="text-right font-medium text-black dark:text-gray-300">
                    Subtotal
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="border-b text-gray-500 dark:text-gray-400 text-sm p-6">
                  <td className="pt-4 pb-4">First month's rent</td>
                  <td className="pt-4 pb-4 text-right">
                    {`${currencySymbol()} ${
                      Number(bookingRequestDetails?.item_total)?.toFixed(2) || 0
                    }`}
                  </td>
                </tr>

                <tr className="border-b text-gray-500 dark:text-gray-400 text-sm p-6">
                  <td className="pt-4 pb-4"> One-Time Service Fee</td>
                  <td className="pt-4 pb-4 text-right">
                    {`${currencySymbol()} ${Number(
                      bookingRequestDetails?.service_tax
                    )?.toFixed(2)}`}
                  </td>
                </tr>

                {/* {Number(bookingRequestDetails?.calculate_cleaning_fee) > 0 && (
                  <tr className="border-b text-gray-500 dark:text-gray-400 text-sm p-6">
                    <td className="pt-4 pb-4"> Cleaning Fee :</td>
                    <td className="pt-4 pb-4 text-right">
                      {`${currencySymbol()} ${Number(
                        bookingRequestDetails?.calculate_cleaning_fee
                      )?.toFixed(2)}`}
                    </td>
                  </tr>
                )} */}

                {/* {Number(bookingRequestDetails?.calculate_city_fee) > 0 && (
                  <tr className="border-b text-gray-500 dark:text-gray-400 text-sm p-6">
                    <td className="pt-4 pb-4">City Fee</td>
                    <td className="pt-4 pb-4 text-right">
                      {`${currencySymbol()} ${Number(
                        bookingRequestDetails?.calculate_city_fee
                      )?.toFixed(2)}`}
                    </td>
                  </tr>
                )} */}
                {Number(bookingRequestDetails?.extra_guest_amount) > 0 && (
                  <tr className="border-b text-gray-500 dark:text-gray-400 text-sm p-6">
                    <td className="pt-4 pb-4"> Extra guest amount :</td>
                    <td className="pt-4 pb-4 text-right">
                      {`${currencySymbol()} ${Number(
                        bookingRequestDetails?.extra_guest_amount
                      )?.toFixed(2)}`}
                    </td>
                  </tr>
                )}

                {Number(bookingRequestDetails?.invoice_total) > 0 && (
                  <tr className="border-b text-gray-500 dark:text-gray-400 text-sm p-6">
                    <td className="pt-4 pb-4"> Invoice total :</td>
                    <td className="pt-4 pb-4 text-right">
                      {`${currencySymbol()} ${Number(
                        bookingRequestDetails?.invoice_total
                      )?.toFixed(2)}`}
                    </td>
                  </tr>
                )}

                {Number(bookingRequestDetails?.tax_total) > 0 && (
                  <tr className="border-b text-gray-500 dark:text-gray-400 text-sm p-6">
                    <td className="pt-4 pb-4">Tax total :</td>
                    <td className="pt-4 pb-4 text-right">
                      {`${currencySymbol()} ${Number(
                        bookingRequestDetails?.tax_total
                      )?.toFixed(2)}`}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <div className="flex justify-between font-medium text-black dark:text-white">
              <span>Total</span>
              <span>
                {`${currencySymbol()} ${
                  Number(bookingRequestDetails?.total)?.toFixed(2) || 0
                }`}
              </span>
            </div>
          </div>
          <div className="flex flex-wrap gap-y-4 gap-x-4 mt-6">
            {/* Credit Card Button */}
            <div className="flex-1 min-w-[180px]">
              <button
                onClick={() => navigate("/checkout")}
                className="px-4 py-3 bg-blue-500 text-white rounded text-sm md:text-base truncate dark:bg-blue-600 dark:hover:bg-blue-700"
              >
                Pay with Credit Card
              </button>
            </div>

            {/* PayPal Button */}
            {process.env.REACT_APP_PAYPAL_CLIENT_ID && (
              <div className="flex-1 min-w-[180px] z-0">
                <PayPalScriptProvider
                  options={{
                    clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
                    currency: "USD",
                    intent: "capture",
                  }}
                >
                  <PayPalButtons
                    style={{
                      layout: "horizontal",
                      color: "blue",
                      shape: "rect",
                      height: 48,
                      tagline: false,
                    }}
                    className="w-full h-full"
                    createOrder={(data, actions) =>
                      onCreateOrder(data, actions)
                    }
                    onApprove={(data, actions) =>
                      onApproveOrder(data, actions, navigate)
                    }
                  />
                </PayPalScriptProvider>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  );
};

export default Invoice;
