import React from "react";
import { PropertyToEdit } from "redux/reducers/Properties/PropertiesSliceTypes";
import user from "../../../../images/dummy.webp";
import { Carousel } from "@mantine/carousel";
import "@mantine/carousel/styles.css";

interface RoomiesProps {
  propertyData: PropertyToEdit | null;
}

const Roomies: React.FC<RoomiesProps> = ({ propertyData }) => {
  return (
    <div
      className={`listingSection__wrap ${
        propertyData?.is_shared_property ? "lg:w-3/4" : "w-full"
      }`}
    >
      <h2 className="text-2xl font-semibold">Roommates</h2>

      {propertyData?.roomies && propertyData.roomies.length > 0 ? (
        <Carousel
          withIndicators
          height={200}
          slideSize={propertyData.roomies.length === 1 ? "100%" : "33.333333%"}
          slideGap="md"
          loop={propertyData.roomies.length > 1}
          align="start"
          slidesToScroll={propertyData.roomies.length === 1 ? 1 : 3}
        >
          {propertyData.roomies.map((roomie) => (
            <Carousel.Slide key={roomie.id}>
              <div className="p-4 bg-white dark:bg-neutral-800 shadow-md dark:shadow-lg rounded-md text-center transition-all duration-300">
                <img
                  src={user}
                  alt={roomie.name}
                  className="w-20 h-20 rounded-full mx-auto mb-2 border dark:border-neutral-600"
                />
                <h3 className="text-lg font-semibold text-neutral-900 dark:text-neutral-100">
                  {roomie.name}
                </h3>
                <p className="text-neutral-500 dark:text-neutral-400">
                  {roomie.country}
                </p>
              </div>
            </Carousel.Slide>
          ))}
        </Carousel>
      ) : (
        <p className="text-neutral-500 dark:text-neutral-400 mt-2">
          No roommates available for this property.
        </p>
      )}
    </div>
  );
};

export default Roomies;
