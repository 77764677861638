import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "redux/store";
import { similar_Properties } from "../../../../redux/reducers/Properties/PropertiesSlice";
import { PropertyToEdit } from "redux/reducers/Properties/PropertiesSliceTypes";
import StayCardSkeleton from "shared/Skelton/CardSkeltion";
import SimilarCard from "components/StayCard/SimilarCard";

interface SimilarPropertyProps {
  propertyData: PropertyToEdit | null;
}

const SimilarProperty: React.FC<SimilarPropertyProps> = ({ propertyData }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [similarProperties, setSimilarProperties] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const property_type_id = propertyData?.property_type?.id;
  const property_category_id = propertyData?.property_category?.id;
  const property_id = propertyData?.id;

  const fetchSimilarProperties = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await dispatch(
        similar_Properties({
          property_type_id: property_type_id ?? 0,
          property_category_id: property_category_id ?? 0,
          property_id: property_id ?? 0,
        })
      ).unwrap();
      setSimilarProperties(response?.properties || []);
    } catch (err: any) {
      setError(err.message || "Failed to fetch similar properties");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (
      propertyData?.property_type?.id &&
      propertyData?.property_category?.id &&
      propertyData?.id
    ) {
      fetchSimilarProperties();
    }
  }, [propertyData]);

  return (
    <div
      className={`listingSection__wrap ${
        propertyData?.is_shared_property ? "lg:w-3/4" : "w-full"
      }`}
    >
      <h2 className="text-2xl font-semibold">Similar Properties</h2>
      {loading ? (
        <StayCardSkeleton />
      ) : similarProperties?.length === 0 ? (
        <p className="text-center text-gray-500">
          No similar properties available
        </p>
      ) : (
        <div className="grid gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-2">
          {similarProperties.map((property, index) => (
            <SimilarCard key={index} similarProperties={property} />
          ))}
        </div>
      )}
    </div>
  );
};

export default SimilarProperty;
