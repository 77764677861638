import { FC, ReactNode, useEffect, useState } from "react";
import { StayDataType } from "data/types";
import HeaderFilter from "./HeaderFilter";
import StayCard from "components/StayCard/StayCard";
import { AppDispatch, RootState } from "redux/store";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getAllProperties } from "../../redux/reducers/Properties/PropertiesSlice";
import SectionGridFeaturePlacesSkeleton from "shared/Skelton/SectionGirdSkelton";
import Pagination from "shared/Pagination/Pagination";

export interface SectionGridFeaturePlacesProps {
  stayListings?: any;
  gridClass?: string;
  heading?: ReactNode;
  subHeading?: ReactNode;
  headingIsCenter?: boolean;
  tabs?: string[];
  pagemetaData?: any;
}

const SectionGridFeaturePlaces: FC<SectionGridFeaturePlacesProps> = ({
  stayListings,
  gridClass = "",
  heading = "Featured places to stay",
  subHeading = "Popular places to stay that Chisfis recommends for you",
  tabs = ["New York", "Tokyo", "Paris", "London"],
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [page, setPage] = useState(1);

  const { loading, metaData } = useSelector(
    (state: RootState) => state.property
  );

  const renderCard = (stay: StayDataType) => (
    <StayCard
      key={stay.id}
      data={stay}
      stayListings={stayListings}
    />
  );

  useEffect(() => {
    dispatch(getAllProperties(page));
  }, [page]);

  return (
    <div className="nc-SectionGridFeaturePlaces relative">
      {loading ? (
        <SectionGridFeaturePlacesSkeleton />
      ) : (
        <>
          <HeaderFilter
            tabActive={"New York"}
            subHeading={subHeading}
            tabs={tabs}
            heading={heading}
            onClickTab={() => {}}
          />
          <div
            className={`grid gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 ${gridClass}`}
          >
            {stayListings?.map((stay: any) => renderCard(stay))}
          </div>

          <div className="flex mt-16 justify-center items-center">
            {metaData?.total_pages > 1 && (
              <Pagination
                currentPage={page}
                totalPages={metaData?.total_pages}
                onPageChange={setPage}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default SectionGridFeaturePlaces;
