import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserBookingsDetails,
  getHostBookingsDetails,
} from "../../../redux/reducers/Properties/PropertiesSlice";
import { AppDispatch, RootState } from "redux/store";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import StayCardV4 from "components/StayCard/StayCardV4";
import HeaderFilterV2 from "containers/PageHome/HeaderFilterV2";
import SectionGridFeaturePlacesSkeleton from "shared/Skelton/SectionGirdSkelton";
import Pagination from "shared/Pagination/Pagination";
import ButtonPrimary from "shared/Button/ButtonPrimary";

interface BookingData {
  id: string;
  [key: string]: string | number | boolean;
}

const MyRequest: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [page, setPage] = useState(1);
  const [bookingData, setBookingData] = useState<BookingData[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [metaData, setMetaData] = useState<any>([]);
  const { currentUser } = useSelector((state: RootState) => state.user);
  const [query, setQuery] = useState<string>("");
  const [searchQuery, setSearchQuery] = useState<string>("");

  const fetchBookings = useCallback(
    async (page: number, searchQuery: string = "") => {
      setIsLoading(true);
      try {
        if (currentUser?.is_host) {
          const response = await dispatch(
            getHostBookingsDetails({ page, query: searchQuery })
          );
          if (response?.payload?.bookings) {
            setBookingData(response.payload.bookings);
            setMetaData(response.payload);
          }
        } else {
          const response = await dispatch(
            getUserBookingsDetails({ page, query: searchQuery })
          ).unwrap();
          setBookingData(response?.bookings);
          setMetaData(response.payload);
        }
      } catch (error) {
        console.error("Error fetching bookings:", error);
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch, currentUser?.is_host]
  );

  useEffect(() => {
    fetchBookings(1, searchQuery);
  }, [searchQuery]);

  const handleSearch = () => {
    setSearchQuery(query);
  };

  useEffect(() => {
    if (query === "") {
      fetchBookings(1);
    }
  }, [query]);

  if (isLoading && !bookingData.length) {
    return (
      <div className="p-10">
        <SectionGridFeaturePlacesSkeleton />
      </div>
    );
  }

  return (
    <div className="nc-PageHome relative overflow-hidden">
      <div className="container relative space-y-24 mb-24 lg:space-y-28 lg:mb-28">
        <div className="relative py-16">
          <BackgroundSection />
          <div className="nc-SectionGridFeaturePlaces relative">
            <HeaderFilterV2
              heading="My Bookings"
              subHeading="View the complete list of all your booking requests."
            />
            <div className="nc-MyListing">
              <div className="mb-8">
                <div className="flex items-center">
                  <input
                    type="text"
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    placeholder="Search By Property Name..."
                    className="p-3 w-full md:w-1/3 border rounded-lg"
                  />
                  <ButtonPrimary
                    onClick={handleSearch}
                    className="ml-2 px-4 py-2 rounded-lg text-white bg-orange-600 hover:bg-orange-700"
                    disabled={!query.trim()}
                  >
                    Search
                  </ButtonPrimary>
                </div>
              </div>
              {bookingData.length > 0 ? (
                <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3">
                  {bookingData.map((data) => (
                    <StayCardV4 key={data.id} booking={data} />
                  ))}
                </div>
              ) : (
                <div className="text-center py-10">
                  <h3 className="text-2xl font-semibold">No bookings found</h3>
                  <p className="text-gray-500 mt-2">
                    There are currently no bookings to display.
                  </p>
                </div>
              )}
            </div>
            {metaData?.meta?.total_pages > 1 && (
              <div className="flex mt-8 justify-center items-center space-x-4">
                <Pagination
                  currentPage={page}
                  totalPages={metaData?.meta?.total_pages}
                  onPageChange={fetchBookings}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyRequest;
