import React, { useState } from "react";
import { PropertyToEdit } from "redux/reducers/Properties/PropertiesSliceTypes";
import ButtonPrimary from "shared/Button/ButtonPrimary";

interface UniversitiesProps {
  propertyData: PropertyToEdit | null;
}

const Universities: React.FC<UniversitiesProps> = ({ propertyData }) => {
  const [showneighbourhoods, setShowneighbourhoods] = useState(false);
  const [showUniversities, setShowUniversities] = useState(false);
  const toggleUniversities = () => {
    setShowUniversities(!showUniversities);
  };

  const truncatedUniversities =
    propertyData?.university_nearby &&
    propertyData.university_nearby.length > 150
      ? propertyData?.university_nearby.substring(0, 150) + "..."
      : propertyData?.university_nearby || "";

  const toggleNeighbourhoods = () => {
    setShowneighbourhoods(!showneighbourhoods);
  };

  const truncatedNeighbourhoods =
    propertyData?.about_neighbourhoods &&
    propertyData.about_neighbourhoods.length > 150
      ? propertyData?.about_neighbourhoods.substring(0, 150) + "..."
      : propertyData?.about_neighbourhoods || "";

  return (
    <div
      className={`listingSection__wrap ${
        propertyData?.is_shared_property ? "lg:w-3/4" : "w-full"
      }`}
    >
      <h2 className="text-2xl font-semibold">Universities Nearby</h2>
      <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />
      <div>
        <div className="prose sm:prose">
          <ul className="mt-3 text-neutral-500 dark:text-neutral-400 space-y-2">
            <span>
              {showUniversities
                ? propertyData?.university_nearby
                : truncatedUniversities}
            </span>
            <div className="mt-5">
              {propertyData?.university_nearby &&
                propertyData.university_nearby.length > 150 && (
                  <ButtonPrimary onClick={toggleUniversities} className="">
                    {showUniversities ? "Show Less" : "Show More"}
                  </ButtonPrimary>
                )}
            </div>
          </ul>
        </div>
      </div>
      <h2 className="text-2xl font-semibold">About Neighborhood</h2>
      <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />
      <div>
        <div className="prose sm:prose">
          <ul className="mt-3 text-neutral-500 dark:text-neutral-400 space-y-2">
            <span>
              {showneighbourhoods
                ? propertyData?.about_neighbourhoods
                : truncatedNeighbourhoods}
            </span>
            <div className="mt-5">
              {propertyData?.about_neighbourhoods &&
                propertyData.about_neighbourhoods.length > 150 && (
                  <ButtonPrimary onClick={toggleNeighbourhoods} className="">
                    {showneighbourhoods ? "Show Less" : "Show More"}
                  </ButtonPrimary>
                )}
            </div>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Universities;
