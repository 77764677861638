import Glide from "@glidejs/glide";
import useNcId from "hooks/useNcId";
import { FC, useEffect, useMemo } from "react";
import NextPrev from "shared/NextPrev/NextPrev";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

export interface GallerySliderProps {
  className?: string;
  galleryImgs: string[];
  ratioClass?: string;
  uniqueID: string;
  href?: string;
}

const GallerySlider: FC<GallerySliderProps> = ({
  className = "",
  galleryImgs,
  ratioClass = "aspect-w-4 aspect-h-3",
  uniqueID = "uniqueID",
  href = `/listing-stay-detail?id=${uniqueID}`,
}) => {
  const UNIQUE_CLASS = `gallerySlider__${uniqueID}` + useNcId();

  let MY_GLIDEJS = useMemo(() => {
    return new Glide(`.${UNIQUE_CLASS}`, {
      perView: 1,
      gap: 0,
      keyboard: false,
    });
  }, [UNIQUE_CLASS]);

  useEffect(() => {
    const element = document.querySelector(`.${UNIQUE_CLASS}`);

    if (element && galleryImgs?.length > 0) {
      const timeoutId = setTimeout(() => {
        MY_GLIDEJS?.mount();
      }, 3000);

      return () => clearTimeout(timeoutId); // Clean up the timeout on unmount or when dependencies change
    } else {
      // console.warn("Element not found or gallery images are empty.");
    }
  }, [MY_GLIDEJS, UNIQUE_CLASS, galleryImgs]);

  const renderDots = () => {
    return (
      <div
        className="glide__bullets flex items-center justify-center absolute bottom-2 left-1/2 transform -translate-x-1/2 space-x-1.5"
        data-glide-el="controls[nav]"
      >
        {galleryImgs?.map((_, i) => (
          <button
            className="glide__bullet w-1.5 h-1.5 rounded-full bg-neutral-300"
            key={i}
            data-glide-dir={`=${i}`}
          />
        ))}
      </div>
    );
  };

  const renderSliderGallery = () => {
    const defaultImage =
      "https://images.pexels.com/photos/186077/pexels-photo-186077.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1";
    const imagesToDisplay =
      galleryImgs && galleryImgs.length > 0 ? galleryImgs : [defaultImage];

    return (
      <div className={`${UNIQUE_CLASS} relative group overflow-hidden`}>
        <div className="glide__track" data-glide-el="track">
          <ul className="glide__slides">
            {imagesToDisplay?.map((item, index) => (
              <li
                key={index}
                className="glide__slide h-full" // Ensure slide takes full height
              >
                <Link
                  to={href}
                  className={`block ${ratioClass} h-full w-full`} // Ensure link fills container
                >
                  <LazyLoadImage
                    src={item}
                    placeholderSrc={defaultImage}
                    effect="blur"
                    className="w-full h-full object-cover" // Key: fill container and cover
                    wrapperClassName="w-full h-full" // Ensure wrapper fills container
                    style={{
                      minHeight: "100%", // Fallback for height
                      minWidth: "100%", // Fallback for width
                    }}
                  />
                </Link>
              </li>
            ))}
          </ul>
        </div>

        {/* DOTS */}
        <div className="absolute -bottom-4 inset-x-0 h-10 bg-gradient-to-t from-neutral-900"></div>
        {renderDots()}

        {/* NAV */}
        <div className="absolute opacity-0 group-hover:opacity-100 transition-opacity flex top-1/2 transform -translate-y-1/2 inset-x-2 justify-between">
          <NextPrev className="w-full justify-between" btnClassName="w-8 h-8" />
        </div>
      </div>
    );
  };

  return (
    <div className={`nc-GallerySlider ${className}`} data-nc-id="GallerySlider">
      {renderSliderGallery()}
    </div>
  );
};

export default GallerySlider;
