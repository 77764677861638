import React, { useState } from "react";
import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow,
  Circle,
} from "@react-google-maps/api";
import { PropertyToEdit } from "redux/reducers/Properties/PropertiesSliceTypes";

interface ListingMapProps {
  propertyData: PropertyToEdit | null;
  userLocation: {
    lat: number;
    lng: number;
  };
}

const ListingMap: React.FC<ListingMapProps> = ({
  propertyData,
  userLocation,
}) => {
  const [showInfo, setShowInfo] = useState(false);
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY || "",
    libraries: ["places"],
  });
  const shortAddress = `${propertyData?.city}, ${propertyData?.state}`;
  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return (
      <div className="flex items-center justify-center py-10">
        <div className="flex items-center justify-center space-x-2">
          <div className="w-10 h-10 border-4 border-t-4 border-gray-300 border-t-primary-6000 rounded-full animate-spin"></div>
        </div>
      </div>
    );
  }

  const mapContainerStyle = {
    width: "100%",
    height: "100%",
  };

  return (
    <div
      className={`listingSection__wrap ${propertyData?.is_shared_property ? "lg:w-3/4" : "w-full"
        }`}
    >
      <div>
        <h2 className="text-2xl font-semibold">Location</h2>
        <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
          {shortAddress ? shortAddress : "Not available"}
        </span>
      </div>
      <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />
      <div className="aspect-w-5 aspect-h-5 sm:aspect-h-3 ring-1 ring-black/10 rounded-xl z-0">
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          zoom={13}
          center={userLocation}
          options={{
            maxZoom: 15,
            minZoom: 12,
            zoomControl: true,
            mapTypeControl: false,
            streetViewControl: false,
            fullscreenControl: false,
            disableDefaultUI: true,
            keyboardShortcuts: false,
            styles: [
              {
                featureType: "all",
                elementType: "labels",
                stylers: [{ visibility: "off" }],
              },
              {
                featureType: "road",
                elementType: "labels",
                stylers: [{ visibility: "simplified" }, { lightness: 40 }],
              },
              {
                featureType: "road.arterial",
                elementType: "labels",
                stylers: [{ visibility: "on" }],
              },
              {
                featureType: "road.highway",
                elementType: "labels",
                stylers: [{ visibility: "on" }, { weight: 1.5 }],
              },
              {
                featureType: "road.highway",
                elementType: "geometry",
                stylers: [{ visibility: "simplified" }, { lightness: 20 }],
              },
              {
                featureType: "road.arterial",
                elementType: "geometry",
                stylers: [{ visibility: "simplified" }, { lightness: 40 }],
              },
              {
                featureType: "road.local",
                elementType: "geometry",
                stylers: [{ visibility: "off" }],
              },
              {
                featureType: "poi.business",
                elementType: "labels",
                stylers: [{ visibility: "simplified" }],
              },
              {
                featureType: "poi.government",
                elementType: "labels",
                stylers: [{ visibility: "on" }],
              },
              {
                featureType: "poi.place_of_worship",
                elementType: "labels",
                stylers: [{ visibility: "off" }],
              },
              {
                featureType: "poi.medical",
                elementType: "labels",
                stylers: [{ visibility: "simplified" }],
              },
              {
                featureType: "poi.school",
                elementType: "labels",
                stylers: [{ visibility: "simplified" }],
              },
              {
                featureType: "landscape",
                elementType: "geometry",
                stylers: [{ lightness: 60 }],
              },
              {
                featureType: "transit",
                elementType: "geometry",
                stylers: [{ visibility: "off" }],
              },
            ],
          }}
        >
          <Marker position={userLocation} onClick={() => setShowInfo(true)}>
            {showInfo && (
              <InfoWindow
                position={userLocation}
                onCloseClick={() => setShowInfo(false)}
              >
                <div className="w-72 bg-white rounded-lg  overflow-hidden relative font-sans">
                  <div className="relative">
                    <span className="absolute top-2 left-2 bg-red-500 text-white text-xs px-2 py-1 rounded-md">
                      {propertyData?.property_state}
                    </span>

                    <img
                      src={propertyData?.place_images[0]?.url}
                      alt="Property"
                      className="w-full h-40 object-cover"
                    />
                  </div>

                  <div className="p-3">
                    <h3 className="text-lg font-bold flex items-center">
                      <span className="truncate w-[60%] overflow-hidden whitespace-nowrap">
                        {propertyData?.title}
                      </span>
                      <span className="text-orange-500 font-bold w-[40%] text-right">
                        USD {propertyData?.price_per_month}
                      </span>
                    </h3>
                    <p className="text-sm text-gray-600">
                      {propertyData?.property_type?.name} /{" "}
                      {propertyData?.property_category?.name}
                    </p>

                    <div className="flex justify-between items-center mt-2 text-gray-700 text-sm">
                      <span className="flex items-center gap-1">
                        🛏 {propertyData?.total_bedrooms}
                      </span>
                      <span className="flex items-center gap-1">
                        👤 {propertyData?.guest_number}
                      </span>
                    </div>
                  </div>
                </div>
              </InfoWindow>
            )}
          </Marker>
          <Circle
            center={userLocation}
            radius={500}
            options={{
              fillColor: "#FF0000",
              fillOpacity: 0.2,
              strokeColor: "#FF0000",
              strokeOpacity: 0.5,
              strokeWeight: 2,
            }}
          />
        </GoogleMap>
      </div>
    </div>
  );
};

export default ListingMap;
