import React, { Fragment, useState } from "react";
// @ts-ignore
import { Popover, Transition } from "@headlessui/react";
import { FC } from "react";
import ClearDataButton from "./ClearDataButton";
import ButtonSubmit from "./ButtonSubmit";
// @ts-ignore
import { UserPlusIcon } from "@heroicons/react/24/outline";
import { GuestsObject } from "../../../src/components/HeroSearchForm/type";
import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import moment from "moment";
import {
  searchProperties,
  setSearchData,
} from "../../redux/reducers/Properties/PropertiesSlice";
import { AppDispatch } from "redux/store";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

export interface GuestsInputProps {
  fieldClassName?: string;
  className?: string;
  buttonSubmitHref?: string;
  hasButtonSubmit?: boolean;
  userLocation: {
    latitude: number;
    longitude: number;
    locationData: any;
  } | null;
  startDate: Date | null;
  endDate: Date | null;
  validateLocation?: () => boolean;
  guestsError?: string;
  setGuests?: (value: number) => void;
  setGuestsError?: (value: string) => void;
  selectedLocation?: any;
  resetForm?: () => void;
}

const GuestsInput: FC<GuestsInputProps> = ({
  fieldClassName = "[ nc-hero-field-padding ]",
  className = "[ nc-flex-1 ]",
  userLocation,
  selectedLocation,
  startDate,
  endDate,
  validateLocation,
  guestsError,
  setGuests,
  setGuestsError,
  resetForm,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [guestAdultsInputValue, setGuestAdultsInputValue] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const modifiedStartDate = moment(
    startDate,
    "d ddd MMM DD YYYY HH:mm:ss [GMT]Z"
  ).format("YYYY-MM-DD");
  const modifiedEndDate = moment(
    endDate,
    "d ddd MMM DD YYYY HH:mm:ss [GMT]Z"
  ).format("YYYY-MM-DD");

  const handleChangeData = (value: number, type: keyof GuestsObject) => {
    if (type === "guestAdults") {
      setGuestAdultsInputValue(value);
      setGuests && setGuests(value);
      setGuestsError && setGuestsError("");
    }
  };

  const totalGuests = guestAdultsInputValue;

  const onSearchSubmit = async () => {
    setIsLoading(true);
    if (validateLocation && !validateLocation()) {
      setIsLoading(false);
      return;
    }

    if (userLocation?.latitude && userLocation?.longitude) {
      const searchQuery = `q[availability_start_lteq]=${modifiedStartDate}&q[availability_end_gteq]=${modifiedEndDate}&q[guest_number_gteq]=${totalGuests}&q[latitude]=${userLocation?.latitude}&q[longitude]=${userLocation?.longitude}`;
      const { latitude, longitude, locationData } = userLocation;
      try {
        const action = await dispatch(searchProperties(searchQuery));
        const searchedResult = action.payload;
        dispatch(
          setSearchData({
            startDate,
            endDate,
            totalGuests,
            latitude,
            longitude,
            locationData,
            label: `${locationData.city}, ${locationData.country}`,
            searchedResult,
          })
        );
        if (action?.payload?.success) {
          navigate("/listing-stay-map");
          resetForm && resetForm();
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <Popover className={`flex relative ${className}`}>
      {({ open }) => (
        <>
          <div
            className={`flex-1 z-10 flex items-center focus:outline-none  w-full${
              open ? "nc-hero-field-focused" : ""
            }`}
          >
            <Popover.Button
              className={`relative z-10 flex-1 flex text-left items-center ${fieldClassName} space-x-3 focus:outline-none`}
              onClickCapture={() => document.querySelector("html")?.click()}
            >
              <div className="text-neutral-300 dark:text-neutral-400">
                <UserPlusIcon className="w-5 h-5 lg:w-7 lg:h-7" />
              </div>
              <div className="flex-grow">
                <span className="block xl:text-lg font-semibold">
                  {totalGuests || ""} Guests
                </span>
                <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
                  {totalGuests ? "Guests" : "Add guests"}
                  {guestsError && (
                    <p className="text-red-500 text-sm mt-2">{guestsError}</p>
                  )}
                </span>
              </div>

              {!!totalGuests && open && (
                <ClearDataButton
                  onClick={() => {
                    setGuestAdultsInputValue(0);
                  }}
                />
              )}
            </Popover.Button>

            {/* BUTTON SUBMIT OF FORM */}

            <div className="pr-2 xl:pr-4">
              <ButtonSubmit onClick={onSearchSubmit} loading={isLoading} />
            </div>
          </div>

          {open && (
            <div className="h-8 absolute self-center top-1/2 -translate-y-1/2 z-0 -left-0.5 right-1 bg-white dark:bg-neutral-800"></div>
          )}
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute right-0 z-10 w-full sm:min-w-[340px] max-w-sm bg-white dark:bg-neutral-800 top-full mt-1 y-5 sm:py-6 px-4 sm:px-8 rounded-3xl shadow-xl">
              <NcInputNumber
                className="w-full"
                defaultValue={guestAdultsInputValue}
                onChange={(value) => handleChangeData(value, "guestAdults")}
                max={10}
                min={1}
                label="No of Guest"
              />
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default GuestsInput;
