import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Formik, useFormikContext } from "formik";
import { AppDispatch, RootState } from "redux/store";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import FormItem from "./FormItem";
import {
  getallupdateProperties,
  getRoomAmenitiesProperty,
  getRoomFeaturesProperty,
  getBedType,
  getRoomType,
  uploadImageProperties,
  deleteImage,
} from "../../redux/reducers/Properties/PropertiesSlice";
import successHandler from "utils/helpers/SuccessHandler";
import { roomRuleValidationSchema } from "utils/formSchema";
import CheckboxV2 from "shared/Checkbox/CheckboxV2";
import {
  BedType,
  RoomType,
} from "../../redux/reducers/Properties/PropertiesSliceTypes";
import CommonLayoutV2 from "./CommonLayoutV2";
//@ts-ignore
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { format } from "date-fns";
import CustomDatePickerV2 from "shared/DateField/CustomDatePickerV2";
import { currencySymbol } from "shared/CurrencySymbol";
import { useDropzone } from "react-dropzone";
import NcImage from "shared/NcImage/NcImage";
import "react-loading-skeleton/dist/skeleton.css";
import moment from "moment";

export interface RoomsProps {}

type RoomsAttributes = {
  rooms_attributes: RoomA[];
};

type RoomA = {
  id: string;
  name: string;
  price_per_month: string;
  status: string;
  booking_start: string;
  booking_end: string;
  description: string;
  bed_type_id: string;
  size: string;
  room_type_id: string;
  amenities: any[];
  features: any[];
};

const Rooms: FC<RoomsProps> = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { globalId } = useSelector((state: RootState) => state.property);
  const { slug } = useParams();
  const id = slug;
  const [isSharedProperty, setIsSharedProperty] = useState<boolean>(false);
  const [property, setProperty] = useState<any>(null);

  const initialRoomValues = {
    id: "",
    name: "",
    price_per_month: "",
    status: "",
    booking_start: "",
    booking_end: "",
    description: "",
    bed_type_id: "",
    size: "",
    room_type_id: "",
    amenities: [],
    features: [],
    isSharedProperty: property?.is_shared_property,
    room_images: [],
    max_guests: "",
  };
  const [initialValues, setInitialValues] = useState<any>({
    rooms_attributes: [initialRoomValues],
  });

  const [amenities, setAmenities] = useState<any>([]);
  const [features, setFeatures] = useState<any>([]);
  const [bedTypes, setBedTypes] = useState<BedType[]>([]);
  const [reorderImages, setReorderImages] = useState<any>([]);
  const [totalRooms, setTotalRooms] = useState<number | null>(null);
  const RouteID = id;
  const [roomTypes, setRoomTypes] = useState<RoomType[]>([]);
  const [count, setCount] = useState<number>(1);
  const [roomImagePreviews, setRoomImagePreviews] = React.useState<{
    [key: number]: string[];
  }>({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (initialValues?.rooms_attributes?.length > 0) {
      setCount(initialValues?.rooms_attributes?.length);
    }
  }, [initialValues]);

  useEffect(() => {
    if (id) {
      const fetchPropertyData = async () => {
        const response: any = await dispatch(
          getallupdateProperties({ propertyId: id })
        );

        if (response.payload?.success) {
          setIsSharedProperty(response?.payload?.property?.is_shared_property);
          setProperty(response?.payload?.property);
        }

        setTotalRooms(response?.payload?.property?.total_rooms);
        if (response.payload?.property?.rooms) {
          const mappedRooms = response.payload.property.rooms.map(
            (room: any) => {
              const sortedPlaceImages = [...room?.room_images].sort(
                (a, b) => a.position - b.position
              );
              return {
                id: room.id,
                name: room.name,
                price_per_month:
                  room.price_per_month === "0.0"
                    ? ""
                    : room.price_per_month?.toString() || "",
                status: room.status,
                booking_start: room.booking_start
                  ? room.booking_start.split("T")[0]
                  : "",
                booking_end: room.booking_end
                  ? room.booking_end.split("T")[0]
                  : "",
                description: room.description || "",
                bed_type_id: room.bed_type?.id?.toString() || "",
                size: room.size?.toString() || "",
                room_type_id: room.room_type?.id?.toString() || "",
                amenities: room.amenities || [],
                features: room.features || [],
                isSharedProperty:
                  response?.payload?.property?.is_shared_property,
                room_images: [],
                existing_room_images: sortedPlaceImages || [],
                max_guests: room.max_guests?.toString() || "",
              };
            }
          );
          const uniqueRooms = Array.from(
            new Map(mappedRooms.map((room: any) => [room?.id, room])).values()
          );
          setInitialValues({
            rooms_attributes:
              uniqueRooms.length > 0
                ? uniqueRooms
                : initialValues.rooms_attributes?.map((item: any) => ({
                    ...item,
                    isSharedProperty:
                      response?.payload?.property?.is_shared_property || false,
                  })),
          });
        }
      };
      fetchPropertyData();
    }
  }, [dispatch, globalId, id]);

  const handleAmenitiesChange = (
    amenityId: number,
    roomIndex: number,
    values: any,
    setFieldValue: any
  ) => {
    const updatedAmenities = values.rooms_attributes.map(
      (room: any, index: any) => {
        if (index === roomIndex) {
          const currentAmenities = room.amenities || [];

          const newAmenities = currentAmenities.some(
            (item: any) =>
              item.amenity_id === amenityId || item.id === amenityId
          )
            ? currentAmenities.filter(
                (item: any) =>
                  item.amenity_id !== amenityId && item.id !== amenityId
              )
            : [...currentAmenities, { amenity_id: amenityId }];

          return { ...room, amenities: newAmenities };
        }
        return room;
      }
    );

    setFieldValue("rooms_attributes", updatedAmenities);
  };

  const handleFeaturesChange = (
    featureId: number,
    roomIndex: number,
    values: any,
    setFieldValue: any
  ) => {
    const updatedFeatures = values.rooms_attributes.map(
      (room: any, index: any) => {
        if (index === roomIndex) {
          const currentFeatures = room.features || [];
          const newFeatures = currentFeatures.some(
            (item: any) =>
              item.feature_id === featureId || item.id === featureId
          )
            ? currentFeatures.filter(
                (item: any) =>
                  item.feature_id !== featureId && item.id !== featureId
              )
            : [...currentFeatures, { feature_id: featureId }];

          return { ...room, features: newFeatures };
        }
        return room;
      }
    );

    setFieldValue("rooms_attributes", updatedFeatures);
  };

  const handleSubmit = async (values: typeof initialValues) => {
    const formData = new FormData();

    if (id) {
      formData.append(`property[id]`, id);
    }
    for (const [index, room] of values.rooms_attributes.entries()) {
      const modifiedAmenities = room.amenities?.map((item: any) => ({
        amenity_id: item.id || item.amenity_id,
      }));

      const modifiedFeatures = room.features?.map((item: any) => ({
        feature_id: item.id || item.feature_id,
      }));

      if (room.id) {
        formData.append(
          `property[rooms_attributes][${index}][id]`,
          room.id.toString()
        );
      }
      formData.append(`property[rooms_attributes][${index}][name]`, room.name);
      formData.append(
        `property[rooms_attributes][${index}][price_per_month]`,
        room.price_per_month ? room.price_per_month.toString() : ""
      );
      formData.append(
        `property[rooms_attributes][${index}][status]`,
        room.status
      );
      formData.append(
        `property[rooms_attributes][${index}][booking_start]`,
        moment(room.booking_start).format("YYYY-MM-DD") || ""
      );
      formData.append(
        `property[rooms_attributes][${index}][booking_end]`,
        moment(room.booking_end).format("YYYY-MM-DD") || ""
      );
      formData.append(
        `property[rooms_attributes][${index}][description]`,
        room.description
      );
      formData.append(
        `property[rooms_attributes][${index}][bed_type_id]`,
        room.bed_type_id.toString()
      );
      formData.append(
        `property[rooms_attributes][${index}][size]`,
        room.size.toString()
      );
      formData.append(
        `property[rooms_attributes][${index}][room_type_id]`,
        room.room_type_id.toString()
      );
      formData.append(
        `property[rooms_attributes][${index}][max_guests]`,
        room.max_guests.toString()
      );

      if (reorderImages?.length > 0) {
        reorderImages?.forEach((item: any) => {
          formData.append(
            "property[room_image_metadata][][blob_id]",
            item.blob_id
          );
          formData.append(
            "property[room_image_metadata][][position]",
            item.position
          );
        });
      }

      if (modifiedAmenities) {
        modifiedAmenities.forEach((amenity: any, amenityIndex: number) => {
          formData.append(
            `property[rooms_attributes][${index}][room_amenities_attributes][${amenityIndex}][amenity_id]`,
            amenity.amenity_id
          );
        });
      }

      // Append features
      if (modifiedFeatures) {
        modifiedFeatures.forEach((feature: any, featureIndex: number) => {
          formData.append(
            `property[rooms_attributes][${index}][room_features_attributes][${featureIndex}][feature_id]`,
            feature.feature_id
          );
        });
      }
      const newRoomImages = room.room_images?.filter(
        (image: any) => image instanceof File
      );
      const existingImages = room.existing_room_images || [];

      if (
        (newRoomImages && newRoomImages.length > 0) ||
        existingImages.length > 0
      ) {
        if (newRoomImages && newRoomImages.length > 0) {
          newRoomImages.forEach((image: File) => {
            if (image instanceof File) {
              formData.append(
                `property[rooms_attributes][${index}][room_images][]`,
                image
              );
            }
          });
        }

        if (existingImages.length > 0) {
          existingImages.forEach((imageUrl: string) => {
            if (imageUrl && typeof imageUrl === "string") {
              formData.append(
                `property[rooms_attributes][${index}][room_images][]`,
                imageUrl
              );
            }
          });
        }
      }
    }

    try {
      const response = await dispatch(uploadImageProperties(formData)).unwrap();
      if (response) {
        successHandler(response?.message || "Updated successfully");
        navigate(`/add-listing-9/${slug}`);
      }
    } catch (error) {
      console.error("Error updating property: ", error);
    }
  };

  function arraysEqual(arr1: any[], arr2: any[]): boolean {
    if (arr1.length !== arr2.length) return false;
    for (let i = 0; i < arr1.length; i++) {
      if (JSON.stringify(arr1[i]) !== JSON.stringify(arr2[i])) return false;
    }
    return true;
  }

  const handleRoomImagesChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
    setFieldValue: any,
    values: any
  ) => {
    const files = e.target.files;
    const newFiles = files ? Array.from(files) : [];

    if (files) {
      const newPreviews = newFiles.map((file) => URL.createObjectURL(file));

      setRoomImagePreviews((prevPreviews) => ({
        ...prevPreviews,
        [index]: [...(prevPreviews[index] || []), ...newPreviews],
      }));

      const existingImages =
        values.rooms_attributes?.[index]?.room_images || [];
      const combinedImages = [...existingImages, ...newFiles];
      setFieldValue(`rooms_attributes[${index}].room_images`, combinedImages);
    }
  };

  const handleRemoveRoomImage = async (
    previewIndex: number,
    index: number,
    values: any,
    setFieldValue: any
  ) => {
    const existingImages =
      values?.rooms_attributes[index]?.existing_room_images || [];
    const isExistingImage = previewIndex < existingImages.length;

    if (isExistingImage) {
      const imageIdToRemove = existingImages[previewIndex]?.id;

      try {
        await dispatch(deleteImage({ id: imageIdToRemove }));
        const updatedExistingImages = existingImages.filter(
          (_: any, idx: number) => idx !== previewIndex
        );
        setFieldValue(
          `rooms_attributes[${index}].existing_room_images`,
          updatedExistingImages
        );
      } catch (error) {
        console.error("Error removing image:", error);
      }
    } else {
      const newPreviews = roomImagePreviews[index] || [];
      const updatedPreviews = newPreviews.filter(
        (_: string, idx: number) => idx !== previewIndex - existingImages.length
      );

      setRoomImagePreviews((prevPreviews) => {
        const updatedPreviewsMap = { ...prevPreviews };
        updatedPreviewsMap[index] = updatedPreviews;
        return updatedPreviewsMap;
      });
      const newImages = values.rooms_attributes[index]?.room_images || [];
      const updatedNewImages = newImages.filter(
        (_: any, idx: number) => idx !== previewIndex - existingImages.length
      );
      setFieldValue(`rooms_attributes[${index}].room_images`, updatedNewImages);
    }
  };

  useEffect(() => {
    dispatch(getRoomFeaturesProperty())
      .unwrap()
      .then((response) => setFeatures(response.data))
      .catch((error) => console.error("Error fetching features:", error));

    dispatch(getRoomAmenitiesProperty())
      .unwrap()
      .then((response) => setAmenities(response.data))
      .catch((error) => console.error("Error fetching features:", error));
  }, [dispatch]);

  useEffect(() => {
    const fetchBedTypes = async () => {
      try {
        const response = await dispatch(getBedType()).unwrap();
        if (response.success) {
          setBedTypes(response.data);
        } else {
          console.error("Failed to fetch bed types:", response.message);
        }
      } catch (error) {
        console.error("Error fetching bed types:", error);
      }
    };

    const fetchRoomTypes = async () => {
      try {
        const response = await dispatch(getRoomType()).unwrap();
        if (response.success) {
          setRoomTypes(response.data);
        } else {
          console.error("Failed to fetch room types:", response.message);
        }
      } catch (error) {
        console.error("Error fetching room types:", error);
      }
    };
    fetchBedTypes();
    fetchRoomTypes();
  }, [dispatch]);

  const useRoomsImagesDropzone = (
    index: number,
    values: any,
    setFieldValue: Function
  ) => {
    return useDropzone({
      onDrop: (acceptedFiles) => {
        const files = acceptedFiles.map((file) => URL.createObjectURL(file));

        setRoomImagePreviews((prevPreviews) => ({
          ...prevPreviews,
          [index]: [...(prevPreviews[index] || []), ...files],
        }));

        const existingImages =
          values.rooms_attributes?.[index]?.room_images || [];
        const combinedImages = [...existingImages, ...acceptedFiles];
        setFieldValue(`rooms_attributes[${index}].room_images`, combinedImages);
      },
    });
  };

  const RoomImagesUploader = ({ index }: { index: number }) => {
    const { values, setFieldValue } = useFormikContext<any>();

    const {
      getRootProps: getRoomsImagesRootProps,
      getInputProps: getRoomsImagesInputProps,
    } = useRoomsImagesDropzone(index, values, setFieldValue);

    return (
      <div
        {...getRoomsImagesRootProps()}
        className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-neutral-300 dark:border-neutral-600 border-dashed rounded-md"
      >
        <input
          {...getRoomsImagesInputProps()}
          id="cover-image-upload"
          name="cover-image-upload"
          type="file"
          className="sr-only"
          onChange={(e) =>
            handleRoomImagesChange(e, index, setFieldValue, values)
          }
        />
        <div className="space-y-1 text-center">
          <svg
            className="mx-auto h-12 w-12 text-neutral-400"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 48 48"
            aria-hidden="true"
          >
            <path
              d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
          </svg>
          <div className="flex text-sm text-neutral-600 dark:text-neutral-300">
            <span className="relative cursor-pointer rounded-md font-medium text-primary-600 hover:text-primary-500">
              <span>Upload multiple files</span>
            </span>
            <p className="pl-1">or drag and drop</p>
          </div>
          <p className="text-xs text-neutral-500 dark:text-neutral-400">
            PNG, JPG, GIF up to 10MB
          </p>
        </div>
      </div>
    );
  };

  useEffect(() => {
    setTimeout(() => setIsLoading(false), 1500);
  }, []);

  return (
    <CommonLayoutV2 currentHref="/add-listing-8" PropertyID={RouteID}>
      <>
        <h2 className="text-2xl font-semibold">Room Details</h2>
        <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
          Provide detailed information about your property to help guests make
          informed decisions.
        </span>
        <Formik
          initialValues={initialValues}
          validationSchema={roomRuleValidationSchema}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {({
            errors,
            values,
            handleChange,
            handleSubmit,
            isSubmitting,
            getFieldProps,
            setFieldValue,
          }) => {
            return (
              <>
                {values.rooms_attributes.map((room: any, index: any) => {
                  return (
                    <div
                      key={index}
                      className={`${
                        index > 0
                          ? "mt-12 pt-8 border-t border-neutral-200"
                          : ""
                      }`}
                    >
                      <h3 className="text-xl font-bold text-neutral-900 dark:text-neutral-100 mb-6">
                        Room {index + 1}
                      </h3>
                      <FormItem label=" Room Name" className="mt-4">
                        <Input
                          type="text"
                          placeholder="Enter Room name"
                          {...getFieldProps(`rooms_attributes[${index}].name`)}
                        />
                        {errors.rooms_attributes &&
                          Array.isArray(errors.rooms_attributes) &&
                          typeof errors.rooms_attributes[index] === "object" &&
                          errors.rooms_attributes[index] !== null &&
                          (
                            errors.rooms_attributes[index] as {
                              [key: string]: string;
                            }
                          ).name && (
                            <div className="text-red-500 text-sm mt-1">
                              {
                                (
                                  errors.rooms_attributes[index] as {
                                    [key: string]: string;
                                  }
                                ).name
                              }
                            </div>
                          )}
                      </FormItem>
                      {isSharedProperty && (
                        <FormItem label="Price per Month" className="mt-4">
                          <div className="relative">
                            <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-500">
                              {currencySymbol()}
                            </span>
                            <Input
                              type="text"
                              name={`rooms_attributes[${index}].price_per_month`}
                              placeholder="Please enter price per month"
                              onChange={handleChange}
                              value={
                                values.rooms_attributes[index]?.price_per_month
                              }
                              className="pl-8"
                            />
                          </div>

                          {errors.rooms_attributes &&
                            Array.isArray(errors.rooms_attributes) &&
                            typeof errors.rooms_attributes[index] ===
                              "object" &&
                            errors.rooms_attributes[index] !== null &&
                            (
                              errors.rooms_attributes[index] as {
                                [key: string]: string;
                              }
                            ).price_per_month && (
                              <div className="text-red-500 text-sm mt-1">
                                {
                                  (
                                    errors.rooms_attributes[index] as {
                                      [key: string]: string;
                                    }
                                  ).price_per_month
                                }
                              </div>
                            )}
                        </FormItem>
                      )}
                      <FormItem label="Status" className="mt-4">
                        <Select
                          name={`rooms_attributes[${index}].status`}
                          onChange={handleChange}
                          value={room.status}
                        >
                          <option value="">Select status</option>
                          <option value="active">Active</option>
                          <option value="inactive">Inactive</option>
                        </Select>
                        {errors.rooms_attributes &&
                          Array.isArray(errors.rooms_attributes) &&
                          typeof errors.rooms_attributes[index] === "object" &&
                          errors.rooms_attributes[index] !== null &&
                          (
                            errors.rooms_attributes[index] as {
                              [key: string]: string;
                            }
                          ).status && (
                            <div className="text-red-500 text-sm mt-1">
                              {
                                (
                                  errors.rooms_attributes[index] as {
                                    [key: string]: string;
                                  }
                                ).status
                              }
                            </div>
                          )}
                      </FormItem>

                      {isSharedProperty && (
                        <FormItem label="Max Guests" className="mt-4">
                          <div className="relative">
                            <Input
                              type="text"
                              name={`rooms_attributes[${index}].max_guests`}
                              placeholder="Max-Guests"
                              onChange={handleChange}
                              value={values.rooms_attributes[index]?.max_guests}
                              className=""
                            />
                          </div>

                          {errors.rooms_attributes &&
                            Array.isArray(errors.rooms_attributes) &&
                            typeof errors.rooms_attributes[index] ===
                              "object" &&
                            errors.rooms_attributes[index] !== null &&
                            (
                              errors.rooms_attributes[index] as {
                                [key: string]: string;
                              }
                            ).max_guests && (
                              <div className="text-red-500 text-sm mt-1">
                                {
                                  (
                                    errors.rooms_attributes[index] as {
                                      [key: string]: string;
                                    }
                                  ).max_guests
                                }
                              </div>
                            )}
                        </FormItem>
                      )}
                      {isSharedProperty && (
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-5">
                          <FormItem
                            label="Availability Start Date"
                            className="mt-4"
                          >
                            <CustomDatePickerV2
                              placeholder="Select Start Date"
                              value={
                                room.booking_start
                                  ? moment(room.booking_start)
                                  : null
                              }
                              onChange={(value) => {
                                setFieldValue(
                                  `rooms_attributes[${index}].booking_start`,
                                  value
                                );
                                setFieldValue(
                                  `rooms_attributes[${index}].booking_end`,
                                  null
                                );
                              }}
                              minDate={new Date()}
                            />
                            {errors.rooms_attributes &&
                              Array.isArray(errors.rooms_attributes) &&
                              errors.rooms_attributes[index] &&
                              typeof errors.rooms_attributes[index] ===
                                "object" &&
                              errors.rooms_attributes[index] !== null &&
                              errors.rooms_attributes[index].booking_start && (
                                <div className="text-red-500 text-sm mt-1">
                                  {typeof errors.rooms_attributes[index]
                                    .booking_start === "string" &&
                                    errors.rooms_attributes[index]
                                      .booking_start}
                                </div>
                              )}
                          </FormItem>
                          <FormItem
                            label="Availability End Date"
                            className="mt-4"
                          >
                            <CustomDatePickerV2
                              placeholder="Select End Date"
                              value={
                                room.booking_end
                                  ? moment(room.booking_end)
                                  : null
                              }
                              onChange={(value) => {
                                setFieldValue(
                                  `rooms_attributes[${index}].booking_end`,
                                  value
                                );
                              }}
                              minDate={
                                room.booking_start
                                  ? moment(room.booking_start)
                                      .add(1, "day")
                                      .toDate()
                                  : new Date()
                              }
                            />
                            {errors.rooms_attributes &&
                              Array.isArray(errors.rooms_attributes) &&
                              errors.rooms_attributes[index] &&
                              typeof errors.rooms_attributes[index] ===
                                "object" &&
                              errors.rooms_attributes[index] !== null &&
                              errors.rooms_attributes[index].booking_end && (
                                <div className="text-red-500 text-sm mt-1">
                                  {typeof errors.rooms_attributes[index]
                                    .booking_end === "string" &&
                                    errors.rooms_attributes[index].booking_end}
                                </div>
                              )}
                          </FormItem>
                        </div>
                      )}

                      <FormItem label="Description" className="mt-4">
                        <textarea
                          className="block w-full h-32 px-4 py-3 text-sm rounded-2xl border-neutral-200"
                          name={`rooms_attributes[${index}].description`}
                          placeholder="Enter room description"
                          onChange={handleChange}
                          value={room.description}
                        />
                        {errors.rooms_attributes &&
                          Array.isArray(errors.rooms_attributes) &&
                          typeof errors.rooms_attributes[index] === "object" &&
                          errors.rooms_attributes[index] !== null &&
                          (
                            errors.rooms_attributes[index] as {
                              [key: string]: string;
                            }
                          ).description && (
                            <div className="text-red-500 text-sm mt-1">
                              {
                                (
                                  errors.rooms_attributes[index] as {
                                    [key: string]: string;
                                  }
                                ).description
                              }
                            </div>
                          )}
                      </FormItem>
                      <div className="grid grid-cols-1 md:grid-cols-3 gap-8 md:gap-5">
                        <FormItem label="Bed Type" className="mt-4">
                          <Select
                            name={`rooms_attributes[${index}].bed_type_id`}
                            onChange={handleChange}
                            value={room.bed_type_id}
                          >
                            <option value="">Select bed type</option>
                            {bedTypes.map((bedType) => (
                              <option key={bedType.id} value={bedType.id}>
                                {bedType.name}
                              </option>
                            ))}
                          </Select>
                          {errors.rooms_attributes &&
                            Array.isArray(errors.rooms_attributes) &&
                            typeof errors.rooms_attributes[index] ===
                              "object" &&
                            errors.rooms_attributes[index] !== null &&
                            (
                              errors.rooms_attributes[index] as {
                                [key: string]: string;
                              }
                            ).bed_type_id && (
                              <div className="text-red-500 text-sm mt-1">
                                {
                                  (
                                    errors.rooms_attributes[index] as {
                                      [key: string]: string;
                                    }
                                  ).bed_type_id
                                }
                              </div>
                            )}
                        </FormItem>
                        <FormItem label="Room Size (sq ft)" className="mt-4">
                          <Input
                            type="text"
                            name={`rooms_attributes[${index}].size`}
                            placeholder="Enter room size"
                            onChange={handleChange}
                            value={room.size}
                          />
                          {errors.rooms_attributes &&
                            Array.isArray(errors.rooms_attributes) &&
                            typeof errors.rooms_attributes[index] ===
                              "object" &&
                            errors.rooms_attributes[index] !== null &&
                            (
                              errors.rooms_attributes[index] as {
                                [key: string]: string;
                              }
                            ).size && (
                              <div className="text-red-500 text-sm mt-1">
                                {
                                  (
                                    errors.rooms_attributes[index] as {
                                      [key: string]: string;
                                    }
                                  ).size
                                }
                              </div>
                            )}
                        </FormItem>
                        <FormItem label="Room Type" className="mt-4">
                          <Select
                            name={`rooms_attributes[${index}].room_type_id`}
                            onChange={handleChange}
                            value={room.room_type_id}
                          >
                            <option value="">Select room type</option>
                            {roomTypes.map((roomType) => (
                              <option key={roomType.id} value={roomType.id}>
                                {roomType.name}
                              </option>
                            ))}
                          </Select>
                          {errors.rooms_attributes &&
                            Array.isArray(errors.rooms_attributes) &&
                            typeof errors.rooms_attributes[index] ===
                              "object" &&
                            errors.rooms_attributes[index] !== null &&
                            (
                              errors.rooms_attributes[index] as {
                                [key: string]: string;
                              }
                            ).room_type_id && (
                              <div className="text-red-500 text-sm mt-1">
                                {
                                  (
                                    errors.rooms_attributes[index] as {
                                      [key: string]: string;
                                    }
                                  ).room_type_id
                                }
                              </div>
                            )}
                        </FormItem>
                      </div>
                      <FormItem>
                        <h2 className="text-2xl font-semibold my-5">
                          Amenities
                        </h2>
                        <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
                          {amenities.map((amenity: any) => (
                            <CheckboxV2
                              key={amenity.id.toString()}
                              id={amenity.id.toString()}
                              label={amenity.name}
                              name={`rooms_attributes[${index}].amenities`}
                              onChange={(checked) =>
                                handleAmenitiesChange(
                                  amenity?.id,
                                  index,
                                  values,
                                  setFieldValue
                                )
                              }
                              checked={values.rooms_attributes[
                                index
                              ]?.amenities?.some(
                                (item: any) =>
                                  item.amenity_id === amenity.id ||
                                  item.id === amenity.id
                              )}
                            />
                          ))}
                        </div>
                      </FormItem>
                      <FormItem>
                        <h2 className="text-2xl font-semibold my-5">
                          Features
                        </h2>
                        <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
                          {features.map((feature: any) => (
                            <CheckboxV2
                              key={feature.id.toString()}
                              id={feature.id.toString()}
                              label={feature.name}
                              name={`rooms_attributes[${index}].features`}
                              onChange={(checked) =>
                                handleFeaturesChange(
                                  feature?.id,
                                  index,
                                  values,
                                  setFieldValue
                                )
                              }
                              checked={values.rooms_attributes[
                                index
                              ]?.features?.some(
                                (item: any) =>
                                  item.feature_id === feature.id ||
                                  item.id === feature.id
                              )}
                            />
                          ))}
                        </div>
                      </FormItem>
                      <div key={index}>
                        {/* Image upload section */}
                        <div className=" mt-5">
                          <span className="text-lg font-semibold">
                            Room images
                          </span>
                          <div className="mt-5">
                            <RoomImagesUploader key={index} index={index} />
                            {isLoading ? (
                              <div className="mt-3 flex flex-wrap justify-start gap-3">
                                {Array.from({
                                  length: room.existing_room_images?.length,
                                }).map((_, previewIndex) => (
                                  <div
                                    key={previewIndex}
                                    className="h-[120px] w-[120px] bg-neutral-300 dark:bg-neutral-700 rounded-md animate-pulse"
                                  ></div>
                                ))}
                              </div>
                            ) : (
                              <DragDropContext
                                onDragEnd={(result: any) => {
                                  const { source, destination } = result;
                                  if (!destination) return;
                                  const combinedImages = [
                                    ...(room.existing_room_images || []),
                                    ...(room.room_images || []),
                                  ];
                                  const reorderedImages = [...combinedImages];
                                  const [removed] = reorderedImages.splice(
                                    source.index,
                                    1
                                  );
                                  reorderedImages.splice(
                                    destination.index,
                                    0,
                                    removed
                                  );
                                  const newPosition = destination.index + 1;

                                  setReorderImages((prev: any) => {
                                    const updated = prev.filter(
                                      (item: any) =>
                                        item.position !== newPosition ||
                                        item.blob_id === removed.id
                                    );
                                    return [
                                      ...updated,
                                      {
                                        blob_id: removed.id,
                                        position: newPosition,
                                      },
                                    ];
                                  });

                                  const updatedExistingRoomImages =
                                    reorderedImages.filter(
                                      (item: any) => item.id
                                    );
                                  const updatedRoomImages =
                                    reorderedImages.filter(
                                      (item: any) => !item.id
                                    );
                                  setFieldValue(
                                    `rooms_attributes[${index}].existing_room_images`,
                                    updatedExistingRoomImages
                                  );
                                  setFieldValue(
                                    `rooms_attributes[${index}].room_images`,
                                    updatedRoomImages
                                  );
                                }}
                              >
                                <Droppable
                                  droppableId={`room-images-${index}`}
                                  direction="horizontal"
                                >
                                  {(provided: any) => (
                                    <div
                                      className="mt-3 flex flex-wrap justify-start gap-3"
                                      {...provided.droppableProps}
                                      ref={provided.innerRef}
                                    >
                                      {[
                                        ...(room.existing_room_images || []),
                                        ...(room.room_images || []),
                                      ].map(
                                        (image: any, previewIndex: number) => (
                                          <Draggable
                                            key={`image-${
                                              image.id || previewIndex
                                            }`}
                                            draggableId={`image-${
                                              image.id || previewIndex
                                            }`}
                                            index={previewIndex}
                                          >
                                            {(provided: any) => (
                                              <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                className="relative inline-block"
                                              >
                                                <div className="h-[120px] w-[120px] relative">
                                                  <NcImage
                                                    src={
                                                      image.url ||
                                                      URL.createObjectURL(image)
                                                    }
                                                    alt={`Room ${
                                                      index + 1
                                                    } Image ${
                                                      previewIndex + 1
                                                    }`}
                                                    className="rounded-md object-cover absolute top-0 left-0 w-full h-full"
                                                  />
                                                </div>
                                                <button
                                                  type="button"
                                                  onClick={() =>
                                                    handleRemoveRoomImage(
                                                      previewIndex,
                                                      index,
                                                      values,
                                                      setFieldValue
                                                    )
                                                  }
                                                  className="absolute top-2 right-2 bg-white border border-gray-300 rounded-full p-1 shadow-sm hover:bg-gray-100"
                                                >
                                                  <svg
                                                    className="w-4 h-4 text-red-500"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                  >
                                                    <path
                                                      strokeLinecap="round"
                                                      strokeLinejoin="round"
                                                      strokeWidth={2}
                                                      d="M6 18L18 6M6 6l12 12"
                                                    />
                                                  </svg>
                                                </button>
                                              </div>
                                            )}
                                          </Draggable>
                                        )
                                      )}
                                      {provided.placeholder}
                                    </div>
                                  )}
                                </Droppable>
                              </DragDropContext>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="flex gap-3 ">
                        {index > 0 && (
                          <ButtonPrimary
                            type="button"
                            onClick={() => {
                              setCount((prev) => prev - 1);
                              setFieldValue(
                                "rooms_attributes",
                                values.rooms_attributes.filter(
                                  (_: any, idx: number) => idx !== index
                                )
                              );
                            }}
                            className="my-5"
                          >
                            Remove Room
                          </ButtonPrimary>
                        )}
                        {totalRooms && totalRooms > count && (
                          <ButtonPrimary
                            type="button"
                            onClick={() => {
                              setCount((prev) => prev + 1);
                              setFieldValue("rooms_attributes", [
                                ...values.rooms_attributes,
                                initialRoomValues,
                              ]);
                            }}
                            className="my-5"
                          >
                            Add Another Room
                          </ButtonPrimary>
                        )}
                      </div>
                    </div>
                  );
                })}
                <div className="flex justify-end space-x-5 mt-5">
                  <ButtonSecondary href={`/add-listing-7/${slug}`}>
                    Go back
                  </ButtonSecondary>
                  <ButtonPrimary
                    type="submit"
                    onClick={handleSubmit}
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Loading..." : "Continue"}
                  </ButtonPrimary>
                </div>
              </>
            );
          }}
        </Formik>
      </>
    </CommonLayoutV2>
  );
};

export default Rooms;
