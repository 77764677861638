import React, { useState, useEffect, useRef } from "react";
import "react-chat-elements/dist/main.css";
import { sendMessage } from "../../redux/reducers/UserSlice/UserSlice";
import { getMessage } from "../../redux/reducers/UserSlice/UserSlice";
import { useDispatch } from "react-redux";
import { RootState, AppDispatch } from "redux/store";
import { useSelector } from "react-redux";
import ChatList from "./Chat";
import {
  getChatLists,
  getChatSearch,
} from "../../redux/reducers/ChatReducer/ChatSlice";
import { Message } from "../../redux/reducers/ChatReducer/ChatSliceTypes";
import { updateBookingsQueryDetails } from "../../redux/reducers/Properties/PropertiesSlice";
import MessageItem from "./components/MessageItem";
import moment from "moment";
import { resetCount } from "../../redux/reducers/ChatReducer/ChatSlice";
import { useNavigate } from "react-router-dom";
import { FiSearch } from "react-icons/fi";
import "./Chat.scss";

const Messages = () => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch<AppDispatch>();
  const [selectChat, setSelectedChat] = useState<any>(null);
  const [messages, setMessages] = useState<Message[]>([]);
  const [booking, setBooking] = useState<Message[]>([]);
  const [bookingQuery, setBookingQuery] = useState<Message[] | null>(null);
  const [bookingQueryId, setBookingQueryId] = useState<any>([]);
  const [bookingId, setBookingId] = useState<any>([]);
  const [message, setMessage] = useState<string>("");
  const [isMessageSend, setIsMessageSend] = useState<boolean>(false);
  const messageContainerRef = useRef<HTMLDivElement | null>(null);
  const { currentUser } = useSelector((state: RootState) => state.user);
  const chatLists = useSelector((state: RootState) => state.chat.chatLists);
  const [attachments, setAttachments] = useState<File[]>([]);
  const [filter, setFilter] = useState<"all" | "unread">("all");
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [chatPage, setChatPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const scrollPositionRef = useRef<number>(0);
  const [messageLoading, setMessageLoading] = useState<boolean>(false);
  const [chats, setChats] = useState(chatLists?.chats || []);
  const [filePreviews, setFilePreviews] = useState<{
    [chatId: number]: string | null;
  }>({});
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const extractedChat: any = chatLists.chats?.find(
    (item: any) => item?.id === selectChat?.id
  );

  const date = moment(new Date()).format("YYYY-MM-DD");

  const handleSendMessage = async () => {
    if (!message.trim() && attachments.length === 0) {
      return;
    }

    if (selectChat?.id) {
      const formData = new FormData();
      formData.append("message[body]", message ? message : "");
      formData.append("chat_id", selectChat.id);

      attachments.forEach((attachment, index) => {
        formData.append(`message[attachments][]`, attachment);
      });

      const action = await dispatch(sendMessage(formData));
      if (action?.payload?.success) {
        setMessage("");
        setAttachments([]);
        setFilePreviews((prev) => ({
          ...prev,
          [selectChat.id]: null,
        }));

        setTimeout(() => {
          setIsMessageSend(false);
        }, 400);
        setIsMessageSend(true);
      }
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file && selectChat?.id) {
      if (file.type.startsWith("image/")) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setFilePreviews((prev) => ({
            ...prev,
            [selectChat.id]: reader.result as string,
          }));
        };
        reader.readAsDataURL(file);
      } else if (file.type === "application/pdf") {
        setFilePreviews((prev) => ({
          ...prev,
          [selectChat.id]: "pdf",
        }));
      } else if (file.type.startsWith("video/")) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setFilePreviews((prev) => ({
            ...prev,
            [selectChat.id]: reader.result as string,
          }));
        };
        reader.readAsDataURL(file);
      } else {
        setFilePreviews((prev) => ({
          ...prev,
          [selectChat.id]: null,
        }));
      }
      setAttachments([file]);
    }
  };

  const handleRemovePreview = (chatId: string) => {
    setFilePreviews((prev) => {
      const updatedPreviews = { ...prev };
      delete updatedPreviews[selectChat.id];
      return updatedPreviews;
    });
  };

  useEffect(() => {
    if (selectChat?.id) {
      setFilePreviews((prev) => ({
        ...prev,
        [selectChat.id]: null,
      }));
    }
  }, [selectChat]);

  useEffect(() => {
    if (messageContainerRef.current) {
      messageContainerRef.current.scrollTop =
        messageContainerRef.current.scrollHeight;
    }
  }, [messages, booking]);

  const fetchChatLists = async () => {
    try {
      await dispatch(getChatLists(chatPage));
    } catch (error) {
      console.error("Error fetching chats:", error);
    }
  };

  const loadMoreChats = () => {
    if (
      chatLists?.meta?.total_pages &&
      chatPage < chatLists.meta.total_pages &&
      !loading &&
      !isLoadingMore
    ) {
      setIsLoadingMore(true);
      setChatPage((prev) => prev + 1);
    }
  };
  useEffect(() => {
    if (
      chatLists?.meta?.total_pages &&
      chatPage <= chatLists.meta.total_pages
    ) {
      const fetchData = async () => {
        setIsLoadingMore(true);
        await fetchChatLists();
        setIsLoadingMore(false);
      };
      fetchData();
    }
  }, [chatPage, chatLists?.meta?.total_pages]);

  const getAllMessages = async (chatId: number, page: number) => {
    const container = messageContainerRef.current;
    if (container) {
      scrollPositionRef.current = container.scrollTop;
    }
    setMessageLoading(true);

    const action = await dispatch(getMessage({ chatId, page }));
    if (action?.payload?.success) {
      const arrangeMessages = (
        existingMessages: Message[],
        newMessages: any
      ) => {
        const combinedMessages = [...existingMessages, ...newMessages];
        const uniqueDates = new Map();
        combinedMessages.forEach((item) => {
          if (!uniqueDates.has(item.date)) {
            uniqueDates.set(item.date, {
              ...item,
              messages: [...item.messages],
            });
          } else {
            const existingMessages = uniqueDates.get(item.date).messages;
            uniqueDates.set(item.date, {
              ...item,
              messages: [...existingMessages, ...item.messages],
            });
          }
        });

        const arrangedData = Array.from(uniqueDates.values());

        const normalizeDate = (date: string) => {
          if (date === "Today") {
            return new Date().setHours(23, 59, 59, 999);
          }
          if (date === "Yesterday") {
            const yesterday = new Date();
            yesterday.setDate(yesterday.getDate() - 1);
            return yesterday.setHours(23, 59, 59, 999);
          }
          return new Date(date).getTime();
        };

        arrangedData.sort(
          (a, b) => normalizeDate(a.date) - normalizeDate(b.date)
        );

        arrangedData.forEach((item) => {
          const seenIds = new Set();
          item.messages = item.messages
            .sort((m1: any, m2: any) => {
              const date1 = new Date(m1.created_at).getTime();
              const date2 = new Date(m2.created_at).getTime();
              return date1 - date2;
            })
            .filter((message: any) => {
              if (seenIds.has(message.id)) return false;
              seenIds.add(message.id);
              return true;
            });
        });

        return arrangedData;
      };

      const newData = action?.payload?.message || [];
      setMessages((prevMessages) => {
        const updatedMessages = arrangeMessages(prevMessages, newData);
        return updatedMessages;
      });

      const booking = action?.payload?.chat?.booking;
      if (booking) {
        const bookingId = booking?.id;
        setBooking(booking);
        setBookingId(bookingId);
      } else {
        setBookingId(null);
      }

      const bookingQuery = action?.payload?.chat?.booking_query;
      if (bookingQuery) {
        const bookingQueryId = bookingQuery?.id;

        setBookingQuery(bookingQuery);
        setBookingQueryId(bookingQueryId);
      } else {
        setBookingQuery(null);
        setBookingQueryId(null);
      }

      if (action?.payload?.meta?.total_pages) {
        setTotalPages(action.payload.meta.total_pages);
      }
    }

    setMessageLoading(false);
  };

  useEffect(() => {
    if (selectChat?.id && page) {
      setMessages([]);
      getAllMessages(selectChat.id, page);
      const container = messageContainerRef.current;

      if (container) {
        const scrollDifference =
          container.scrollTop - scrollPositionRef.current;
        if (container.scrollTop === 0) {
          container.scrollTop = container.scrollHeight - scrollDifference;
        }
      }
    }

    if (selectChat?.id) {
      scrollPositionRef.current = 0;
    }
  }, [selectChat, page, isMessageSend]);

  const handleScroll = () => {
    const container = messageContainerRef.current;

    if (container && container.scrollTop === 0 && page < totalPages) {
      if (messageLoading) return;
      setPage((prevPage) => prevPage + 1);
    }
  };

  const handleProceedToBooking = () => {
    if (bookingQuery != null) {
      const payload = {
        id: bookingQueryId,
        propertyId: selectChat.property.id,
        state: "accepted",
        checkIn: selectChat?.booking?.check_in_date,
        checkOut: selectChat?.booking?.check_out_date,
      };

      dispatch(updateBookingsQueryDetails(payload))
        .then((response) => {
          console.log("Booking updated successfully", response);
          navigate(`/booking_queries_details?id=${bookingQueryId}`);
        })
        .catch((error) => {
          console.log("Error in updating booking", error);
        });
    } else {
      console.log("bookingQuery is null or id is missing");
    }
  };

  const handleProceedToPayment = () => {
    try {
      navigate(`/booking_request?id=${bookingId}`);
    } catch (error) {
      alert("An error occurred while navigating. Please try again later.");
    }
  };

  const handleSearchMessages = async () => {
    setLoading(true);
    if (!searchQuery.trim()) {
      alert("Please enter a search term.");
      return;
    }
    try {
      const response = await dispatch(getChatSearch(searchQuery)).unwrap();
      setSearchResults(response);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (searchQuery.length === 0) {
      const fetchChatListInitially = async () => {
        await dispatch(getChatLists(chatPage));
      };
      fetchChatListInitially();
    }
  }, [searchQuery, isMessageSend]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (searchQuery.trim().length >= 3) {
        handleSearchMessages();
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [searchQuery]);

  const getName = (name: string) => {
    const modifiedName = name[0]?.toUpperCase() + name?.slice(1);
    return modifiedName;
  };

  const filterChats = (chats: any[]) => {
    if (filter === "unread") {
      return chats?.filter((chat) => chat.unread_count > 0);
    }
    return chats;
  };
  const resetSelectedChatUnreadCount = () => {
    if (selectChat?.id) {
      dispatch(resetCount(selectChat.id));
    }
  };

  const handleFilterChange = (newFilter: "all" | "unread") => {
    setFilter(newFilter);

    if (newFilter === "all") {
      resetSelectedChatUnreadCount();
    }
  };

  return (
    <div>
      <div className="flex h-screen bg-white dark:bg-neutral-900">
        <div className="w-1/4 border-r bg-white dark:bg-neutral-800 dark:border-neutral-700 ">
          <div className="pl-4 pr-0 pt-4 pb-4">
            <div className="flex items-center justify-between mb-4">
              <h2 className="text-xl font-semibold text-neutral-900 dark:text-neutral-100">
                Messages
              </h2>
              <button
                onClick={() => setShowSearch((prev) => !prev)}
                className="p-2 text-neutral-900 border bg-gray-200 dark:text-neutral-100 dark:bg-gray-600 rounded-full"
                aria-label="Toggle search bar"
              >
                <FiSearch size={20} />
              </button>
            </div>
            <div className="flex items-center mb-4">
              {showSearch && (
                <>
                  <input
                    type="text"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    placeholder="Search chats"
                    className="border p-2 rounded-md flex-grow mr-2 dark:bg-white dark:text-black"
                  />
                  <button
                    onClick={handleSearchMessages}
                    className="p-2 bg-gray-600 text-white rounded-md"
                  >
                    Search
                  </button>
                </>
              )}
            </div>
            <div className="mt-4 flex space-x-4">
              <button
                onClick={() => handleFilterChange("all")}
                className={`px-7 py-2 h-12 rounded-3xl border-2 ${
                  filter === "all"
                    ? "bg-black text-white border-black"
                    : "bg-white dark:bg-neutral-700 text-neutral-900 dark:text-neutral-100 border-neutral-300 dark:border-neutral-600"
                }`}
              >
                All
              </button>
              <button
                onClick={() => handleFilterChange("unread")}
                className={`px-3 py-2 h-12 rounded-3xl border-2 ${
                  filter === "unread"
                    ? "bg-black text-white border-black"
                    : "bg-white dark:bg-neutral-700 text-neutral-900 dark:text-neutral-100 border-neutral-300 dark:border-neutral-600"
                }`}
              >
                Unread
              </button>
            </div>
            <div className="mt-6">
              <ChatList
                loading={loading}
                isLoadingMore={isLoadingMore}
                currentUser={currentUser}
                chats={filterChats(chatLists?.chats)}
                selectChat={selectChat}
                setSelectedChat={setSelectedChat}
                getAllMessages={getAllMessages}
                loadMoreChats={loadMoreChats}
                setChats={setChats}
              />
            </div>
          </div>
        </div>
        {selectChat ? (
          <React.Fragment>
            <div className="flex-1 flex flex-col bg-white dark:bg-neutral-800">
              <div className="px-6 py-4 flex justify-between items-center border-b border-neutral-300 dark:border-neutral-700">
                <div className="flex items-center">
                  <img
                    src={
                      selectChat?.show?.image
                        ? selectChat?.show?.image
                        : "https://www.ihna.edu.au/blog/wp-content/uploads/2022/10/user-dummy.png"
                    }
                    alt="Profile"
                    className="w-10 h-10 rounded-full"
                  />
                  <div className="ml-4">
                    <h3 className="text-lg font-semibold text-neutral-900 dark:text-neutral-100">
                      {getName(selectChat?.show?.name)}
                    </h3>
                  </div>
                </div>
              </div>

              <div
                ref={messageContainerRef}
                onScroll={handleScroll}
                className="flex-1 relative overflow-y-auto px-6 py-6 space-y-4 text-center scrollbar design"
              >
                <div className="flex-1 overflow-y-auto px-6 py-6 space-y-4">
                  <div className="chat-container">
                    {messageLoading ? (
                      <div className="flex justify-center items-center py-15 mt-44">
                        <div className="w-10 h-10 border-4 border-gray-300 border-t-gray-500 rounded-full animate-spin"></div>
                      </div>
                    ) : (
                      messages?.map((msg: any) => (
                        <MessageItem
                          key={msg.id}
                          message={msg}
                          currentUser={currentUser}
                          selectChat={selectChat}
                          getName={getName}
                          setMessages={setMessages}
                        />
                      ))
                    )}
                  </div>
                </div>
              </div>

              <div
                className="bg-white dark:bg-neutral-800 px-6 py-4"
                style={{ zIndex: 10 }}
              >
                <div className="flex flex-col space-y-2">
                  {selectChat?.id && filePreviews[selectChat.id] && (
                    <div className="relative flex-shrink-0 w-20 h-20 border border-neutral-300 rounded-md overflow-hidden mb-2 bg-gray-200">
                      {filePreviews[selectChat.id]?.startsWith("data:image") ? (
                        <img
                          src={filePreviews[selectChat.id] as string}
                          alt="Preview"
                          className="w-full h-full object-cover"
                        />
                      ) : filePreviews[selectChat.id]?.startsWith(
                          "data:video"
                        ) ? (
                        <video
                          src={filePreviews[selectChat.id] as string}
                          controls
                          className="w-full h-full object-cover"
                        />
                      ) : (
                        <div className="text-neutral-500 dark:text-neutral-300 flex items-center justify-center h-full">
                          PDF File
                        </div>
                      )}
                      <button
                        onClick={() => handleRemovePreview(selectChat.id)}
                        className="absolute top-1 right-1 bg-transparent text-black rounded-full w-3 h-3 flex items-center justify-center text-md focus:outline-none"
                      >
                        ✖
                      </button>
                    </div>
                  )}

                  <div className="flex items-center space-x-2">
                    <button
                      onClick={() => fileInputRef.current?.click()}
                      className="text-neutral-500 hover:text-neutral-700 dark:text-neutral-400 dark:hover:text-neutral-200 mr-4"
                    >
                      📎
                    </button>

                    <input
                      type="text"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      placeholder="Type a message"
                      className="flex-grow bg-white dark:bg-neutral-900 border border-neutral-400 dark:border-neutral-600 rounded-lg p-3 focus:outline-none text-neutral-900 dark:text-neutral-100"
                    />
                    <input
                      type="file"
                      multiple={false}
                      ref={fileInputRef}
                      onChange={handleFileChange}
                      className="hidden"
                      accept=".pdf,.png,.jpeg,.jpg,.doc,.docx,.mp4"
                    />
                    <button
                      onClick={handleSendMessage}
                      className="bg-gray-600 text-white px-4 py-2 rounded-lg hover:bg-gray-400 dark:hover:bg-gray-400 focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 dark:focus:ring-gray-300"
                    >
                      Send
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-1/4 border-l border-neutral-300 dark:border-neutral-700 bg-white dark:bg-neutral-800 p-6">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-lg font-semibold text-neutral-900 dark:text-neutral-100">
                  Reservation
                </h3>
              </div>

              <div>
                {extractedChat ? (
                  <div className="mt-8 p-4 border border-neutral-300 dark:border-neutral-700 rounded-lg bg-white dark:bg-neutral-800">
                    <div>
                      <img
                        src={
                          extractedChat?.property?.image ||
                          "https://www.w3schools.com/w3images/lights.jpg"
                        }
                        alt={selectChat.property.title}
                        className="w-full h-auto rounded-lg mb-4 object-contain"
                        style={{ maxHeight: "350px" }}
                        onError={(e) => {
                          e.currentTarget.src =
                            "https://www.w3schools.com/w3images/lights.jpg";
                        }}
                      />
                      <h4 className="text-lg font-semibold text-neutral-800 dark:text-neutral-100">
                        {selectChat.property.title}
                      </h4>
                      <p className="text-sm text-neutral-500 dark:text-neutral-400 mt-2">
                        City: {selectChat.property.city}
                      </p>
                      <p className="text-sm text-neutral-500 dark:text-neutral-400 mt-2">
                        Address:{" "}
                        {`${selectChat?.property?.city}, ${selectChat?.property?.state}`}
                      </p>
                    </div>
                  </div>
                ) : (
                  <span className="text-neutral-900 dark:text-neutral-100">
                    No Property found
                  </span>
                )}
              </div>
              {(currentUser?.is_student || currentUser?.is_host) && (
                <div className="mt-7 p-4 border-t border-neutral-400 dark:border-neutral-700">
                  {bookingId ? (
                    <div className="flex flex-col items-center my-0">
                      <button
                        className="px-6 py-3 bg-white text-black border-2 border-gray-400 rounded-lg hover:bg-gray-200 focus:outline-none dark:bg-gray-600 dark:text-white"
                        onClick={handleProceedToPayment}
                      >
                        View Booking
                      </button>
                    </div>
                  ) : bookingQueryId ? (
                    <div className="flex flex-col items-center my-3">
                      <button
                        className="px-6 py-3 bg-white text-black border-2 border-gray-400 rounded-lg hover:bg-gray-200 focus:outline-none dark:bg-gray-600 dark:text-white"
                        onClick={handleProceedToBooking}
                      >
                        View Booking Query
                      </button>
                    </div>
                  ) : null}
                </div>
              )}

              {selectChat && (
                <div className="mt-1 p-4 border-t border-neutral-400 dark:border-neutral-700">
                  <div className="flex items-center justify-between">
                    <div className="flex flex-col items-center justify-center h-full w-full text-center">
                      <p className="font-bold text-lg text-neutral-800 dark:text-neutral-100">
                        Check-in
                      </p>
                      <p className="text-md text-neutral-500 dark:text-neutral-400">
                        {moment(
                          selectChat?.booking?.check_in_date ||
                            selectChat?.booking_query?.check_in_date
                        ).format("MMM DD, YYYY")}
                      </p>
                    </div>

                    <div className="h-10 border-l border-neutral-400 dark:border-neutral-700 mx-4"></div>

                    <div className="flex flex-col items-center justify-center h-full w-full text-center">
                      <p className="font-bold text-lg text-neutral-800 dark:text-neutral-100">
                        Check-out
                      </p>
                      <p className="text-md text-neutral-500 dark:text-neutral-400">
                        {moment(
                          selectChat?.booking?.check_out_date ||
                            selectChat?.booking_query?.check_out_date
                        ).format("MMM DD, YYYY")}
                      </p>
                    </div>
                  </div>
                </div>
              )}
              <div className="p-4 mt-2 border-t border-neutral-400 dark:border-neutral-700"></div>
            </div>
          </React.Fragment>
        ) : (
          <div className="w-screen h-screen flex items-center justify-center">
            <h3 className="text-neutral-900 dark:text-neutral-100">
              NO MESSAGE YET. START THE CONVERSATION!
            </h3>
          </div>
        )}
      </div>
    </div>
  );
};

export default Messages;
