import axios from "axios";
import ErrorHandler from "./ErrorHandler";
import { logout } from "../../redux/reducers/AuthSlice/AuthSlice";

const API = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

API.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("authToken");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    if (config.data instanceof FormData) {
      config.headers["Content-Type"] = "multipart/form-data";
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

API.interceptors.response.use(
  (response) => response.data,
  (error) => {
    const statusCode = error.response?.status;
    if (statusCode === 401 || statusCode === 403) {
      ErrorHandler("Unauthorized Error!");

      const preserveKey = "theme";
      const preserveValue = localStorage.getItem(preserveKey);
      if (preserveValue) {
        localStorage.setItem(preserveKey, preserveValue);
      }

      setTimeout(() => {
        import("../../redux/store").then(({ store }) => {
          store.dispatch(logout());
          window.location.href = "/login";
        });
      }, 2000);
    }
    return Promise.reject(error);
  }
);

export default API;
