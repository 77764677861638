import React, { FC, useEffect, useState } from "react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import convertNumbThousand from "utils/convertNumbThousand";
import { debounce } from "lodash";
import {
  getPropertyCategories,
  getPropertyType,
  getAmenitiesProperty,
  getFeaturesProperty,
  searchCategory,
  setSearchData,
} from "../../redux/reducers/Properties/PropertiesSlice";
import FormItem from "containers/PageAddListing1/FormItem";
import FormField from "shared/FormField/FormField";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "redux/store";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import Skeleton from "react-loading-skeleton";
import { useLoadScript } from "@react-google-maps/api";
import moment from "moment";

export interface TabFiltersProps {
  page?: number;
}

const TabFilters: FC<TabFiltersProps> = ({ page }) => {
  const dispatch = useDispatch<AppDispatch>();

  const {
    propertiesCategories,
    propertyTypes,
    amenitiesCategories,
    featuresCategories,
  } = useSelector((state: RootState) => state.property);

  const storedSearchData = useSelector(
    (state: RootState) => state.property.searchedData
  );
  const { latitude, longitude, label, locationData, startDate, endDate } =
    storedSearchData || {};
  const [userLocation, setUserLocation] = useState<{
    latitude: any;
    longitude: any;
    locationData: any;
  }>({
    latitude: latitude || null,
    longitude: longitude || null,
    locationData: locationData || "",
  });
  const [filteredPropertyTypes, setFilteredPropertyTypes] = useState<any[]>([]);
  const [start, setStart] = useState(startDate || undefined);
  const [end, setEnd] = useState(endDate || undefined);
  const [priceRange, setPriceRange] = useState<[number, number]>([0, 5000]);
  const [googleAddress, setGoogleAddress] = useState(
    userLocation.locationData.street || ""
  );
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY || "",
    libraries: ["places"],
  });
  useEffect(() => {
    dispatch(getPropertyCategories());
    dispatch(getPropertyType());
    dispatch(getAmenitiesProperty());
    dispatch(getFeaturesProperty());
  }, [dispatch]);

  useEffect(() => {
    if (Array.isArray(propertyTypes)) {
      setFilteredPropertyTypes(propertyTypes);
    }
  }, [propertyTypes]);

  const formik = useFormik({
    initialValues: {
      location: label || "",
      startDate: startDate || "",
      endDate: endDate || "",
      property_category: "",
      property_type_id: "",
      amenities: [],
      features: [],
      price_min: priceRange[0],
      price_max: priceRange[1],
      guest_number: "",
      bedrooms: "",
      bathrooms: "",
    },
    onSubmit: () => {},
  });

  const triggerSearch = debounce(async () => {
    const values = formik.values;

    const amenities = Array.isArray(values.amenities)
      ? values.amenities.join(",")
      : "";
    const features = Array.isArray(values.features)
      ? values.features.join(",")
      : "";

    const searchQuery = `q[property_type_id]=${values.property_type_id}
    &q[property_category_id]=${values.property_category}
    &q[min_price]=${values.price_min}
    &q[max_price]=${values.price_max}
    &q[feature_ids]=${features}
    &q[amenity_ids]=${amenities}
    &q[total_bedrooms_gteq]=${values.bedrooms}
    &q[guest_number_gteq]=${values.guest_number}
    &q[total_bathrooms_gteq]=${values.bathrooms}
    &q[latitude]=${userLocation.latitude}
    &q[longitude]=${userLocation.longitude}
    &q[availability_start_lteq]=${moment(start).format("YYYY-MM-DD")}
    &q[availability_end_gteq]=${moment(end).format("YYYY-MM-DD")}
    &page=${page}`;
    try {
      const action = await dispatch(searchCategory(searchQuery));
      if (action.payload?.success) {
        dispatch(
          setSearchData({ ...storedSearchData, searchedResult: action.payload })
        );
      } else {
        dispatch(setSearchData({ ...storedSearchData, searchedResult: [] }));
      }
    } catch (error) {
      console.error("Search API error:", error);
    }
  }, 300);

  useEffect(() => {
    if (
      formik.values.property_category ||
      formik.values.property_type_id ||
      formik.values.guest_number ||
      formik.values.bathrooms ||
      formik.values.bedrooms ||
      formik.values.amenities?.length > 0 ||
      formik.values.features?.length > 0 ||
      formik.values.price_min > 0 ||
      formik.values.price_max < 5000 ||
      (start !== null && end !== null && start < end)
    ) {
      triggerSearch();
    }
    return triggerSearch.cancel;
  }, [
    formik.values.property_category,
    formik.values.property_type_id,
    formik.values.amenities,
    formik.values.features,
    formik.values.guest_number,
    formik.values.bathrooms,
    formik.values.bedrooms,
    formik.values.price_min,
    formik.values.price_max,
    start,
    end,
    userLocation,
    page,
  ]);

  const handlePriceChange = (value: number | number[]) => {
    if (Array.isArray(value)) {
      setPriceRange(value as [number, number]);
      formik.setFieldValue("price_min", value[0]);
      formik.setFieldValue("price_max", value[1]);
    } else {
      setPriceRange([value, value]);
      formik.setFieldValue("price_min", value);
      formik.setFieldValue("price_max", value);
    }
  };

  const handlePlaceSelect = async (place: any) => {
    try {
      if (!place || !place.value || !place.value.place_id) return;

      const placeId = place.value.place_id;
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?place_id=${placeId}&key=${process.env.REACT_APP_GOOGLE_MAP_KEY}`
      );

      if (!response.ok) {
        throw new Error(`API error: ${response.status} ${response.statusText}`);
      }

      const geocodeData = await response.json();
      if (geocodeData.status !== "OK") {
        throw new Error(`Google API Error: ${geocodeData.status}`);
      }

      if (setUserLocation) {
        const { lat, lng } = geocodeData.results[0].geometry.location;
        const addressComponents = geocodeData.results[0].address_components;
        const formattedAddress = geocodeData.results[0].formatted_address;

        let city = "";
        let state = "";
        let zipCode = "";
        let country = "";

        addressComponents.forEach((component: any) => {
          if (component.types.includes("locality")) {
            city = component.long_name;
          }
          if (component.types.includes("administrative_area_level_1")) {
            state = component.long_name;
          }
          if (
            component.types.includes("postal_code") ||
            component.types.includes("postal_code_prefix")
          ) {
            zipCode = component.long_name;
          }
          if (component.types.includes("country")) {
            country = component.long_name;
          }
        });
        const locationData = {
          street: formattedAddress || `${city}, ${state}, ${country}`,
          city: city || "N/A",
          state: state || "N/A",
          zipcode: zipCode || "N/A",
          country: country || "N/A",
        };
        setUserLocation({
          latitude: lat,
          longitude: lng,
          locationData: locationData,
        });
        dispatch(
          setSearchData({
            ...storedSearchData,
            label: `${city}, ${country}`,
            latitude: lat,
            longitude: lng,
            locationData: locationData,
          })
        );
      }
    } catch (error) {
      console.error("Geocoding error:", error);
    }
  };

  return (
    <form
      onSubmit={formik.handleSubmit}
      className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-4"
    >
      <FormItem>
        <h1 className="mb-1">location :</h1>
        {!isLoaded ? (
          <Skeleton height={40} width="100%" className="rounded-md" />
        ) : (
          <div className="border border-gray-200 dark:border-gray-600 rounded-2xl">
            <GooglePlacesAutocomplete
              apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY || ""}
              selectProps={{
                inputValue: googleAddress,
                onInputChange: (newValue, { action }) => {
                  if (action !== "input-blur" && action !== "menu-close") {
                    setGoogleAddress(newValue);
                  }
                },
                placeholder: "Search for places...",
                onChange: (place) => {
                  if (place?.value?.place_id) {
                    handlePlaceSelect(place);
                  }
                },
                className: "text-sm",
                isClearable: true,
                styles: {
                  control: (provided) => ({
                    ...provided,
                    border: "none",
                    boxShadow: "none",
                    background: "transparent",
                  }),
                  input: (provided) => ({
                    ...provided,
                    color: "inherit",
                    ".dark &": { color: "white" },
                  }),
                  placeholder: (provided) => ({
                    ...provided,
                    color: "black",
                    ".dark &": {
                      color: "white",
                    },
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isFocused
                      ? "#E5E7EB"
                      : "transparent",
                    color: "inherit",
                    ".dark &": {
                      backgroundColor: state.isFocused
                        ? "rgba(255, 255, 255, 0.2)"
                        : "transparent",
                    },
                  }),
                  menu: (provided) => ({
                    ...provided,
                    backgroundColor: "var(--bg-color, #ffffff)",
                    marginTop: "0",
                    marginLeft: "6px",
                    ".dark &": {
                      backgroundColor:
                        "rgba(var(--c-neutral-800), var(--tw-bg-opacity, 1))",
                      color: "#ffffff",
                    },
                  }),
                  singleValue: (provided) => ({
                    ...provided,
                    color: "inherit",
                    ".dark &": { color: "white" },
                  }),
                },
              }}
            />
          </div>
        )}
      </FormItem>
      <FormItem>
        <FormField
          label="Start Date"
          name="startDate"
          type="date"
          value={start}
          onChange={(e) => {
            setStart(e);
            setEnd(null);
            dispatch(
              setSearchData({
                ...storedSearchData,
                startDate: e,
                endDate: null,
              })
            );
          }}
          minDate={new Date()}
          onBlur={formik.handleBlur}
          error={
            formik.touched.startDate && formik.errors.startDate
              ? String(formik.errors.startDate)
              : undefined
          }
        />
      </FormItem>
      <FormItem>
        <FormField
          label="End Date"
          name="endDate"
          type="date"
          value={end}
          onChange={(e) => {
            setEnd(e);
            dispatch(
              setSearchData({
                ...storedSearchData,
                endDate: e || null,
              })
            );
          }}
          minDate={
            start ? new Date(new Date(start).getTime() + 86400000) : new Date()
          }
          onBlur={formik.handleBlur}
          error={
            formik.touched.endDate && formik.errors.endDate
              ? String(formik.errors.endDate)
              : undefined
          }
        />
      </FormItem>
      <FormItem>
        <FormField
          label="Property Category"
          name="property_category"
          type="select"
          value={formik.values.property_category || "All Types"}
          onChange={(e) => {
            const selectedValue = e.target.value;
            formik.handleChange(e);
            if (selectedValue === "") {
              formik.setFieldValue("property_category", "");
            }
          }}
          onBlur={formik.handleBlur}
          options={[
            { value: "", label: "All Types" },
            ...(propertiesCategories?.map((item) => ({
              value: String(item.id),
              label: `${item.name} (${item.property_count || 0})`,
            })) || []),
          ]}
          error={
            formik.touched.property_category &&
            formik.errors.property_category &&
            formik.errors.property_category
          }
        />
      </FormItem>

      <FormItem>
        <FormField
          label="Property type"
          name="property_type_id"
          type="select"
          value={formik.values.property_type_id || "All Types"}
          onChange={(e) => {
            const selectedValue = e.target.value;
            formik.handleChange(e);
            if (selectedValue === "") {
              formik.setFieldValue("property_type_id", "");
            }
          }}
          onBlur={formik.handleBlur}
          options={[
            { value: "", label: "All Types" },
            ...filteredPropertyTypes.map((type) => ({
              value: String(type.id),
              label: `${type.name} (${type.property_count || 0})`,
            })),
          ]}
          error={
            formik.touched.property_type_id && formik.errors.property_type_id
              ? formik.errors.property_type_id
              : undefined
          }
        />
      </FormItem>
      <FormItem>
        <FormField
          label="Guest Number"
          name="guest_number"
          type="select"
          value={formik.values.guest_number || "Any"}
          onChange={(e) => {
            const selectedValue = e.target.value;
            formik.handleChange(e);
            dispatch(
              setSearchData({
                ...storedSearchData,
                totalGuests: selectedValue || null,
              })
            );
          }}
          onBlur={formik.handleBlur}
          options={[
            { value: "", label: "Any" },
            ...Array.from({ length: 15 }, (_, i) => ({
              value: String(i + 1),
              label: `${i + 1} Guest${i > 0 ? "s" : ""}`,
            })),
          ]}
          error={
            formik.touched.guest_number && formik.errors.guest_number
              ? formik.errors.guest_number
              : undefined
          }
        />
      </FormItem>
      <FormItem>
        <FormField
          label="Amenities"
          name="amenities"
          type="select"
          value={formik.values.amenities}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
            const selectedOptions = Array.from(
              e.target.selectedOptions,
              (option) => option.value
            );
            formik.setFieldValue("amenities", selectedOptions);
          }}
          onBlur={formik.handleBlur}
          options={
            amenitiesCategories?.map((anamity) => ({
              value: String(anamity.id),
              label: anamity.name,
            })) || []
          }
          error={
            formik.touched.amenities &&
            typeof formik.errors.amenities === "string"
              ? formik.errors.amenities
              : undefined
          }
        />
      </FormItem>
      <FormItem>
        <FormField
          label="Property feature"
          name="features"
          type="select"
          value={formik.values.features}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
            const selectedOptions = Array.from(
              e.target.selectedOptions,
              (option) => option.value
            );
            formik.setFieldValue("features", selectedOptions);
          }}
          onBlur={formik.handleBlur}
          options={
            featuresCategories?.map((feature: any) => ({
              value: String(feature?.id),
              label: feature?.name,
            })) || []
          }
          error={
            formik.touched.features &&
            typeof formik.errors.features === "string"
              ? formik.errors.features
              : undefined
          }
        />
      </FormItem>
      <FormItem>
        <FormField
          label="Bedrooms"
          name="bedrooms"
          type="text"
          value={formik.values.bedrooms}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          placeholder="Bedrooms"
          error={
            formik.touched.bedrooms && formik.errors.bedrooms
              ? formik.errors.bedrooms
              : undefined
          }
        />
      </FormItem>
      <FormItem>
        <FormField
          label="Bathrooms"
          name="bathrooms"
          type="text"
          value={formik.values.bathrooms}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          placeholder="Bathrooms"
          error={
            formik.touched.bathrooms && formik.errors.bathrooms
              ? formik.errors.bathrooms
              : undefined
          }
        />
      </FormItem>
      <FormItem className="mt-5">
        <label className="text-m font-normal">Price Range</label>
        <div className="flex items-center space-x-4 w-full">
          <span className="text-orange-500">
            ${convertNumbThousand(priceRange[0])}
          </span>
          <Slider
            range
            min={0}
            max={5000}
            step={1}
            value={priceRange}
            onChange={handlePriceChange}
            className="w-full"
            styles={{
              track: {
                backgroundColor: "#FF7A00",
                height: 6,
              },
              handle: {
                borderColor: "#FF7A00",
                backgroundColor: "#FF7A00",
                borderRadius: 0,
                height: 15,
                width: 15,
              },
              rail: {
                backgroundColor: "#d3d3d3",
                height: 6,
              },
            }}
          />
          <span className="text-orange-500">
            ${convertNumbThousand(priceRange[1])}
          </span>
        </div>
      </FormItem>
    </form>
  );
};

export default TabFilters;
