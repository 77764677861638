import { StarIcon } from "@heroicons/react/24/solid";
import React, { FC } from "react";
import Avatar from "shared/Avatar/Avatar";
import dayjs from "dayjs";
import { PropertyToEdit } from "redux/reducers/Properties/PropertiesSliceTypes";

interface Review {
  id: number;
  comment: string;
  rating: number | undefined;
  user: {
    image: string | null;
    name: string;
  };
  average: number;
  created_at: string;
}

interface CommentListingProps {
  className?: string;
  data: Review;
  propertyData: PropertyToEdit | null;
}

const CommentListing: FC<CommentListingProps> = ({
  className = "",
  data,
  propertyData,
}) => {
  const formattedDate = dayjs(data.created_at).format("DD MMMM YYYY");
  const review = propertyData?.average?.average_review;
  const formattedreview = review ? review.toFixed(1) : null;
  const reviewCategories = [
    {
      label: "Accuracy",
      rating: propertyData?.average?.accuracy,
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5 text-gray-700 dark:text-white"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M3 10h11M9 21V3m8 18V3m4 4H9"
          />
        </svg>
      ),
    },
    {
      label: "Communication",
      rating: propertyData?.average?.communication,
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5 text-gray-700 dark:text-white"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M8 10h.01M12 10h.01M16 10h.01M21 12c0 1.657-1.343 3-3 3H6l-4 4V5a3 3 0 013-3h12a3 3 0 013 3v7z"
          />
        </svg>
      ),
    },
    {
      label: "Cleanliness",
      rating: propertyData?.average?.cleanliness,
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5 text-gray-700 dark:text-white"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M3 5h18M5 3v18m14-18v18"
          />
        </svg>
      ),
    },
    {
      label: "Location",
      rating: propertyData?.average?.location,
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5 text-gray-700 dark:text-white"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M12 2C8.13 2 5 5.13 5 9c0 3.25 3 6.88 6 10.27 1.16 1.36 2.83 1.36 4 0 3-3.39 6-7.02 6-10.27 0-3.87-3.13-7-7-7zM12 11a2 2 0 110-4 2 2 0 010 4z"
          />
        </svg>
      ),
    },
    {
      label: "Check In",
      rating: propertyData?.average?.check_in,
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5 text-gray-700 dark:text-white"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      ),
    },
    {
      label: "Value",
      rating: propertyData?.average?.values,
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5 text-gray-700 dark:text-white"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M17 9V7a4 4 0 10-8 0v2m0 0H5a2 2 0 00-2 2v6a2 2 0 002 2h14a2 2 0 002-2v-6a2 2 0 00-2-2h-4z"
          />
        </svg>
      ),
    },
  ];
  return (
    <div>
      <div className="p-5">
        <h3 className="text-lg font-semibold flex items-center">
          <span className="text-yellow-500">★ {formattedreview}</span>
          <span className="ml-2 text-neutral-500 dark:text-white">
            {" "}
            Reviews
          </span>
        </h3>

        <div className="grid grid-cols-2 gap-4 mt-3 ">
          {reviewCategories.map((category, index) => (
            <div
              key={index}
              className="flex items-center space-x-2 text-neutral-500 dark:text-white"
            >
              {category.icon}
              <span className="text-neutral-500 dark:text-white  font-semibold">
                {category.label}
              </span>
              <div className="flex text-yellow-500">
                {[...Array(5)].map((_, i) => (
                  <StarIcon
                    key={i}
                    className={`w-4 h-4 ${
                      i < (category?.rating ?? 0)
                        ? "text-yellow-500"
                        : "text-gray-300"
                    }`}
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div
        className={`nc-CommentListing flex space-x-4 p-5 ${className}`}
        data-nc-id="CommentListing"
      >
        <div className="pt-0.5">
          <Avatar
            sizeClass="h-10 w-10 text-lg"
            radius="rounded-full"
            userName={data?.user?.name}
            imgUrl={data?.user?.image || undefined}
          />
        </div>
        <div className="flex-grow">
          <div className="flex justify-between space-x-3">
            <div className="flex flex-col">
              <div className="text-sm font-semibold">
                <span>{data?.user?.name}</span>
              </div>
              <div className="flex text-yellow-500 mt-3">
                {[...Array(5)].map((_, index) => (
                  <StarIcon
                    key={index}
                    className={`w-4 h-4 ${
                      index < data?.average
                        ? "text-yellow-500"
                        : "text-gray-300"
                    }`}
                  />
                ))}
              </div>
            </div>
            <span className="text-sm text-neutral-500 dark:text-neutral-400 ">
              Posted on - {formattedDate}
            </span>
          </div>
          <span className="block mt-3 text-neutral-6000 dark:text-neutral-300">
            {data?.comment}
          </span>
        </div>
        <hr className="border-t border-gray-300 dark:border-gray-600" />
      </div>
    </div>
  );
};

export default CommentListing;
