import { Modal } from "@mantine/core";
import { useEffect, useState } from "react";
import { PropertyToEdit } from "redux/reducers/Properties/PropertiesSliceTypes";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css"; // Optional: for blur effect

interface ImageModalProps {
  isOpen: boolean;
  onClose: () => void;
  propertyData: PropertyToEdit;
}

const ImageModal: React.FC<ImageModalProps> = ({
  isOpen,
  onClose,
  propertyData,
}) => {
  const images = propertyData?.place_images;
  const [fullImageUrl, setFullImageUrl] = useState<any>("");
  const [selectedIndex, setSelectedIndex] = useState<any>(null);
  const numColumns = propertyData.place_images.length > 3 ? 3 : 2; // Adjust columns dynamically

  useEffect(() => {
    if (selectedIndex !== null) {
      setFullImageUrl(images[selectedIndex]?.url);
    }
  }, [selectedIndex, images]);

  const fullImageHandler = (image: any, index: number) => {
    setSelectedIndex(index);
    setFullImageUrl(image?.url);
  };

  const modifiedClose = () => {
    setFullImageUrl("");
    onClose();
  };

  const onSlideRight = (idx: number) => {
    const updatedIndex = (idx + 1) % images.length; // Cyclic navigation
    setSelectedIndex(updatedIndex);
  };

  const onSlideLeft = (idx: number) => {
    const updatedIndex = (idx - 1 + images.length) % images.length; // Cyclic navigation
    setSelectedIndex(updatedIndex);
  };

  return (
    <Modal
      id="modal-1"
      opened={isOpen}
      onClose={modifiedClose}
      fullScreen
      radius={0}
      transitionProps={{ transition: "fade", duration: 200 }}
      styles={{
        content: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          overflow: "hidden",
          padding: 0,
        },
        body: {
          height: "100%",
          maxHeight: "100vh",
          width: "100%",
          padding: 0,
          margin: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
        close: {
          position: "absolute",
          bottom: "380px",
          right: "-50px",
          zIndex: 1000,
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          color: "white",
          borderRadius: "50%",
          width: "32px",
          height: "32px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
      }}
      closeButtonProps={{
        "aria-label": "Close modal",
        size: "lg",
        iconSize: 20,
      }}
    >
      {!fullImageUrl ? (
        <div className="p-4 w-full h-full overflow-auto">
          {/* Debug: Show image count */}
          {images && images.length > 0 ? (
            <div
              className="grid grid-cols-2 gap-4"
              style={{
                gridTemplateColumns: "repeat(2, 1fr)",
                maxWidth: "1200px",
                margin: "0 auto",
              }}
            >
              {images
                .map(
                  (item, index) =>
                    index % 3 === 0 && (
                      <div
                        key={`row-${Math.floor(index / 3)}`}
                        className="grid grid-cols-3 gap-4 col-span-2"
                      >
                        {images.slice(index, index + 3).map((subItem) => (
                          <div
                            key={subItem?.id || `img-${index}`}
                            className="relative w-full overflow-hidden"
                            onClick={() =>
                              fullImageHandler(subItem, images.indexOf(subItem))
                            }
                          >
                            <LazyLoadImage
                              src={subItem?.url || ""}
                              alt={`Listing gallery image ${
                                subItem?.id || index
                              }`}
                              effect="blur"
                              placeholder={
                                <div className="w-full h-full bg-gray-200" />
                              }
                              wrapperClassName="w-full h-full"
                              className="rounded-lg object-cover sm:rounded-xl"
                              style={{ width: "100%", height: "300px" }}
                              onError={(e) => {
                                console.error(
                                  "Image failed to load:",
                                  subItem?.url
                                );
                              }}
                            />
                          </div>
                        ))}
                      </div>
                    )
                )
                .filter(Boolean)}
            </div>
          ) : (
            <div className="text-center">No images available</div>
          )}
        </div>
      ) : (
        <div className="relative flex items-center justify-center w-full h-full container">
          <div className="absolute inset-0 flex items-center justify-center">
            <LazyLoadImage
              src={fullImageUrl || ""}
              effect="blur"
              placeholder={<div className="w-full h-full bg-gray-200" />}
              wrapperClassName="max-w-full max-h-full"
              className="object-contain"
              style={{
                width: "auto",
                height: "auto",
                maxHeight: "100vh",
                maxWidth: "100vw",
              }}
              onError={(e) => {
                console.error("Full image failed to load:", fullImageUrl);
              }}
            />
          </div>
          <div className="absolute inset-0 mx-auto flex max-w-7xl items-center justify-center pointer-events-none">
            <div className="relative w-full h-full">
              <button
                className="absolute left-3 top-[calc(50%-16px)] rounded-full bg-black/50 p-3 text-white/75 backdrop-blur-lg transition hover:bg-black/75 hover:text-white focus:outline-none pointer-events-auto"
                style={{ transform: "translate3d(0, 0, 0)" }}
                onClick={() => onSlideLeft(selectedIndex)}
              >
                <ChevronLeftIcon className="h-6 w-6" />
              </button>
              <button
                className="absolute right-3 top-[calc(50%-16px)] rounded-full bg-black/50 p-3 text-white/75 backdrop-blur-lg transition hover:bg-black/75 hover:text-white focus:outline-none pointer-events-auto"
                style={{ transform: "translate3d(0, 0, 0)" }}
                onClick={() => onSlideRight(selectedIndex)}
              >
                <ChevronRightIcon className="h-6 w-6" />
              </button>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default ImageModal;
